<div>
  <h1 class="page-title">Transfer</h1>
</div>

<div
  class="offcanvas offcanvas-end"
  data-bs-backdrop="default"
  tabindex="-1"
  id="staticBackdrop"
  aria-labelledby="staticBackdropLabel">
  <div class="offcanvas-header">
    <h1 class="offcanvas-title" id="staticBackdropLabel">Transfer Mandat {{ current_aktenzeichen }}</h1>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
          clip-rule="evenodd" />
      </svg>
    </button>
  </div>
  <div class="offcanvas-body">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="container-fluid" style="margin-top: 30px">
  <!-- / .row -->
  <div class="row">
    <div class="col-12">
      <div class="mx-4">
        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white">
          <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
        </div>

        <mat-form-field appearance="standard">
          <mat-label>Suche</mat-label>
          <input matInput (keyup)="applyFilter($event)" [(ngModel)]="searchValue" placeholder="" #input />
          <button
            mat-button
            *ngIf="searchValue"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchValue = ''; applyFilter($event)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        &nbsp; &nbsp;
        <mat-form-field class="flex-none" appearance="standard" *ngFor="let empfilter of empFilters">
          <mat-label>{{ empfilter.name }}</mat-label>
          <mat-select [(value)]="empfilter.defaultValue" (selectionChange)="applyEmpFilter($event, empfilter)">
            <mat-option *ngFor="let op of empfilter.options" [value]="op">
              {{ op }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        &nbsp; &nbsp;

        <button
          type="button"
          mat-raised-button
          matRipple
          (click)="doTransfer2Mandate()"
          [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
          class="mb-3 flex-none bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
          [style]="{ 'margin-left': '.5em', 'max-height': '39px' }">
          Zu Mandate übertragen
        </button>

        <div class="flex gap-4"></div>
      </div>

      <div class="example-container">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z0"
          matSort
          matSortActive="id"
          matSortDisableClear
          matSortDirection="desc"
          matTableExporter
          #exporter="matTableExporter">
          <!----------------------------------------------------------------- -->

          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let row">{{ row.id }}</td>
          </ng-container>

          <!-- transferred STATUS Column -->
          <ng-container matColumnDef="transferred">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Status</th>
            <td mat-cell *matCellDef="let row">
              <div
                *ngIf="row.status_id == '0'"
                data-te-chip-init
                data-te-ripple-init
                class="[word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                ENTWURF
              </div>

              <div
                *ngIf="row.status_id == '1'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-approved [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                TRANSFER
              </div>

              <div
                *ngIf="row.status_id == '2'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-success [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                ÜBERTRAGEN
              </div>

              <div
                *ngIf="row.status_id == '3'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-invalid [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                UNGÜLTIG
              </div>

              <div
                *ngIf="row.status_id == '9'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-error [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                ERROR
              </div>
            </td>
          </ng-container>

          <!-- Profilbild Column
          <ng-container matColumnDef="profile_image">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-50">&nbsp;</th>
            <td mat-cell *matCellDef="let row">
              <phuture-iavatar src_image="{{ imageStorage }}{{ row.profile_image }}"></phuture-iavatar>
            </td>
          </ng-container>
          -->

          <!-- aktenzeichen Column -->
          <ng-container matColumnDef="aktenzeichen">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-80">Aktenzeichen</th>
            <td mat-cell *matCellDef="let row" class="font-semibold">&nbsp;{{ row.aktenzeichen }}&nbsp;</td>
          </ng-container>

          <!----------------------------------------------------------------- -->

          <!-- kzland Column -->
          <ng-container matColumnDef="kzland">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.kzland }}&nbsp;</td>
          </ng-container>

          <!-- kzbezirk Column --
          <ng-container matColumnDef="kzbezirk">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Bezirk</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.kzbezirk }}&nbsp;</td>
          </ng-container>
          -->

          <!-- kznummer Column -->
          <ng-container matColumnDef="kznummer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nummer</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.kznummer }}&nbsp;</td>
          </ng-container>

          <!-- plate Column
          <ng-container matColumnDef="plate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kennzeichen</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.plate }}&nbsp;</td>
          </ng-container>
          -->

          <!-- street Column -->
          <ng-container matColumnDef="street">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Straße</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.street }}&nbsp;</td>
          </ng-container>

          <!-- tat_datum Column -->
          <ng-container matColumnDef="tat_datum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tatdatum</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.tat_datum }}&nbsp;</td>
          </ng-container>

          <!-- tat_zeit Column -->
          <ng-container matColumnDef="tat_zeit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tatzeit</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.tat_zeit }}&nbsp;</td>
          </ng-container>

          <!-- tatbestnr Column
          <ng-container matColumnDef="tatbestnr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tatbestand</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.tatbestnr }}&nbsp;</td>
          </ng-container>
          -->

          <!-- createtime Column
          <ng-container matColumnDef="createtime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tatzeit</th>
            <td mat-cell *matCellDef="let row"></td>
          </ng-container>
          -->

          <!-- printcount Column
          <ng-container matColumnDef="printcount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Version</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.printcount }}&nbsp;</td>
          </ng-container>
          -->

          <!-- person Column -->
          <ng-container matColumnDef="person">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Person</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.person }}&nbsp;</td>
          </ng-container>

          <!----------------------------------------------------------------- -->

          <!-- checked Column -->
          <ng-container matColumnDef="checked">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Kontrolle</th>
            <td mat-cell *matCellDef="let row">
              <ng-container
                *ngIf="row.errorId !== undefined && row.errorId !== null && row.errorId !== 999 && row.errorId !== 100">
                <mat-icon
                  class="error-icon"
                  matTooltip="{{ row.errorMessage }}"
                  matTooltipPosition="above"
                  class="alert"
                  >error</mat-icon
                >
              </ng-container>

              <ng-container *ngIf="row.errorId == 100">
                <mat-icon matTooltip="In Arbeit" matTooltipPosition="above" class="success">none</mat-icon>
              </ng-container>

              <ng-container *ngIf="row.errorId == 999">
                <mat-icon matTooltip="Alles OK" matTooltipPosition="above" class="success">check</mat-icon>
              </ng-container>
            </td>
          </ng-container>

          <!-- actions button bar Column -->

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
            <td mat-cell *matCellDef="let row">
              <button
                type="button"
                matRipple
                [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
                class="bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
                [style]="{ 'margin-left': '.5em' }"
                (click)="showPageDetail(row.id)"
                [queryParams]="{ debug: true }"
                fragment="education">
                Detail
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let emprow; columns: displayedColumns" (click)="showPageDetail(emprow.id)"></tr>
        </table>

        <mat-paginator
          #paginator
          [length]="totalItems"
          [pageSizeOptions]="pageSizes"
          showFirstLastButtons></mat-paginator>
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>

      <!--
      <button
        type="button"
        mat-raised-button
        matRipple
        (click)="checkTransfer()"
        [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
        class="bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
        [style]="{ 'margin-left': '.5em' }">
        1. Kontrolle
      </button>
      &nbsp; &nbsp;

      <br />
      <p>&nbsp;</p>

      <div *ngIf="checking" class="checkJournal">
        <p id="checkJournal" [innerHTML]="checkJournal"></p>
      </div>

      <div *ngIf="errorsFound">
        <p class="alert">Es wurden Fehler gefunden. Die fehlerhaften Datensätze werden nicht übertragen.</p>
      </div>
      <p>&nbsp;</p>

      <button
        type="button"
        matRipple
        *ngIf="isChecked"
        [disabled]="!isChecked"
        [ngClass]="isChecked ? '  ' : 'disabled'"
        (click)="doTransfer2Mandate()"
        [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
        class="bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
        [style]="{ 'margin-left': '.5em' }">
        2. &Uuml;berf&uuml;hren zu Mandate
      </button>

      <p>&nbsp;</p>

      <div *ngIf="transferSuccess">
        <p class="success">Transfer Einträge wurden erfolgreich zu Mandate übertragen.</p>
      </div>
      <p>&nbsp;</p>
      -->
    </div>
  </div>
</div>
