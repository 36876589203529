<div class="container">
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-8 d-flex align-items-center mt-4 mt-md-0">
          <div style="text-align: left">
            <h1>Support kontaktieren</h1>
            <p class="margin-top-s">
              Du brauchst Service oder Support?<br />Stell deine Anfrage online und wir finden eine Lösung für dich.
            </p>
            <form (ngSubmit)="sendSupport()" [formGroup]="supportForm" novalidate>
              <div class="card mb-4 box-shadow">
                <div class="card-header">
                  <h4 class="my-0 font-weight-normal">
                    <mat-form-field style="width: 100%" [hideRequiredMarker]="true">
                      <input type="text" matInput formControlName="subject" [placeholder]="'Betreff'" required />
                      <mat-error *ngIf="supportForm.controls['subject'].invalid && supportForm.controls['subject'].touched">
                        <span>Betreff muss angegeben werden</span>
                      </mat-error>
                    </mat-form-field>
                  </h4>
                </div>
                <div class="card-body">
                  <mat-form-field style="width: 100%" [hideRequiredMarker]="true">
                    <textarea
                      matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="5"
                      cdkAutosizeMaxRows="5"
                      formControlName="message"
                      [placeholder]="'Nachricht'"
                      required></textarea>

                    <mat-error *ngIf="supportForm.controls['message'].invalid && supportForm.controls['message'].touched">
                      <span>Nachricht muss angegeben werden</span>
                    </mat-error>
                  </mat-form-field>

                  <p>&nbsp;</p>

                  <button
                    class="btnLogin"
                    mat-focus-indicator
                    mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="supportForm.invalid">
                    <span>Anfrage senden</span>
                  </button>
                </div>
              </div>
            </form>
            <p>&nbsp;</p>
            <p>
              Wenn du uns direkt kontaktieren willst: <br />
              <strong>
                <br />
                support@parkraumplus.at <br />
                +44 1 890 39 54 <br />
                <br />
                Pappenheimgasse 33/10<br />
                1200 Wien<br />
                Austria<br />
                <br />
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
