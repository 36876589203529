import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '@core';
import { AuthDataService } from '../auth-data.service';

const log = new Logger('RegisterNew');

@Component({
  selector: 'app-register-new-page',
  templateUrl: './register-new-page.component.html',
  styleUrls: ['./register-new-page.component.scss'],
})
export class RegisterNewPageComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  registerForm!: FormGroup;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthDataService,
    private _snackBar: MatSnackBar
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  private createForm() {
    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      pwdconfirm: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      knr: [],
      company: ['', Validators.required],
      street: [],
      zip: [],
      city: [],
      country: [],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      branche: ['', Validators.required],
      uid: [],
    });
  }

  gotoLogin() {
    this.router.navigateByUrl('/login');
  }

  registerNew() {
    console.log('check server > register new');

    const register$ = this.authService.register(this.registerForm.value);
    register$
      .pipe(
        finalize(() => {
          this.registerForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          log.debug(`${data.name} successfully logged in`);
          this.router.navigate(
            [this.route.snapshot.queryParams['redirect'] || '/'],
            { replaceUrl: true }
          );
          /*
          if (data.code == 200) {

          } else {
            this._snackBar.open('Benutzername existiert bereits.', 'Schließen', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
          */
        },
        (error: any) => {
          log.debug(`Login error: ${error}`);
          this.error = error;

          this._snackBar.open('Benutzername existiert bereits', 'Schließen', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      );
  }
}
