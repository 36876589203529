<div>
  <div class="authGridContainer authGridContainer--login authGridContainer--fullScreen">
    <a class="authGrid__logo" href="/" aria-label="Link to main page" tabindex="0">
      <img src="./assets/parkraumplus-logo.png" alt="ParkraumPlus" />
    </a>
    <div class="authGridColumn authGridColumn--callToActionParent">
      <div class="createFreeAccount">
        <span class="createFreeAccount__text" style="font-size: 1.6em; line-height: 30px">Die effiziente Parkarumverwaltung</span>
      </div>
    </div>
    <div class="authGridColumn authGridColumn--formParent">
      <div class="formContainer loginForm">
        <h2>Kennwort vergessen?</h2>

        <form (ngSubmit)="forgotPwd()" [formGroup]="loginForm" novalidate>
          <div class="login-error" [hidden]="!error || isLoading">E-Mail oder Kennwort incorrect.</div>
          <br />
          <div class="login-fields" fxLayout="column">
            <mat-form-field [hideRequiredMarker]="true">
              <input type="text" matInput formControlName="email" autocomplete="email" [placeholder]="'E-Mail'" required />
              <mat-error *ngIf="loginForm.controls.email.invalid && loginForm.controls.email.touched">
                <span>E-Mail muss angegeben werden</span>
              </mat-error>
            </mat-form-field>
            <br />
            <button
              class="btnLogin"
              mat-focus-indicator
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="loginForm.invalid || isLoading">
              <span>Kennwort zur&uuml;cksetzen</span>
            </button>

            <br />
            <br />
            <button mat-flat-button style="padding: 0px" (click)="gotoLogin()">zur&uuml;ck zum Anmelden</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
