import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';

import { IAppDataModel, AppDataState } from './appdata.model';

/*
export interface AppDataState extends EntityState<IAppDataModel, number> {
  appdata: string;
}
*/

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'appdata', resettable: true })
export class AppDataStore extends EntityStore<AppDataState> {
  constructor() {
    super();
  }
}
