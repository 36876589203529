import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpParamsOptions, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { map, tap } from 'rxjs/operators';

import { SortDirection } from '@angular/material/sort';

/*
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};
*/

export interface MandatImage {
  id: number;
  aktenzeichen: string;
  url: string;
  longitude: string;
  lattitude: string;
  createtime: string;
  createdate: string;
  info1: string;
  info2: string;
  info3: string;
  filename: string;
  fid: string;
}

export interface MandatImageSingle {
  data: MandatImage;
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

export interface MandatImageTable {
  data: MandatImage[];
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
  }),
};

//
// --------------------------------------------------------------
//

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  errorMsg: string = '';

  constructor(private httpClient: HttpClient) {}

  /**
   * GET ALL
   *
   * @return response()
   */

  public getImages(
    //pageNumber: Number,
    //pageSize: Number,
    //sort: string,
    //order: SortDirection,
    searchTerm: string,
    //filtersArgs: string,
    aktenzeichen: string
  ): Observable<MandatImageTable> {
    const apiUrl = `${environment.serverUrl}/images`;
    // '?page=${pageNumber}&per_page=${pageSize}&sort=${sort}&sorder=${order}`

    return this.httpClient.get<MandatImageTable>(apiUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
      }),
      params: new HttpParams()
        //.set('page', '' + pageNumber)
        //.set('per_page', '' + pageSize)
        //.set('sort', sort)
        //.set('sorder', order)
        .set('sterm', searchTerm)
        //.set('filters', filtersArgs)
        .set('aktenzeichen', aktenzeichen),
    });

    /*
    .pipe(
      map((response: VisitorTable) => {
        //debugger;
        return response.data.map((item: Visitor) => ({
          ...item,
        }));
      }),
      tap((response: any) => {
        console.log('mrio');
        console.log(response);
        return <Visitor[]>response;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
    */
  }

  /**
   * GET / EXIF IMAGE DATA
   *
   * @return response()
   */
  getExifImageData(imageFilename: string): Observable<any> {
    const apiUrl = `${environment.serverUrl}/exif`;

    return this.httpClient
      .get<any>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
        }),
        params: new HttpParams().set('f', '' + imageFilename),
      })
      .pipe(
        map((response: any) => {
          return {
            ...response,
          };
        })
      );
  }

  /**
   * RECOGNIZE IMAGE - PLATE RECOGNITION
   *
   * @return response()
   */
  recognizeImage(filename: string): Observable<MandatImageSingle> {
    const apiUrl = `${environment.serverUrl}/anpr`;

    return this.httpClient
      .get<MandatImageSingle>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
        }),
        params: new HttpParams().set('f', '' + filename),
      })
      .pipe(
        map((response: MandatImageSingle) => {
          return {
            ...response,
          };
        })
      );
  }
  /**
   * GET / FIND BY ID
   *
   * @return response()
   */
  getTransferMandatById(id: number): Observable<MandatImageSingle> {
    const apiUrl = `${environment.serverUrl}/image/` + id;

    return this.httpClient
      .get<MandatImageSingle>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
        }),
        params: new HttpParams().set('id', '' + id),
      })
      .pipe(
        map((response: MandatImageSingle) => {
          return {
            ...response,
          };
        })
      );

    /*
    return this.httpClient.get<Visitor[]>(environment.serverUrl + '/visitor/' + id, httpOptions).pipe(
      tap((resp) => {
        //this.repo.setQuizes( resp || []);
        return resp;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
    */
  }

  /**
   * GET / FIND BY FILTER
   *
   * @return response()
   */
  getTransferMandatByParams(data: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    //const data: any = { this: 'thisThing', that: 'thatThing', other: 'otherThing'};

    const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;

    const options = { params: new HttpParams(httpParams), headers: headers };

    return this.httpClient.get<MandatImage[]>(environment.serverUrl + '/image/', options).pipe(
      tap((resp) => {
        //this.repo.setQuizes( resp || []);
        return resp;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * CREATE
   *
   * @return response()
   */
  createVisitor(post: any): Observable<any> {
    return this.httpClient.post<any>(environment.serverUrl + '/image/', JSON.stringify(post), httpOptions).pipe(
      map((response) => {
        return {
          ...response.data,
          id: response.data.quuid,
          quizdata: '',
        };

        /*
          response.data.map((item) => ({
            ...item,
            id: item.quuid,
            quizdata: ''
          }));

          return response;
          */
      }),
      tap((response: any) => {
        console.log(response);
        //this.repo.addQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * UPDATE
   *
   * @return response()
   */
  updateVisitor(person: MandatImage): Observable<any> {
    let id = person.id;
    const data = {
      //'id': quiz.qdata.id,
      quuid: '' + person.id,
    };

    // do the update
    /*     return this.httpClient.post(environment.serverUrl + '/person/' + id, data, httpOptions)
      .pipe(
        catchError((res: HttpErrorResponse) => {
            console.log(res);
            return throwError(JSON.stringify(res));

        })
      );*/

    return this.httpClient.post<any>(environment.serverUrl + '/image/' + id, JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.data,
          id: response.data.quuid,
          quizdata: '',
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * DELETE
   *
   * @return response()
   */
  deleteVisitor(id: string) {
    return this.httpClient.delete(environment.serverUrl + '/image/' + id, httpOptions).pipe(
      tap((value) => {
        //this.repo.removeQuiz(id);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getServerErrorMessage(error: HttpErrorResponse) {
    console.log('custom service error handler');

    switch (error.status) {
      case 0: {
        // A client-side or network error occurred. Handle it accordingly.
        //console.error('An error occurred:', error.error);
        return `An error occurred: ${error.error}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.error}`;
      }

      // Return an observable with a user-facing error message.
      //return throwError(() => new Error('Something bad happened; please try again later.'));
    }
  }
}
