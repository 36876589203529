import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { AuthStore, AuthState } from './auth.store';

@Injectable({
  providedIn: 'root',
})
export class AuthQuery extends Query<AuthState> {
  public isLoggedIn$ = this.select((state) => toBoolean(state.access_token));

  public name$ = this.select((state) => state.name);
  public email$ = this.select((state) => state.email);
  public firststart$ = this.select((state) => state.firststart);

  public appid$ = this.select((state) => state.appid);
  public appdata$ = this.select((state) => state.appdata);
  public userid$ = this.select((state) => state.userid);

  public fid$ = this.select((state) => state.fid);
  public role$ = this.select((state) => state.role);

  public profile_image$ = this.select((state) => state.profile_image);

  public access_token$ = this.select((state) => state.access_token);
  public version$ = this.select((state) => state.version);

  constructor(protected override store: AuthStore) {
    super(store);
  }

  get isLoggedIn() {
    return !!this.getValue().access_token;
  }

  get name() {
    return this.getValue().name;
  }

  get email() {
    return this.getValue().email;
  }

  get appid() {
    return this.getValue().appid;
  }

  get appdata() {
    return this.getValue().appdata;
  }

  get userid() {
    return this.getValue().userid;
  }

  get profile_image() {
    return this.getValue().profile_image;
  }

  get firststart_done() {
    return this.getValue().firststart_done;
  }

  set firststart_done(value) {
    this.getValue().firststart_done = value;
  }

  get access_token() {
    return this.getValue().access_token;
  }

  get version() {
    return this.getValue().version;
  }

  get role() {
    return this.getValue().role;
  }

  get fid() {
    return this.getValue().fid;
  }

  get country() {
    return this.getValue().country;
  }
}
