import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '@env/environment';
import { Logger } from '@core';
import { AuthDataService } from '../auth-data.service';

import { JournalService, MailService, Mail } from '@core';
import { CookieService } from 'ngx-cookie-service';

const log = new Logger('Login');

@Component({
  selector: 'app-login',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;
  eye: boolean = false;
  eyeIcon: string = 'visibility_off';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthDataService,
    private _snackBar: MatSnackBar,
    private mailService: MailService,
    private journalService: JournalService,
    private cookieService: CookieService
  ) {
    this.createForm();
  }

  ngOnInit() {}

  ngOnDestroy() {}

  gotoRegisterNew() {
    this.router.navigateByUrl('/register-new');
  }

  gotoForgotPwd() {
    this.router.navigateByUrl('/forgot-pwd');
  }

  toggleEye() {
    this.eye = !this.eye;
    this.eyeIcon = this.eye ? 'visibility' : 'visibility_off';
  }

  login() {
    this.isLoading = true;

    const login$ = this.authService.login(this.loginForm.value);
    login$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data) => {
          //
          // check for error
          //
          if (data.status === 401) {
            console.log('login error');

            // show error message
            // username or password wrong
            this._snackBar.open('Error: Username or password were wrong.', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
              // panelClass: ['mat-toolbar', 'mat-primary'],
            });
            return;
          } else {
            //
            // everything went OK
            //
            log.debug(`${data.name} successfully logged in`);

            //const access_token = this.authQuery.access_token;
            //const userid = this.authQuery.userid;
            //const fid = this.authQuery.fid;
            //const role = this.authQuery.role;

            this.journalService.log('Anmeldung erfolgreich.   --------------------------------------', '', '');
            this.cookieService.set('logged-on-today', '1', new Date()); // remember me funktion

            // create a cookie
            // not implemented yet
            // check if firststart is done
            if (data.firststart_done == 1 && data.isactivated == 1) {
              // if the user is an admin
              // go to the new dashboard

              if (data.role == 1) {
                // admin
                this.router.navigate(['/dashboard']);
              } else {
                // visitor or exhibitor
                this.router.navigate(['/error-404']);
              }
            } else {
              // if (data.firststart_done == 0)

              // goto firststart
              /*
              wir haben keinen firststart
                let uid = this.authQuery.userid;
                let fid = this.authQuery.fid;
                let role = this.authQuery.role;
                let country = this.authQuery.country;
                let firststart_done = this.authQuery.firststart_done;

                this.router.navigate(['/firststart', { id: fid, _uid: uid, _fid: fid, _role: 3, _country: country, ia: 0 }]);
                */
              this.router.navigate(['/error-404']);
            }
          }
        },
        (error) => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }
}
