<div class="mx-auto max-w-7xl mt-50 px-4 pb-12 sm:px-6 lg:px-8">
  <div class="rounded-lg bg-white px-1 py-6 sm:px-6" style="margin-top: 0px">
    <div class="row">
      <div class="col-md-12 order-md-1 order-sm-2">
        <!-- MAIN CONTENT START -->

        <form [formGroup]="frmMandat">
          <!-- mat-horizontal-stepper #stepper labelPosition="bottom">-->

          <mat-tab-group animationDuration="0ms">
            <mat-tab label="Mandat">
              <div class="step-container d-flex align-items-start flex-column">
                <div class="container-fluid mt-6">
                  <div class="form">
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <!-- Company -->

                        <div class="form-group">
                          <label class="form-label" for="aktenzeichen"> Aktenzeichen *</label>
                          <input
                            type="text"
                            class="form-control"
                            style="background-color: #fff !important"
                            id="aktenzeichen"
                            formControlName="aktenzeichen"
                            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Aktenzeichen"
                            readonly />
                        </div>

                        <!-- Kennzeichen (land, bezirk, nummer) -->
                        <div class="flex flex-wrap -mx-3 mb-2">
                          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <div class="form-group">
                              <label class="form-label" for="land"> Land *</label>
                              <ng-container *ngIf="stammdaten?.lander">
                                <select
                                  class="form-control"
                                  id="land"
                                  formControlName="land"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                  <option
                                    *ngFor="let land of stammdaten.lander | sort : 'bezeichnung' : 'asc'"
                                    [value]="land.bezeichnung">
                                    {{ land.bezeichnung }}
                                  </option>
                                </select>
                              </ng-container>
                            </div>
                          </div>

                          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <div class="form-group">
                              <label class="form-label" for="bezirk"> Bezirk *</label>
                              <!--
                              <select
                                class="form-control"
                                id="land"
                                formControlName="land"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option
                                  *ngFor="let bezirk of filteredBezirke$ | async | sort : 'bezeichnung' : 'asc'"
                                  [value]="bezirk.bezeichnung">
                                  {{ bezirk.bezeichnung }}
                                </option>
                              </select>
                            -->
                              <input
                                type="text"
                                class="form-control"
                                id="bezirk"
                                formControlName="bezirk"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Bezirk" />
                            </div>
                          </div>

                          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <div class="form-group">
                              <label class="form-label" for="nummer"> Nummer *</label>
                              <input
                                type="text"
                                class="form-control"
                                id="nummer"
                                formControlName="nummer"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Your Nummer" />
                            </div>
                          </div>
                        </div>

                        <!-- Hersteller -->
                        <div class="form-group">
                          <label class="form-label" for="hersteller"> Hersteller *</label>
                          <ng-container *ngIf="stammdaten?.hersteller">
                            <select
                              class="form-control"
                              id="hersteller"
                              formControlName="hersteller"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                              <option
                                *ngFor="let hersteller of stammdaten.hersteller | sort : 'bezeichnung' : 'asc'"
                                [value]="hersteller.bezeichnung">
                                {{ hersteller.bezeichnung }}
                              </option>
                            </select>
                          </ng-container>
                        </div>

                        <!-- Farbe -->
                        <div class="form-group">
                          <label class="form-label" for="farbe"> Farbe *</label>

                          <select
                            class="form-control"
                            id="farbe"
                            formControlName="farbe"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option
                              *ngFor="let farbe of stammdaten?.farben | sort : 'bezeichnung' : 'asc'"
                              [value]="farbe?.bezeichnung">
                              {{ farbe?.bezeichnung }}
                            </option>
                          </select>

                          <ng-container *ngIf="stammdaten?.farben"> </ng-container>
                        </div>

                        <!-- Fahrzeugart -->
                        <div class="form-group">
                          <label class="form-label" for="fahrzeugart"> Fahrzeugart *</label>
                          <select
                            class="form-control"
                            id="fahrzeugart"
                            formControlName="fahrzeugart"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="930">KFZ</option>
                          </select>
                        </div>

                        <!-- probekennzeichen -->
                        <div class="form-group">
                          <label class="form-label" for="hersteller"> Probekennzeichen *</label>

                          <mat-slide-toggle
                            class="example-margin"
                            [color]="'primary'"
                            formControlName="probekennzeichen">
                            <span class="text-gray-900 text-sm"> Probekennzeichen </span>
                          </mat-slide-toggle>
                        </div>

                        <!-- Interne Notizen -->
                        <div class="form-group">
                          <label class="form-label" for="internal_notes"> Interne Notizen *</label>
                          <textarea
                            id="internal_notes"
                            formControlName="internal_notes"
                            rows="4"
                            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""></textarea>
                          <button class="mt-2" mat-raised-button (click)="abmahnung()">Abmahnung</button> <br />
                        </div>
                      </div>

                      <div class="col-12 col-lg-3">
                        <div class="row bg-gray-200">
                          <!-- STATUS -->
                          <div class="col-12 col-md-6">
                            <div class="status-text">{{ mandat?.Md_Status }}</div>
                            <span *ngIf="mandat?.Md_Status == 'E'">Erfasst</span>
                            <span *ngIf="mandat?.Md_Status == 'T'">Teil-Erfasst</span>
                            <span *ngIf="mandat?.Md_Status == 'U'">Überfällig</span>
                            <span *ngIf="mandat?.Md_Status == 'A'">Angezeigt</span>
                            <span *ngIf="mandat?.Md_Status == 'B'">Bezahlt</span>
                            <span *ngIf="mandat?.Md_Status == 'AB'">Angezeigt / Bezahlt</span>
                            <span *ngIf="mandat?.Md_Status == 'S'">Storno</span>
                          </div>
                          <div class="col-12 col-md-6">
                            <div class="btn-group-vertical p-2 statusBar">
                              <button
                                mat-raised-button
                                (click)="saveStatusChange(StatusMandat.ERFASST)"
                                color="primary">
                                Erfasst
                              </button>
                              <br />
                              <button
                                mat-raised-button
                                (click)="saveStatusChange(StatusMandat.TEILERFASST)"
                                color="primary">
                                Teil-Erfasst
                              </button>
                              <br />
                              <button
                                mat-raised-button
                                (click)="saveStatusChange(StatusMandat.UBERFALLIG)"
                                color="primary">
                                Überfällig
                              </button>
                              <br />
                              <button
                                mat-raised-button
                                (click)="saveStatusChange(StatusMandat.ANGEZEIGT)"
                                color="primary">
                                Angezeigt
                              </button>
                              <br />
                              <button
                                mat-raised-button
                                (click)="saveStatusChange(StatusMandat.BEZAHLT)"
                                color="primary">
                                Bezahlt
                              </button>
                              <br />
                              <button
                                mat-raised-button
                                (click)="saveStatusChange(StatusMandat.ANZEIGEBEZAHLT)"
                                color="primary">
                                Ang. Bezahlt
                              </button>
                              <br />
                              <button mat-raised-button (click)="saveStatusChange(StatusMandat.STORNO)" color="primary">
                                Storno
                              </button>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3">
                        <!-- haupt bild -->
                        <div *ngFor="let img of mandatImages; let i = index">
                          <div *ngIf="i === 0">
                            <img
                              class="w-full rounded-lg sm:rounded-lg"
                              src="{{ imageStorage }}img?f={{ img.filename }}"
                              (click)="openImageInNewWindow(img.filename)"
                              style="cursor: pointer" />
                            <!-- image-viewer.component.html -->
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-lg-6 mt-4" *ngIf="mandat.vstv_packet > 0">
                        <div class="row bg-gray-200 p-2">
                          <p>
                            <span class="text-gray-900 text-sm">
                              Das Mandate ist Teil vom
                              <span class="text-bold">VSTV-Paket #{{ mandat.vstv_packet }}</span>
                            </span>
                            <br />
                            <span class="text-red-900 text-sm" *ngIf="mandat.vstv_ignore > 0">
                              Das Mandate wurde aus dem VSTV-Paket ausgenommen.
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Tatbestand">
              <div class="step-container d-flex align-items-start flex-column">
                <div class="container-fluid mt-6">
                  <div class="form">
                    <div class="grid gap-6 mb-6 md:grid-cols-2">
                      <!--  -->
                      <div class="flex flex-wrap -mx-3 mb-2">
                        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="tat_datum"> Datum *</label>
                            <input
                              type="text"
                              class="form-control"
                              id="tat_datum"
                              formControlName="tat_datum"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="" />
                          </div>
                        </div>

                        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="tat_zeit"> Tatzeit von*</label>
                            <input
                              type="text"
                              class="form-control"
                              id="tat_zeit"
                              formControlName="tat_zeit"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="" />
                          </div>
                        </div>

                        <!--
                        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="tatzeit"> Tatzeit bis*</label>
                            <input
                              type="text"
                              class="form-control"
                              id="tatzeit"
                              formControlName="tatzeit"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="" />
                          </div>
                        </div>
                        -->

                        <div class="form-group pl-4">
                          <span class="text-gray-900 text-sm">
                            Tatzeit aus Bild:
                            {{ getExifData(this.mandatImages[0].filename) }}
                          </span>
                          <br />
                          <button
                            *ngIf="
                              role == 1 && getExifData(this.mandatImages[0].filename) != 'Zeitstempel nicht gefunden'
                            "
                            mat-raised-button
                            (click)="fetchTatzeitFromImage()"
                            color="secondary">
                            Tatzeit übernehmen
                          </button>
                        </div>
                      </div>

                      <!--  -->
                      <div class="flex flex-wrap mb-2">
                        <div class="w-full md:w-3/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="tatbestandNr"> Tatbestand Nummer *</label>

                            <div class="flex items-center space-x-2">
                              <select
                                class="form-control"
                                id="allTatbestande"
                                formControlName="allTatbestande"
                                class="mb-2 py-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option
                                  *ngFor="let tatbestand of stammdaten?.tatbestande | sort : 'vstv_code' : 'asc'"
                                  [value]="tatbestand?.vstv_code">
                                  <span style="font-weight: bold">{{ tatbestand?.vstv_code | padString : 10 }} </span> |
                                  {{ tatbestand?.paragraph }}
                                  {{ tatbestand?.short }}
                                </option>
                              </select>
                              <button class="mb-2 mt-0" mat-raised-button (click)="addTatbestand()" color="secondary">
                                +
                              </button>
                            </div>

                            <table class="table table-responsive table-bordered table-sm w-100 text-sm">
                              <thead>
                                <tr>
                                  <th>VSTV-Code</th>
                                  <th colspan="2">Paragraph</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody
                                formArrayName="tatbestande"
                                *ngFor="let tatbestand of tatbestandeFormArray.controls; let i = index">
                                <ng-container [formGroupName]="i">
                                  <tr>
                                    <th>{{ transfer_tatbestande[i]?.vstv_code }}</th>
                                    <td colspan="2">
                                      {{ transfer_tatbestande[i]?.short }}
                                      <div *ngIf="transfer_tatbestande[i]?.luecken.length > 0">
                                        <table class="table mb-0">
                                          <tr
                                            *ngFor="
                                              let lueckeControl of tatbestand.get('luecken').controls;
                                              let u = index
                                            ">
                                            <td>{{ transfer_tatbestande[i]?.luecken[u].titel }}</td>
                                            <td>
                                              <input
                                                type="text"
                                                [formControl]="lueckeControl"
                                                class="form-control bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="{{ transfer_tatbestande[i].luecken[u].default }}" />
                                            </td>
                                            <td>
                                              <button
                                                mat-icon-button
                                                placement="bottom"
                                                [ngbPopover]="transfer_tatbestande[i].luecken[u].info"
                                                popoverTitle="Ausfüllhilfe">
                                                <mat-icon style="color: #adb5bd">info_outline</mat-icon>
                                              </button>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        (click)="removeTatbestand(i, transfer_tatbestande[i]?.vstv_code)"
                                        class="px-2 py-1 bg-transparent text-sm text-black hover:text-gray hover:text-gray transition-colors duration-150">
                                        [Löschen]
                                      </button>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>

                            <!--
                            <table class="table table-responsive table-bordered table-sm w-100 text-sm">
                              <thead>
                                <tr>
                                  <th>VSTV-Code</th>
                                  <th colspan="2">Paragraph</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody *ngFor="let tatbestand of transfer_tatbestande; let i = index">
                                <tr>
                                  <th>{{ tatbestand.vstv_code }}</th>
                                  <td colspan="2">
                                    {{ tatbestand.short }}

                                    <div *ngIf="tatbestand.luecken.length > 0">
                                      <table class="table mb-0">
                                        <tr *ngFor="let luecke of tatbestand.luecken; let u = index">
                                          <td>{{ luecke.titel }}</td>

                                          <td>
                                            <input
                                              type="text"
                                              class="form-control"
                                              id="tatbestandNr"
                                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                              placeholder="{{ luecke.default }}" />
                                          </td>
                                          <td>
                                            <button
                                              mat-icon-button
                                              placement="bottom"
                                              ngbPopover=" {{ luecke.info }} "
                                              popoverTitle="Ausfüllhilfe">
                                              <mat-icon style="color: #adb5bd"> info_outline</mat-icon>
                                            </button>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </td>
                                  <td>
                                    <button
                                      (click)="removeTatbestand()"
                                      class="px-2 py-1 bg-transparent text-sm text-black hover:text-gray hover:text-gray transition-colors duration-150">
                                      [Löschen]
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <p>datenstruktur</p>
                            {{ transfer_tatbestande | json }}

                            <p>&nbsp;</p>
                            <p>Form Transfer:</p>
                            <p>{{ frmTransfer.value | json }}</p>

                            <p>&nbsp;</p>
                            <p>Luecken:</p>
                            <p>{{ tatbestandeFormArray.value | json }}</p>

                            <input
                              type="text"
                              class="form-control"
                              id="tatbestandNr"
                              formControlName="tatbestandNr"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="" />
                              -->
                          </div>
                        </div>
                      </div>

                      <!--  -->
                      <div class="flex flex-wrap -mx-3 mb-2">
                        <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="strasse"> Straße *</label>

                            <ng-container *ngIf="stammdaten?.streets">
                              <select
                                class="form-control"
                                id="strasse"
                                formControlName="strasse"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option
                                  *ngFor="let street of stammdaten.streets | sort : 'bezeichnung' : 'asc'"
                                  [value]="street.bezeichnung">
                                  {{ street.bezeichnung }}
                                </option>
                              </select>
                            </ng-container>
                          </div>
                        </div>

                        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="hausnummer"> Hausnummer *</label>
                            <input
                              type="text"
                              class="form-control"
                              id="hausnummer"
                              formControlName="hausnummer"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="" />
                          </div>
                        </div>

                        <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="hausnummer"> Erfasst von</label>
                            <span class="text-gray-900 text-sm">
                              {{ mandat?.person }} ({{ mandat?.Md_DienstNr }} / {{ mandat?.dienstnr_stvo }})
                            </span>
                          </div>
                        </div>

                        <!--

                        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="dienstNr"> Dienstnummer *</label>
                            <input
                              type="text"
                              class="form-control"
                              id="dienstNr"
                              formControlName="dienstNr"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder=""
                              readonly />
                          </div>
                        </div>

                        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="dienstNrSTVO"> Dienstnummer STVO *</label>
                            <input
                              type="text"
                              class="form-control"
                              id="dienstNrSTVO"
                              formControlName="dienstNrSTVO"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder=""
                              readonly />
                          </div>
                        </div>
-->
                      </div>

                      <!--  -->
                      <div class="flex flex-wrap -mx-3 mb-2">
                        <!--
                        <div class="w-full md:w-3/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="zusatz1"> Zusatz 1 *</label>
                            <input
                              type="text"
                              class="form-control"
                              id="zusatz1"
                              formControlName="zusatz1"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="" />
                          </div>
                        </div>
                        -->
                        <!--
                        <div class="w-full md:w-3/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="zusatz2"> Zusatz 2 *</label>
                            <input
                              type="text"
                              class="form-control"
                              id="zusatz2"
                              formControlName="zusatz2"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="" />
                          </div>
                        </div>
                        -->

                        <!--
                        <div class="w-full md:w-3/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="konkretisierung"> Konkretisierung *</label>
                            <input
                              type="text"
                              class="form-control"
                              id="konkretisierung"
                              formControlName="konkretisierung"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="" />
                          </div>
                        </div>
                        -->

                        <!-- Anmerkungen -->
                        <div class="w-full md:w-3/3 px-3 mb-6 md:mb-0">
                          <div class="form-group">
                            <label class="form-label" for="anmerkungen"> Anmerkungen *</label>
                            <textarea
                              id="anmerkungen"
                              formControlName="anmerkungen"
                              maxlength="70"
                              rows="4"
                              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder=""></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Zahlung">
              <div class="step-container d-flex align-items-start flex-column">
                <div class="container-fluid mt-6">
                  <p>Derzeit sind keine Zahlungsinformationen verfügbar.</p>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Bilder">
              <div class="step-container d-flex align-items-start flex-column">
                <div class="container-fluid mt-6">
                  <div class="form">
                    <h3>Erfasste Bilder zum Mandat:</h3>
                    <div class="grid gap-6 mb-6 md:grid-cols-3">
                      <div *ngFor="let img of mandatImages">
                        <img
                          class="w-full rounded-lg sm:rounded-lg"
                          src="{{ imageStorage }}img?f={{ img.filename }}"
                          (click)="openImageInNewWindow(img.filename)"
                          style="cursor: pointer" />
                        <!-- image-viewer.component.html

                        <button
                          mat-icon-button
                          #tooltip="matTooltip"
                          [matTooltip]="getExifData(img.filename)"
                          matTooltipPosition="above"
                          matTooltipHideDelay="5000">
                          <mat-icon>timelapse</mat-icon>
                        </button>
                        -->

                        <button class="mt-3" *ngIf="role == 1" mat-raised-button (click)="recognizePlate(img.filename)">
                          Erkennung
                        </button>
                        &nbsp;
                      </div>

                      <div class="p-3 bg-blue-100 bg-slate-100rounded-lg text-sm imageRecognitionResult">
                        <div>
                          <h2>Ergebnis Kennzeichenerkennung:</h2>
                          <mat-form-field appearance="fill">
                            <mat-label>Auswahl Ergebnis</mat-label>
                            <mat-select
                              formControlName="selection"
                              name="anpr"
                              (selectionChange)="onTrefferChange($event)">
                              <mat-option *ngFor="let result of plateReconResults; let i = index" [value]="i">
                                Treffer {{ i + 1 }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div *ngIf="getSelectedResult()">
                          <p><strong>Kennzeichen:</strong> {{ getSelectedResult().plate | uppercase }}</p>
                          <p>
                            <strong>Land:</strong> {{ getSelectedResult().region.code | uppercase | translateLand }}
                          </p>

                          <!-- Dropdown for selecting vehicle color -->
                          <mat-form-field appearance="fill">
                            <mat-label>Farbe</mat-label>
                            <mat-select formControlName="colorControl" name="color">
                              <mat-option
                                *ngFor="let color of getSelectedResult().color"
                                [value]="color.color | translateColor">
                                {{ color.color | translateColor }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <!-- Dropdown for selecting vehicle make -->
                          <mat-form-field appearance="fill">
                            <mat-label>Hersteller</mat-label>
                            <mat-select formControlName="makeControl" name="make">
                              <mat-option
                                *ngFor="let make of getSelectedResult().model_make"
                                [value]="make.make | translateHersteller">
                                {{ make.make | translateHersteller }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <button *ngIf="role == 1" mat-raised-button (click)="savePlateRecon()" color="secondary">
                            Übernehmen
                          </button>
                        </div>

                        <!--
                        <p>
                          {{ frmTransfer.value | json }}
                        </p>
                        -->

                        <!--
                        <form>
                          <h2>Plate: {{ plateRecon?.plate | uppercase }}</h2>
                          <h3>Region: {{ plateRecon?.region?.code }}</h3>
                          <h3>Hersteller: {{ modell?.make }}</h3>
                          <h3>Farbe: {{ farbe?.color }}</h3>
                          <h3>Richtung: {{ richtung?.orientation }}</h3>
                          <button *ngIf="role == 1" mat-raised-button (click)="savePlateRecon()" color="secondary">
                            Übernehmen
                          </button>
                          &nbsp;
                        </form>
                        -->
                      </div>

                      <div>
                        <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >Weitere Bilder auf Server laden</label
                        >
                        <file-pond
                          id="myPondProfile"
                          name="myPondProfile"
                          [options]="pondOptions"
                          [files]="pondFiles"
                          (oninit)="pondHandleInit()"
                          (onaddfile)="pondHandleAddFile($event)"
                          (onprocessfiles)="onProcessFiles($event)"
                          (onactivatefile)="pondHandleActivateFile($event)">
                        </file-pond>
                      </div>

                      <img
                        *ngIf="_profile_image"
                        class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                        src="{{ imageStorage }}{{ _profile_image }}"
                        alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Journal">
              <div class="step-container d-flex align-items-start flex-column">
                <div class="container-fluid mt-6">
                  <!-- journal item -->
                  <div class="relative px-4">
                    <div class="absolute h-full border border-dashed border-opacity-20 border-secondary"></div>

                    <!-- start::Timeline item -->
                    <div class="flex items-center w-full my-6 -ml-1.5" *ngFor="let item of journalItems">
                      <div class="w-1/12 z-10">
                        <div class="w-3.5 h-3.5 bg-blue-600 rounded-full"></div>
                      </div>
                      <div class="w-11/12">
                        <p class="text-sm journal-header">
                          <span class="journal-title">{{ item.aktion }}</span> - {{ item.image }}<br />
                          {{ item.category }}
                        </p>
                        <p class="text-xs text-gray-500 journal-content">
                          {{ item.created | date : 'dd.MM.yyyy, HH:mm' }} / {{ item.dienstname }} ({{ item.dienstnr }})
                          <br />
                          {{ item.mobile_id }}
                        </p>
                      </div>
                    </div>
                    <!-- end::Timeline item -->

                    <!-- end::Timeline item -->
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
          <p>&nbsp;</p>
          <div>
            <div>
              <button mat-raised-button (click)="sendPerMail()" color="primary">Akt per Mail senden</button>
              &nbsp;
              <button *ngIf="role == 1" mat-raised-button (click)="checkMandat()" color="primary">Prüfen</button>&nbsp;
              <button *ngIf="role == 1" mat-raised-button (click)="saveChanges()" color="primary">
                Änderungen speichern
              </button>
              &nbsp;
              <button *ngIf="role == 1" mat-raised-button (click)="closeOffcanvas()" color="secondary">
                Abbrechen
              </button>
              &nbsp; &nbsp;
            </div>

            <div class="bg-gray-200 text-sm mt-5 p-2.5" *ngIf="pruefung_positiv == 0">
              <h2>Prüfprotokoll</h2>
              <p style="color: #d51d1d" [innerHTML]="errorprotokoll"></p>
              <p [innerHTML]="pruefprotokoll"></p>
            </div>

            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            <!-- MAIN CONTENT END -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
