import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpParamsOptions, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { map, tap } from 'rxjs/operators';

import { SortDirection } from '@angular/material/sort';

import { AuthQuery } from '@app/auth/auth.query';

/*
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};
*/

export interface Journal {
  userid: string;
  dienstnr: null;
  dienstnr_stvo: null;
  created: Date;
  aktion: string;
  info: null;
  category: null;
  aktenzeichen: null;
  image: null;
  longitude: null;
  latitude: null;
  dienstname: null;
  mobile_id: null;
  gemeinde_id: null;
}

export interface JournalSingle {
  data: Journal;
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

export interface JournalTable {
  data: Journal[];
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
  }),
};

//
// --------------------------------------------------------------
//

@Injectable({
  providedIn: 'root',
})
export class JournalService {
  errorMsg: string = '';

  constructor(private httpClient: HttpClient, private authQuery: AuthQuery) {}

  /**
   * GET ALL
   *
   * @return response()
   */

  public getAll(
    pageNumber: Number,
    pageSize: Number,
    sort: string,
    order: SortDirection,
    searchTerm: string,
    filtersArgs: string
  ): Observable<JournalTable> {
    const apiUrl = `${environment.serverUrl}/journal`;
    // '?page=${pageNumber}&per_page=${pageSize}&sort=${sort}&sorder=${order}`

    return this.httpClient.get<JournalTable>(apiUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
      }),
      params: new HttpParams()
        .set('page', '' + pageNumber)
        .set('per_page', '' + pageSize)
        .set('sort', sort)
        .set('sorder', order)
        .set('sterm', searchTerm)
        .set('filters', filtersArgs)
        .set('webapp', 1),
    });
  }

  /**
   * GET / FIND BY ID
   *
   * @return response()
   */
  getItemById(id: number): Observable<JournalSingle> {
    const apiUrl = `${environment.serverUrl}/journal/` + id;

    return this.httpClient
      .get<JournalSingle>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
        }),
        params: new HttpParams().set('id', '' + id),
      })
      .pipe(
        map((response: JournalSingle) => {
          return {
            ...response,
          };
        })
      );
  }

  /**
   * GET / FIND BY FILTER
   *
   * @return response()
   */
  getItemsByParams(data: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    /*
      const data: any = {
        this: 'thisThing',
        that: 'thatThing',
        other: 'otherThing'
      };
     */

    const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;

    const options = { params: new HttpParams(httpParams), headers: headers };

    return this.httpClient.get<Journal[]>(environment.serverUrl + '/journalby', options).pipe(
      tap((resp) => {
        //this.repo.setQuizes( resp || []);
        return resp;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * CREATE
   *
   * @return response()
   */
  log(aktion: string, aktenzeichen: string, info: string): void {
    var data = {
      uid: '' + this.authQuery.userid,
      aktion: aktion,
      aktenzeichen: aktenzeichen,
      info: info,
      source: 'webapp',
      token: '' + this.authQuery.access_token,
    };
    console.log('write-to-lot ' + aktion);

    this.httpClient
      .post<any>(environment.serverUrl + '/journal', JSON.stringify(data), httpOptions)
      .pipe(
        map((response) => {
          return {
            ...response.data,
            //id: response.data.quuid,
            //quizdata: '',
          };
        }),
        tap((response: any) => {
          console.log(response);
          //this.repo.addQuiz(response);
        }),
        catchError((error) => {
          let errorMsg: string = '';
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }

          return throwError(errorMsg);
        })
      )
      .subscribe({
        next: (response) => {
          console.log('Server response:', response);
          return response;
          // Display the response in your component's template
          // or handle it as needed
        },
        error: (error) => {
          console.error('Error:', error);
          this.errorMsg = error;
          // Handle or display the error message in your component's template
        },
      });
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getServerErrorMessage(error: HttpErrorResponse) {
    console.log('custom service error handler');

    switch (error.status) {
      case 0: {
        // A client-side or network error occurred. Handle it accordingly.
        //console.error('An error occurred:', error.error);
        return `An error occurred: ${error.error}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.error}`;
      }

      // Return an observable with a user-facing error message.
      //return throwError(() => new Error('Something bad happened; please try again later.'));
    }
  }
}
