<div class="d-flex">
  <div class="row setting-card-container mx-auto">
    <mat-card class="mat-elevation-z4 w-100">
      <mat-card-title>Stammdaten</mat-card-title>
      <mat-card-content>
        <mat-tab-group>
          <!--
              LANDER
          -->
          <mat-tab label="L&auml;nder">
            <div class="container-fluid p-1">
              <div class="row w-100 m-0">
                <div class="col-sm-12 col-md-12 p-0">
                  <h2>L&auml;nder</h2>
                </div>
                <div class="col-sm-12 col-md-12 p-1">
                  <p class="p-4"><button mat-raised-button (click)="addNewLand()" color="primary">Neuanlage</button></p>
                  <table mat-table #tableLand [dataSource]="dataSourceLander" class="mat-elevation-z8">
                    <!-- id Column
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef>ID</th>
                      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>
                    -->

                    <!-- bezeichnung Column -->
                    <ng-container matColumnDef="bezeichnung">
                      <th mat-header-cell *matHeaderCellDef>Land</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.bezeichnung }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Land" [(ngModel)]="row.bezeichnung" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                      <td mat-cell *matCellDef="let row">
                        <button mat-button *ngIf="!row.isEditable" (click)="editItem('Land', row)" aria-label="Edit">
                          <mat-icon class="small-icon">edit</mat-icon> Edit
                        </button>
                        <button mat-button *ngIf="row.isEditable" (click)="saveItem('Land', row)" aria-label="Save">
                          <mat-icon class="small-icon">save</mat-icon> Save
                        </button>
                        <button mat-button *ngIf="row.isEditable" (click)="cancelEdit('Land', row)" aria-label="Cancel">
                          <mat-icon class="small-icon">close</mat-icon> Cancel
                        </button>
                        <button mat-button *ngIf="row.isEditable" (click)="removeItem('Land', row)" aria-label="Delete">
                          <mat-icon class="small-icon">delete</mat-icon> Delete
                        </button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayLander"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayLander" (dblclick)="editItem('Land', row)"></tr>
                  </table>
                </div>
              </div>
            </div>
          </mat-tab>

          <!--
              Bezirke
          -->
          <mat-tab label="Bezirke">
            <div class="container-fluid p-1">
              <div class="row w-100 m-0">
                <div class="col-sm-12 col-md-12 p-0">
                  <h2>Bezirke</h2>
                </div>
                <div class="col-sm-12 col-md-12 p-1">
                  <p class="p-4">
                    <button mat-raised-button (click)="addNewBezirk()" color="primary">Neuanlage</button>
                  </p>
                  <table mat-table #tableBezirk [dataSource]="dataSourceBezirke" class="mat-elevation-z8">
                    <!-- id Column
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef>ID</th>
                      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>
                    -->

                    <!-- Land Column -->
                    <ng-container matColumnDef="land">
                      <th mat-header-cell *matHeaderCellDef>Land</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.land }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Land" [(ngModel)]="row.land" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- Bezirk Column -->
                    <ng-container matColumnDef="bezeichnung">
                      <th mat-header-cell *matHeaderCellDef>Bezirk</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.bezeichnung }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Bezirk" [(ngModel)]="row.bezeichnung" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                      <td mat-cell *matCellDef="let row">
                        <button mat-button *ngIf="!row.isEditable" (click)="editItem('Bezirk', row)" aria-label="Edit">
                          <mat-icon class="small-icon">edit</mat-icon> Edit
                        </button>
                        <button mat-button *ngIf="row.isEditable" (click)="saveItem('Bezirk', row)" aria-label="Save">
                          <mat-icon class="small-icon">save</mat-icon> Save
                        </button>
                        <button
                          mat-button
                          *ngIf="row.isEditable"
                          (click)="cancelEdit('Bezirk', row)"
                          aria-label="Cancel">
                          <mat-icon class="small-icon">close</mat-icon> Cancel
                        </button>
                        <button
                          mat-button
                          *ngIf="row.isEditable"
                          (click)="removeItem('Bezirk', row)"
                          aria-label="Delete">
                          <mat-icon class="small-icon">delete</mat-icon> Delete
                        </button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayBezirke"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayBezirke" (dblclick)="editItem('Bezirk', row)"></tr>
                  </table>
                </div>
              </div>
            </div>
          </mat-tab>

          <!--
              Strasse
          -->
          <mat-tab label="Strassen">
            <div class="container-fluid p-1">
              <div class="row w-100 m-0">
                <div class="col-sm-12 col-md-12 p-0">
                  <h2>Strassen</h2>
                </div>
                <div class="col-sm-12 col-md-12 p-1">
                  <p class="p-4">
                    <button mat-raised-button (click)="addNewStreet()" color="primary">Neuanlage</button>
                  </p>
                  <table mat-table #tableStreet [dataSource]="dataSourceStrassen" class="mat-elevation-z8">
                    <!-- id Column
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef>ID</th>
                      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>
                    -->

                    <!-- Strasse Column -->
                    <ng-container matColumnDef="bezeichnung">
                      <th mat-header-cell *matHeaderCellDef>Straße</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.bezeichnung }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Straße" [(ngModel)]="row.bezeichnung" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- Tatortart Column -->
                    <ng-container matColumnDef="tatortart">
                      <th mat-header-cell *matHeaderCellDef>Tatortart</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.tatortart }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Tatortart" [(ngModel)]="row.tatortart" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                      <td mat-cell *matCellDef="let row">
                        <button mat-button *ngIf="!row.isEditable" (click)="editItem('Street', row)" aria-label="Edit">
                          <mat-icon class="small-icon">edit</mat-icon> Edit
                        </button>
                        <button mat-button *ngIf="row.isEditable" (click)="saveItem('Street', row)" aria-label="Save">
                          <mat-icon class="small-icon">save</mat-icon> Save
                        </button>
                        <button
                          mat-button
                          *ngIf="row.isEditable"
                          (click)="cancelEdit('Street', row)"
                          aria-label="Cancel">
                          <mat-icon class="small-icon">close</mat-icon> Cancel
                        </button>
                        <button
                          mat-button
                          *ngIf="row.isEditable"
                          (click)="removeItem('Street', row)"
                          aria-label="Delete">
                          <mat-icon class="small-icon">delete</mat-icon> Delete
                        </button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayStrassen"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayStrassen"
                      (dblclick)="editItem('Street', row)"></tr>
                  </table>
                </div>
              </div>
            </div>
          </mat-tab>

          <!--
              Farbe
          -->
          <mat-tab label="Farben">
            <div class="container-fluid p-1">
              <div class="row w-100 m-0">
                <div class="col-sm-12 col-md-12 p-0">
                  <h2>Farben</h2>
                </div>
                <div class="col-sm-12 col-md-12 p-1">
                  <p class="p-4">
                    <button mat-raised-button (click)="addNewFarbe()" color="primary">Neuanlage</button>
                  </p>
                  <table mat-table #tableFarbe [dataSource]="dataSourceFarben" class="mat-elevation-z8">
                    <!-- id Column
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef>ID</th>
                      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>
                    -->

                    <!-- Farbe Column -->
                    <ng-container matColumnDef="bezeichnung">
                      <th mat-header-cell *matHeaderCellDef>Farbe</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.bezeichnung }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Farbe" [(ngModel)]="row.bezeichnung" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                      <td mat-cell *matCellDef="let row">
                        <button mat-button *ngIf="!row.isEditable" (click)="editItem('Farbe', row)" aria-label="Edit">
                          <mat-icon class="small-icon">edit</mat-icon> Edit
                        </button>
                        <button mat-button *ngIf="row.isEditable" (click)="saveItem('Farbe', row)" aria-label="Save">
                          <mat-icon class="small-icon">save</mat-icon> Save
                        </button>
                        <button
                          mat-button
                          *ngIf="row.isEditable"
                          (click)="cancelEdit('Farbe', row)"
                          aria-label="Cancel">
                          <mat-icon class="small-icon">close</mat-icon> Cancel
                        </button>
                        <button
                          mat-button
                          *ngIf="row.isEditable"
                          (click)="removeItem('Farbe', row)"
                          aria-label="Delete">
                          <mat-icon class="small-icon">delete</mat-icon> Delete
                        </button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayFarben"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayFarben" (dblclick)="editItem('Farbe', row)"></tr>
                  </table>
                </div>
              </div>
            </div>
          </mat-tab>

          <!--
              Hersteller
          -->
          <mat-tab label="Hersteller">
            <div class="container-fluid p-1">
              <div class="row w-100 m-0">
                <div class="col-sm-12 col-md-12 p-0">
                  <h2>Hersteller</h2>
                </div>
                <div class="col-sm-12 col-md-12 p-1">
                  <p class="p-4">
                    <button mat-raised-button (click)="addNewHersteller()" color="primary">Neuanlage</button>
                  </p>
                  <table mat-table #tableHersteller [dataSource]="dataSourceHersteller" class="mat-elevation-z8">
                    <!-- id Column
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef>ID</th>
                      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>
                    -->

                    <!-- Hersteller Column -->
                    <ng-container matColumnDef="bezeichnung">
                      <th mat-header-cell *matHeaderCellDef>Hersteller</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.bezeichnung }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Hersteller" [(ngModel)]="row.bezeichnung" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                      <td mat-cell *matCellDef="let row">
                        <button
                          mat-button
                          *ngIf="!row.isEditable"
                          (click)="editItem('Hersteller', row)"
                          aria-label="Edit">
                          <mat-icon class="small-icon">edit</mat-icon> Edit
                        </button>
                        <button
                          mat-button
                          *ngIf="row.isEditable"
                          (click)="saveItem('Hersteller', row)"
                          aria-label="Save">
                          <mat-icon class="small-icon">save</mat-icon> Save
                        </button>
                        <button
                          mat-button
                          *ngIf="row.isEditable"
                          (click)="cancelEdit('Hersteller', row)"
                          aria-label="Cancel">
                          <mat-icon class="small-icon">close</mat-icon> Cancel
                        </button>
                        <button
                          mat-button
                          *ngIf="row.isEditable"
                          (click)="removeItem('Hersteller', row)"
                          aria-label="Delete">
                          <mat-icon class="small-icon">delete</mat-icon> Delete
                        </button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayHersteller"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayHersteller"
                      (dblclick)="editItem('Hersteller', row)"></tr>
                  </table>
                </div>
              </div>
            </div>
          </mat-tab>

          <!--
              Tatbestand
          -->
          <mat-tab label="Tatbest&auml;nde">
            <div class="container-fluid p-1">
              <div class="row w-100 m-0">
                <div class="col-sm-12 col-md-12 p-0">
                  <h2>Tatbest&auml;nde</h2>
                </div>
                <div class="col-sm-12 col-md-12 p-1">
                  <p class="p-4">
                    <button mat-raised-button (click)="addNewTatbestand()" color="primary">Neuanlage</button>
                  </p>
                  <table mat-table #tableTatbestand [dataSource]="dataSourceTatbestande" class="mat-elevation-z8">
                    <!-- id Column
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef>ID</th>
                      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>
                    -->

                    <!-- VSTV Code Column -->
                    <ng-container matColumnDef="vstv_code">
                      <th mat-header-cell *matHeaderCellDef>VSTV Code</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable" class="text-bold">
                          <span style="font-weight: bold">{{ row.vstv_code }}</span>
                        </div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="VSTV Code" [(ngModel)]="row.vstv_code" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- Paragraph Column -->
                    <ng-container matColumnDef="paragraph">
                      <th mat-header-cell *matHeaderCellDef>Paragraph</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.paragraph }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Paragraph" [(ngModel)]="row.paragraph" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- strafe Column -->
                    <ng-container matColumnDef="strafe">
                      <th mat-header-cell *matHeaderCellDef>Strafe</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.strafe }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Strafe" [(ngModel)]="row.strafe" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- short Column -->
                    <ng-container matColumnDef="short">
                      <th mat-header-cell *matHeaderCellDef>Kurzform</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.short }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="in Kurzform" [(ngModel)]="row.short" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- drucktext Column -->
                    <ng-container matColumnDef="drucktext">
                      <th mat-header-cell *matHeaderCellDef>Drucktext</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.drucktext }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Drucktext" [(ngModel)]="row.drucktext" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- reihung Column -->
                    <ng-container matColumnDef="reihung">
                      <th mat-header-cell *matHeaderCellDef>Reihung</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="!row.isEditable">{{ row.reihung }}</div>
                        <mat-form-field *ngIf="row.isEditable" class="input-fullwith">
                          <input matInput placeholder="Reihung" [(ngModel)]="row.reihung" />
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                      <td mat-cell *matCellDef="let row">
                        <div *ngIf="neuanlage">
                          <button
                            mat-button
                            *ngIf="row.isEditable"
                            (click)="saveItem('Tatbestand', row)"
                            aria-label="Save">
                            <mat-icon class="small-icon">save</mat-icon> Save
                          </button>
                          <button
                            mat-button
                            *ngIf="row.isEditable"
                            (click)="cancelEdit('Tatbestand', row)"
                            aria-label="Cancel">
                            <mat-icon class="small-icon">close</mat-icon> Cancel
                          </button>
                        </div>

                        <div *ngIf="!neuanlage">
                          <button mat-button (click)="removeItem('Tatbestand', row)" aria-label="Delete">
                            <mat-icon class="small-icon">delete</mat-icon> Delete
                          </button>
                          <button mat-button (click)="editItem('Tatbestand', row)" aria-label="Edit">
                            <mat-icon class="small-icon">edit</mat-icon> Edit
                          </button>
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayTatbestande"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayTatbestande"
                      (dblclick)="editItem('Tatbestand', row)"></tr>
                  </table>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
</div>
