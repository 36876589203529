<h2 mat-dialog-title class="dialog-title">Tatbestand bearbeiten</h2>
<mat-dialog-content class="mat-typography dialog-content">
  <!--
  <p>{{ tatbestand | json }}</p>

  <p>---------------------</p>
  <p>{{ frmTatbestand.value | json }}</p>
  -->

  <form [formGroup]="frmTatbestand">
    <!-- Reihung -->
    <div class="form-group">
      <label class="form-label" for="reihung"> Reihung</label>
      <input
        type="text"
        class="form-control"
        id="reihung"
        formControlName="reihung"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Reihung" />
    </div>

    <!-- VSTV-Code -->
    <div class="form-group">
      <label class="form-label" for="vstv_code"> VSTV-Code</label>
      <input
        type="text"
        class="form-control"
        id="vstv_code"
        formControlName="vstv_code"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="VSTV-Code" />
    </div>

    <!-- Paragraph -->
    <div class="form-group">
      <label class="form-label" for="paragraph"> Paragraph</label>
      <input
        type="text"
        class="form-control"
        id="paragraph"
        formControlName="paragraph"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Paragraph" />
    </div>

    <!-- Kurztext -->
    <div class="form-group">
      <label class="form-label" for="short"> Kurztext</label>
      <input
        type="text"
        class="form-control"
        id="short"
        formControlName="short"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Kurztext" />
    </div>

    <!-- Drucktext -->
    <div class="form-group">
      <label class="form-label" for="drucktext"> Drucktext</label>
      <textarea
        id="drucktext"
        formControlName="drucktext"
        rows="4"
        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Drucktext"></textarea>
    </div>

    <!-- Strafe -->
    <div class="form-group">
      <label class="form-label" for="strafe"> Strafe</label>
      <input
        type="text"
        class="form-control"
        id="strafe"
        formControlName="strafe"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Strafe" />
    </div>

    <!-- Lücken -->
    <div class="form-group">
      <label class="form-label" for="luecken"> Lücken</label>

      <table class="table table-responsive table-bordered table-sm w-100 text-sm">
        <thead>
          <tr>
            <th>Bezeichnung</th>
            <th>Info</th>
            <th>Standard-Wert</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody formArrayName="luecken">
          <tr *ngFor="let luecke of luecken.controls; let i = index" [formGroupName]="i">
            <td>
              <input
                type="text"
                class="form-control"
                id="titel-{{ id }}"
                #inputRef
                formControlName="titel"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Bezeichnung" />
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                id="info-{{ id }}"
                #inputRef
                formControlName="info"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Ausfüllhilfe zur Lücke" />
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                id="default-{{ id }}"
                #inputRef
                formControlName="default"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Stanrd-Wert" />
            </td>
            <td><button mat-flat-button (click)="removeLuecke(i)">Löschen</button></td>
          </tr>
        </tbody>
      </table>
      <button class="mb-3 mt-1" (click)="addNewLuecke()" mat-raised-button>Lücke hinzufügen</button> <br />
    </div>

    <!-- Freitext -->
    <div class="form-group">
      <label class="form-label" for="freitext"> Freitext</label>
      <textarea
        id="freitext"
        formControlName="freitext"
        rows="4"
        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Freitext"></textarea>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-button (click)="onConfirm()" cdkFocusInitial>Speichern</button>
</mat-dialog-actions>
