import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { IAppDataModel, IYearData, YearData } from '@app/@appstate/appdata.model';

import { AppDataQuery } from '@app/@appstate/appdata.query';
import { AppDataService } from '@app/@appstate/appdata.service';
import { AuthQuery } from '@app/auth/auth.query';
import { AuthDataService } from '@app/auth/auth-data.service';

import { Observable } from 'rxjs';

import { ConfirmationService } from 'primeng/api';

import { MatSnackBar } from '@angular/material/snack-bar';

import { JournalService } from '@app/@core';
import { SettingsService, Settings } from '@app/services/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  settings: Settings;

  frmVSettings: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _adQuery: AppDataQuery,
    private _adService: AppDataService,
    private authQuery: AuthQuery,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private authService: AuthDataService,
    private _snackBar: MatSnackBar,
    private journalService: JournalService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    // init the forms
    this.createForms();

    // get the stammdaten
    this.getStammdaten();
  }

  /**
   * get the stammdaten
   */
  getStammdaten() {
    console.log('do transfer');

    /* BUG BUG BUG -- we need some kind of security
    let request = {
      api_key: this._config.getConfig().api_key,
      api_baseurl: this._config.getConfig().api_baseurl,
      company_id: this._config.getConfig().company_id,
      person_id: this._config.getConfig().person_id,
    };
    */

    let requestData = {
      api_key: new Date().getTime(),
      //api_baseurl: this._config.getConfig().api_baseurl,
      //company_id: this._config.getConfig().company_id,
      //person_id: this._config.getConfig().person_id,
      do: 'getStammdaten',
    };

    this.settingsService.getSettings().subscribe(
      (response: Settings) => {
        //next() callback
        console.log('response received');
        console.log(response);

        this.settings = response;
        this.frmVSettings.setValue(this.settings);

        // set the date
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  private createForms() {
    // BUG BUG BUG: load the current data from ?
    //
    this.frmVSettings = this.formBuilder.group({
      EMPFANGER: ['', Validators.required],
      SENDER: ['', Validators.required],
      GEMEINDE: ['', Validators.required],
      TATORTART: ['', Validators.required],
      LANDESKENNZEICHEN: ['', Validators.required],
      WAHRNEHMUNG: ['', Validators.required],
      VSTV_PRE_NUMMER: ['', Validators.required],
      UNTERZEICHNER: ['', Validators.required],
      BEARBEITER: ['', Validators.required],
      TAGE_ZUR_ANZEIGE: [0, Validators.required],
      VOR_TEXTBAUSTEIN: [''],
      NACH_TEXTBAUSTEIN: [''],
    });
  }

  /**
   * sync the settings to the server
   */
  saveSettings() {
    console.log('save  Settings');

    var username = this.authQuery.name;
    var userid = this.authQuery.userid;
    var token = this.authQuery.access_token;

    let data = {
      username: username,
      uid: userid,
      token: token,
      data: this.frmVSettings.value,
    };

    this.settingsService
      .syncSettings(data)
      .pipe(
        finalize(() => {
          this.frmVSettings.markAsPristine();
        })
      )
      .subscribe(
        (data) => {
          //console.log(data);

          this._snackBar.open('Änderungen erfolgreich durchgeführt', 'Schließen', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
            panelClass: 'awesome-snackbar',
          });
        },
        (error) => {
          // show error notification alert
          this._snackBar.open('Fehler aufgetreten', 'Schließen', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      );
  }
}
