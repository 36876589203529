import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

//import { environment } from '@environments/environment';
//import { AuthenticationService } from '@app/_services';

import { AuthQuery } from '@app/auth/auth.query';
import { environment } from '@env/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authQuery: AuthQuery) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const isApiUrl = request.url.startsWith(environment.serverUrl);
    if (isApiUrl) {
      if (this.authQuery.access_token) {
        //console.log('JwtInterceptor');
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authQuery.access_token}`,
          },
        });
        //console.log('Token added to HTTP request');
      } else {
        //No token; proceed request without bearer token
        //console.log('No token added to HTTP request');
      }
    }

    return next.handle(request);
  }
}
