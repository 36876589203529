<div>
  <h1 class="page-title">Liste Mandate</h1>
</div>

<div
  class="offcanvas offcanvas-end"
  data-bs-backdrop="default"
  tabindex="-1"
  id="staticBackdrop"
  aria-labelledby="staticBackdropLabel">
  <div class="offcanvas-header">
    <h1 class="offcanvas-title" id="staticBackdropLabel">Mandat: #{{ current_mandat?.Md_BelegNr }}</h1>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
          clip-rule="evenodd" />
      </svg>
    </button>
  </div>
  <div class="offcanvas-body">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="container-fluid" style="margin-top: 30px">
  <!-- / .row -->
  <div class="row">
    <div class="col-12">
      <div class="mx-4">
        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white">
          <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
        </div>

        <mat-form-field appearance="standard">
          <mat-label>Suche</mat-label>
          <input matInput (keyup)="applyFilter($event)" [(ngModel)]="searchValue" placeholder="" #input />
          <button
            mat-button
            *ngIf="searchValue"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchValue = ''; applyFilter($event)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        &nbsp; &nbsp;

        <mat-form-field class="flex-none" appearance="standard" *ngFor="let empfilter of empFilters">
          <mat-label>{{ empfilter.name }}</mat-label>
          <mat-select [(value)]="empfilter.defaultValue" (selectionChange)="applyEmpFilter($event, empfilter)">
            <mat-option *ngFor="let op of empfilter.options" [value]="op">
              {{ op }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        &nbsp; &nbsp;

        <button
          type="button"
          mat-raised-button
          matRipple
          (click)="checkOverdue()"
          [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
          class="mb-3 flex-none bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
          [style]="{ 'margin-left': '.5em', 'max-height': '39px' }">
          Auf &Uuml;berfällig setzen
        </button>
        <div class="flex gap-4"></div>
      </div>

      <div class="example-container">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z0"
          matSort
          matSortActive="Md_PrimKey"
          matSortDisableClear
          matSortDirection="desc"
          matTableExporter
          #exporter="matTableExporter">
          <!----------------------------------------------------------------- -->

          <!-- ID Column -->
          <ng-container matColumnDef="Md_PrimKey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let row">{{ row.Md_PrimKey }}</td>
          </ng-container>

          <!-- Md_Status Column -->
          <ng-container matColumnDef="Md_Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Status</th>
            <td mat-cell *matCellDef="let row">
              <div
                *ngIf="row.Md_Status == 'A'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-approved word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                ANGEZEIGT
              </div>

              <div
                *ngIf="row.Md_Status == 'T'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-gray [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                TEIL-ERFASST
              </div>

              <div
                *ngIf="row.Md_Status == 'E'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-darkgray [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                ERFASST
              </div>

              <div
                *ngIf="row.Md_Status == 'U'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-overdue [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                ÜBERFÄLLIG
              </div>

              <div
                *ngIf="row.Md_Status == 'B'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-active-fsdone [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                BEZAHLT
              </div>

              <div
                *ngIf="row.Md_Status == 'AB'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-warn [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                ANG/BEZ
              </div>

              <div
                *ngIf="row.Md_Status == 'S'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-lightgray [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                STORNO
              </div>

              <div
                *ngIf="row.Md_Status == '8'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-warn [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                AUSGENOMMEN
              </div>

              <div
                *ngIf="row.Md_Status == '9'"
                data-te-chip-init
                data-te-ripple-init
                class="chip-error [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                data-te-close="true">
                ERROR
              </div>
            </td>
          </ng-container>

          <!-- Profilbild Column
          <ng-container matColumnDef="profile_image">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-50">&nbsp;</th>
            <td mat-cell *matCellDef="let row">
              <phuture-iavatar src_image="{{ imageStorage }}{{ row.profile_image }}"></phuture-iavatar>
            </td>
          </ng-container>
          -->

          <!-- Md_BelegNr Column -->
          <ng-container matColumnDef="Md_BelegNr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-80">Aktenzeichen</th>
            <td mat-cell *matCellDef="let row" class="font-semibold">&nbsp;{{ row.Md_BelegNr }}&nbsp;</td>
          </ng-container>

          <!----------------------------------------------------------------- -->

          <!-- Tatdatum Column | date -->
          <ng-container matColumnDef="Md_Datum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.Md_Datum }}&nbsp;</td>
          </ng-container>

          <!-- Tatzeit Column | date : 'shortTime' -->
          <ng-container matColumnDef="Md_ZeitVon">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Zeit</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.Md_ZeitVon }}&nbsp;</td>
          </ng-container>

          <!-- Md_KzLand Column -->
          <ng-container matColumnDef="Md_KzLand">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
            <td mat-cell *matCellDef="let row">&nbsp;{{ row.Md_KzLand }}&nbsp;</td>
          </ng-container>

          <!-- Md_KzBezirk Column
          <ng-container matColumnDef="Md_KzBezirk">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Bezirk</th>
            <td mat-cell *matCellDef="let row">{{ row.Md_KzBezirk }}</td>
          </ng-container>
          -->

          <!-- Md_KzNummer Column -->
          <ng-container matColumnDef="Md_KzNummer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kennzeichen</th>
            <td mat-cell *matCellDef="let row">{{ row.Md_KzNummer }}</td>
          </ng-container>

          <!-- Md_Hersteller Column -->
          <ng-container matColumnDef="Md_Hersteller">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hersteller</th>
            <td mat-cell *matCellDef="let row">{{ row.Md_Hersteller }}</td>
          </ng-container>

          <!-- Md_Betrag Column -->
          <ng-container matColumnDef="Md_Betrag">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Betrag</th>
            <td mat-cell *matCellDef="let row">{{ row.Md_Betrag }}</td>
          </ng-container>

          <!-- Md_DienstNr Column -->
          <ng-container matColumnDef="Md_DienstNr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Dienst-Nr</th>
            <td mat-cell *matCellDef="let row">{{ row.Md_DienstNr }}</td>
          </ng-container>

          <!----------------------------------------------------------------- -->

          <!-- actions button bar Column -->

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
            <td mat-cell *matCellDef="let row">
              <button
                type="button"
                matRipple
                [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
                class="bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
                [style]="{ 'margin-left': '.5em' }"
                (click)="showPageDetail(row.Md_PrimKey)"
                [queryParams]="{ debug: true }"
                fragment="education">
                Detail
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef="let row">&nbsp;</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let emprow; columns: displayedColumns"
            (click)="showPageDetail(emprow.Md_PrimKey)"></tr>
        </table>

        <mat-paginator
          #paginator
          [length]="totalItems"
          [pageSizeOptions]="pageSizes"
          showFirstLastButtons></mat-paginator>
      </div>
      <p>&nbsp;</p>

      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </div>
</div>
