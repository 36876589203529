<div>
  <div class="authGridContainer authGridContainer--login authGridContainer--fullScreen">
    <a class="authGrid__logo" href="/" aria-label="Link to main page" tabindex="0">
      <img src="./assets/parkraumplus-logo.png" alt="ParkraumPlus" />
    </a>
    <div class="authGridColumn authGridColumn--callToActionParent">
      <div class="createFreeAccount">&nbsp;</div>
    </div>
    <div class="authGridColumn authGridColumn--formParent">
      <div class="formContainer loginForm">
        <h2>Kennwort zurücksetzen</h2>

        <form (ngSubmit)="resetpwd()" [formGroup]="loginForm" novalidate>
          <div class="login-error" [hidden]="!error || isLoading">Benutzername oder Kennwort sind falsch.</div>
          <br />

          <div *ngIf="loginlink == 0" class="login-fields" fxLayout="column">
            <mat-form-field [hideRequiredMarker]="true">
              <input type="password" matInput formControlName="password" [placeholder]="'Kennwort'" required />
              <mat-error *ngIf="loginForm.controls['pwdconfirm'].invalid && loginForm.controls['pwdconfirm'].touched">
                <span>Kennwort muss angegeben werden</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field [hideRequiredMarker]="true">
              <input type="password" matInput formControlName="pwdconfirm" [placeholder]="'Kennwort bestätigen'" required />
              <mat-error *ngIf="loginForm.controls['pwdconfirm'].invalid && loginForm.controls['pwdconfirm'].touched">
                <span>Kennwort muss angegeben werden</span>
              </mat-error>
            </mat-form-field>
            <br />
            <button
              class="btnLogin"
              mat-focus-indicator
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="loginForm.invalid || isLoading">
              <span>Kennwort ändern</span>
            </button>
          </div>
          <p *ngIf="loginlink == 1"><a href="/login">[Zur Anmeldung]</a></p>
        </form>
      </div>
    </div>
  </div>
</div>
