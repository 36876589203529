import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpParamsOptions, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { map, tap } from 'rxjs/operators';

import { SortDirection } from '@angular/material/sort';

import { AuthQuery } from '@app/auth/auth.query';

/*
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};
*/

// lcmario BUG BUG BUG
/*
export enum StatusMandat {
  DRAFT = '0',
  TRANSFER = '1',
  UNGULTIG = '3',
}

export interface Mandat {
  Md_PrimKey: number;
  Md_Status: string;
  Md_StatusDate: string;
  Md_StatusTime: string;
  Md_Kennzeichen: string;
  Md_BelegNr: string;
  Md_ZahlungsDatum: string;
  Md_Betrag: number;
  Md_DienstNr: number;
  Md_KzLand: string;
  Md_KzTyp: string;
  Md_KzBezirk: string;
  Md_KzNummer: string;
  Md_Hersteller: string;
  Md_Type: string;
  Md_Farbe: string;
  Md_GebietsCode: number;
  Md_Strasse: string;
  Md_HausNr: number;
  Md_HausNrZusatz: string;
  Md_Datum: string;
  Md_ZeitVon: string;
  Md_ZeitBis: string;
  Md_TatbestNr: number;
  Md_Konkretisierung: string;
  createdate: string;
  createtime: string;
  person: string;
  printed: number;
  printdate: string;
  printcount: number;
  transferred: number;
  transferdate: string;
  longitude: number;
  lattitude: number;
  info1: string;
  info2: string;
  info3: string;
  fahrzeugart: number;
  zone: number;
  ticketnr: number;
  ticket_zeit: string;
  notes: string;
  interne_notes: string;
  vstv_export: number;
  vstv_packet: number;
  vstv_ignore: number;
  zusatz1: string;
  zusatz2: string;
  strafe: number;
  dienstnr_stvo: number;
}

export interface MandatSingle {
  data: Mandat;
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

export interface MandatTable {
  data: Mandat[];
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}
*/

export interface VSTVPaket {
  id: number;
  bezeichnung: string;
  created: string;
}
export interface VSTVArchivTable {
  data: VSTVPaket[];
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
  }),
};

//
// --------------------------------------------------------------
//

@Injectable({
  providedIn: 'root',
})
export class VSTVService {
  errorMsg: string = '';

  constructor(private httpClient: HttpClient, private authQuery: AuthQuery) {}

  /**
   * GET ALL
   *
   * @return response()
   */
  /**
   * GET ALL
   *
   * @return response()
   */

  public getVSTVArchivPakete(): Observable<VSTVArchivTable> {
    const apiUrl = `${environment.serverUrl}/vstv-archive`;
    // '?page=${pageNumber}&per_page=${pageSize}&sort=${sort}&sorder=${order}`

    return this.httpClient.get<VSTVArchivTable>(apiUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
      }),
      params: new HttpParams().set('webapp', 1),
    });
  }

  /**
   * clone the TRANSFER into the MANDATE >>> damit wird es zu einem AKTIVEN Strafmandat (Status-erfasst)
   *          oder auch für die polizeit TEILERFASST !!!!!!! BUG BUG BUG
   *
   * @return response()
   */
  doPaketAusnehmen(mandat_id: number): Observable<any> {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: this.authQuery.getValue().phone_id,
      token: this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: this.authQuery.getValue().dienstnr,
      didstvo: this.authQuery.getValue().dienstnr_stvo,
      didname: this.authQuery.getValue().dienstname,
    };

    // get the data for the item to save
    const data = {
      ...requestAuth,
      mid: mandat_id,
    };

    return this.httpClient.post<any>(environment.serverUrl + '/vstv-exclude', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.mandat,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getServerErrorMessage(error: HttpErrorResponse) {
    console.log('custom service error handler');

    switch (error.status) {
      case 0: {
        // A client-side or network error occurred. Handle it accordingly.
        //console.error('An error occurred:', error.error);
        return `An error occurred: ${error.error}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.error}`;
      }

      // Return an observable with a user-facing error message.
      //return throwError(() => new Error('Something bad happened; please try again later.'));
    }
  }
}
