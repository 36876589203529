import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timer, throwError } from 'rxjs';
import { mapTo, tap, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthStore, ReturnAuthState } from './auth.store';

import { environment } from '@env/environment';

export interface Backdoor {
  uid: string;
  token: string;
  tstamp: string;
}

export interface Credentials {
  email: string;
  password: string;
}
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  constructor(private http: HttpClient) {}

  public login(creds: Credentials): Observable<ReturnAuthState> {
    let input = new FormData();
    // Add your values in here
    input.append('email', creds.email);
    input.append('password', creds.password);

    // rest api call
    return this.http.post<any>(`${environment.serverUrl}/login`, JSON.stringify(creds), HttpUploadOptions).pipe(
      map((data) => {
        //console.log(data);
        return data;
      })
    );
  }

  public firststart(inputdata: Backdoor): Observable<ReturnAuthState> {
    let input = new FormData();
    // Add your values in here
    //input.append('uid', inputdata.uid);
    input.append('token', inputdata.token);
    //input.append('tstamp', inputdata.tstamp);

    // rest api call
    return this.http.post<any>(`${environment.serverUrl}/firststart`, JSON.stringify(inputdata), HttpUploadOptions).pipe(
      map((data) => {
        //console.log(data);
        return data;
      })
    );
  }

  logout() {
    return timer(300).pipe(mapTo({}));
  }

  register(data: any) {
    // rest api call
    return this.http.post<any>(`${environment.serverUrl}/register`, JSON.stringify(data), HttpUploadOptions).pipe(
      map((data) => {
        //console.log(data);
        return data;
      })
    );
  }

  changePassword(data: any) {
    // rest api call
    return this.http.post<any>(`${environment.serverUrl}/change-pwd`, JSON.stringify(data), HttpUploadOptions).pipe(
      map((data) => {
        //console.log(data);
        return data;
      })
    );
  }

  forgotPassword(data: any) {
    // rest api call
    return this.http.post<any>(`${environment.serverUrl}/forgot-pwd`, JSON.stringify(data), HttpUploadOptions).pipe(
      map((data) => {
        //console.log(data);
        return data;
      })
    );
  }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return throwError(() => error);
  }
}
