<div class="sm:py-8">
  <div class="mx-auto max-w-7xl px-2 lg:px-2">
    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">ParkraumPlus - die effiziente Lösung</h1>
      <div class="mt-10 grid max-w-xl grid-cols-2 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-4">
        <div>
          <p>
            <img src="../../assets/btn-transfer.png" />
          </p>
          <a
            [href]="['/transfer']"
            class="bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >Transfer</a
          >
        </div>
        <div>
          <p>
            <img src="../../assets/btn-mandate.png" />
          </p>
          <a
            [href]="['/mandate']"
            class="bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >Mandate</a
          >
        </div>
        <div>
          <p>
            <img src="../../assets/btn-zahlung.png" />
          </p>
          <a
            [href]="['/zahlungsabgleich']"
            class="bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >Zahlungsabgleich</a
          >
        </div>

        <div>
          <p>
            <img src="../../assets/btn-vstv.png" />
          </p>
          <a
            [href]="['/vstv']"
            class="bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >VSTV Export</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
