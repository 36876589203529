import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataChangeMandateService {
  private dataSource = new BehaviorSubject<any>(null);
  dataChanges$ = this.dataSource.asObservable();

  updateData(data: any) {
    this.dataSource.next(data);
  }
}
