import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpParamsOptions, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { map, tap } from 'rxjs/operators';

import { SortDirection } from '@angular/material/sort';

/*
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};
*/

/**
 * DATA STRUCTURES
 */

export interface Settings {
  EMPFANGER: string;
  SENDER: string;
  GEMEINDE: string;
  TATORTART: string;
  LANDESKENNZEICHEN: string;
  WAHRNEHMUNG: string;
  VSTV_PRE_NUMMER: string;
  UNTERZEICHNER: string;
  BEARBEITER: string;
  TAGE_ZUR_ANZEIGE: number;
}

interface ApiResponse {
  data: Settings;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
  }),
};

//
// --------------------------------------------------------------
//

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  errorMsg: string = '';

  constructor(private httpClient: HttpClient) {}

  /**
   * GET / FIND BY ID
   *
   * @return response()
   */
  getSettings(): Observable<Settings> {
    const apiUrl = `${environment.serverUrl}/settings`;

    return this.httpClient
      .get<ApiResponse>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
        }),
        //params: new HttpParams().set('id', '' + id),
      })
      .pipe(map((response) => response.data));
  }

  /**
   * SYNC SETTINGS
   *
   * @return response()
   */

  public syncSettings(postData: any): Observable<Settings> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.httpClient.post<any>(environment.serverUrl + '/settings', JSON.stringify(postData), httpOptions).pipe(
      map((response) => {
        return {
          ...response.data,
        };
      }),
      tap((response: any) => {
        console.log(response);
        //this.repo.addQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );

    /*
    .pipe(
      map((response: VisitorTable) => {
        //debugger;
        return response.data.map((item: Visitor) => ({
          ...item,
        }));
      }),
      tap((response: any) => {
        console.log('mrio');
        console.log(response);
        return <Visitor[]>response;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
    */
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getServerErrorMessage(error: HttpErrorResponse) {
    console.log('custom service error handler');

    switch (error.status) {
      case 0: {
        // A client-side or network error occurred. Handle it accordingly.
        //console.error('An error occurred:', error.error);
        return `An error occurred: ${error.error}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.error}`;
      }

      // Return an observable with a user-facing error message.
      //return throwError(() => new Error('Something bad happened; please try again later.'));
    }
  }
}
