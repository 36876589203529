import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


//import { CoreModule } from '@core';
import { SharedModule } from '@shared';

import { MaterialModule } from '@app/material.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SupportComponent } from './support.component';

@NgModule({
  declarations: [SupportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    TranslateModule,
  ],
})
export class SupportModule {}
