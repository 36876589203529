import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
//import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { FilePondModule, registerPlugin } from 'ngx-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageTransform);
registerPlugin(FilePondPluginImageResize);
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { RedZoomModule } from 'ngx-red-zoom';

import { SharedModule } from '@app/@shared';
import { TranslateLandPipe } from '@app/@shared/pipe/translate-land.pipe';

import { TransferEditComponent } from './transfer_edit.component';

import { DatePipe } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatRippleModule,
    //MatStepperModule,
    MatCardModule,
    MatTooltipModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatRadioModule,
    FilePondModule,

    NgbPopoverModule,
    RedZoomModule,

    SharedModule,
  ],
  exports: [TransferEditComponent],
  declarations: [TransferEditComponent],
  providers: [DatePipe],
})
export class TransferEditModule implements DoBootstrap {
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const el = createCustomElement(TransferEditModule, {
      injector: this.injector,
    });

    customElements.define('transfer_edit', el);
  }
}
