import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateHersteller',
})
export class TranslateHerstellerPipe implements PipeTransform {
  // mapping-tabelle für HERSTELLER
  private herstellerMap = {
    volkswagen: 'VW',
    'mercedes-benz': 'Mercedes',
    unknown: 'Audi',
  };

  transform(value: string): string {
    // Check if the value exists in the map; if not, return 'unknown'
    return this.herstellerMap[value.toLowerCase()] || value;
  }
}
