import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';

import { MaterialModule } from '@app/material.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SettingsComponent } from './settings.component';

import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,

    TranslateModule,
    //CoreModule,
    //SharedModule,

    ConfirmDialogModule,
    ButtonModule,
    MessagesModule,
  ],
  providers: [ConfirmationService],
  declarations: [SettingsComponent],
})
export class SettingsModule {}
