import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpParamsOptions, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { map, tap } from 'rxjs/operators';

import { SortDirection } from '@angular/material/sort';

import { AuthQuery } from '@app/auth/auth.query';

/*
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};
*/

// lcmario BUG BUG BUG
export enum StatusMandat {
  ERFASST = 'E',
  TEILERFASST = 'T',
  BEZAHLT = 'B',
  UBERFALLIG = 'U',
  ANGEZEIGT = 'A',
  ANZEIGEBEZAHLT = 'AB',
  STORNO = 'S',
}

export interface Mandat {
  Md_PrimKey: number;
  Md_Status: string;
  Md_StatusDate: string;
  Md_StatusTime: string;
  Md_Kennzeichen: string;
  Md_BelegNr: string;
  Md_ZahlungsDatum: string;
  Md_Betrag: number;
  Md_DienstNr: number;
  Md_KzLand: string;
  Md_KzTyp: string;
  Md_KzBezirk: string;
  Md_KzNummer: string;
  Md_Hersteller: string;
  Md_Type: string;
  Md_Farbe: string;
  Md_GebietsCode: number;
  Md_Strasse: string;
  Md_HausNr: number;
  Md_HausNrZusatz: string;
  Md_Datum: string;
  Md_ZeitVon: string;
  Md_ZeitBis: string;
  Md_TatbestNr: number;
  Md_Konkretisierung: string;
  createdate: string;
  createtime: string;
  person: string;
  printed: number;
  printdate: string;
  printcount: number;
  transferred: number;
  transferdate: string;
  longitude: number;
  lattitude: number;
  info1: string;
  info2: string;
  info3: string;
  fahrzeugart: number;
  zone: number;
  ticketnr: number;
  ticket_zeit: string;
  notes: string;
  interne_notes: string;
  vstv_export: number;
  vstv_packet: number;
  vstv_ignore: number;
  zusatz1: string;
  zusatz2: string;
  strafe: number;
  dienstnr_stvo: number;
}

export interface MandatSingle {
  data: Mandat;
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

export interface MandatTable {
  data: Mandat[];
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
  }),
};

//
// --------------------------------------------------------------
//

@Injectable({
  providedIn: 'root',
})
export class MandatService {
  errorMsg: string = '';

  constructor(private httpClient: HttpClient, private authQuery: AuthQuery) {}

  /**
   * GET ALL
   *
   * @return response()
   */

  public getMandate(
    pageNumber: Number,
    pageSize: Number,
    sort: string,
    order: SortDirection,
    searchTerm: string,
    filtersArgs: string
  ): Observable<MandatTable> {
    const apiUrl = `${environment.serverUrl}/mandate`;
    // '?page=${pageNumber}&per_page=${pageSize}&sort=${sort}&sorder=${order}`

    return this.httpClient.get<MandatTable>(apiUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
      }),
      params: new HttpParams()
        .set('page', '' + pageNumber)
        .set('per_page', '' + pageSize)
        .set('sort', sort)
        .set('sorder', order)
        .set('sterm', searchTerm)
        .set('filters', filtersArgs)
        .set('webapp', 1),
    });

    /*
    .pipe(
      map((response: VisitorTable) => {
        //debugger;
        return response.data.map((item: Visitor) => ({
          ...item,
        }));
      }),
      tap((response: any) => {
        console.log('mrio');
        console.log(response);
        return <Visitor[]>response;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
    */
  }

  /**
   * GET / FIND BY ID
   *
   * @return response()
   */
  getMandatById(id: number): Observable<MandatSingle> {
    const apiUrl = `${environment.serverUrl}/mandat/` + id;

    return this.httpClient
      .get<MandatSingle>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
        }),
        params: new HttpParams().set('id', '' + id),
      })
      .pipe(
        map((response: MandatSingle) => {
          return {
            ...response,
          };
        })
      );

    /*
    return this.httpClient.get<Visitor[]>(environment.serverUrl + '/visitor/' + id, httpOptions).pipe(
      tap((resp) => {
        //this.repo.setQuizes( resp || []);
        return resp;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
    */
  }

  /**
   * GET / FIND BY FILTER
   *
   * @return response()

  getVisitorByParams(data: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    //const data: any = { this: 'thisThing', that: 'thatThing', other: 'otherThing'};

    const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;

    const options = { params: new HttpParams(httpParams), headers: headers };

    return this.httpClient.get<Mandat[]>(environment.serverUrl + '/mandat/', options).pipe(
      tap((resp) => {
        //this.repo.setQuizes( resp || []);
        return resp;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }
*/

  /**
   * clone the TRANSFER into the MANDATE >>> damit wird es zu einem AKTIVEN Strafmandat (Status-erfasst)
   *          oder auch für die polizeit TEILERFASST !!!!!!! BUG BUG BUG
   *
   * @return response()
   */
  doMandate2VSTV(): Observable<any> {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: this.authQuery.getValue().phone_id,
      token: this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: this.authQuery.getValue().dienstnr,
      didstvo: this.authQuery.getValue().dienstnr_stvo,
      didname: this.authQuery.getValue().dienstname,
    };

    // get the data for the item to save
    const data = {
      ...requestAuth,
    };

    return this.httpClient.post<any>(environment.serverUrl + '/mandat2vstv', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.mandat,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * clone the TRANSFER into the MANDATE >>> damit wird es zu einem AKTIVEN Strafmandat (Status-erfasst)
   *          oder auch für die polizeit TEILERFASST !!!!!!! BUG BUG BUG
   *
   * @return response()
   */
  doExportVSTV(bearbeiter: string, paket_id: number) {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: this.authQuery.getValue().phone_id,
      token: this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: this.authQuery.getValue().dienstnr,
      didstvo: this.authQuery.getValue().dienstnr_stvo,
      didname: this.authQuery.getValue().dienstname,
    };

    // get the data for the item to save
    const data = {
      ...requestAuth,
      bearbeiter: bearbeiter,
      pakid: paket_id,
    };

    return this.httpClient.post<any>(environment.serverUrl + '/vstv-export', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.data,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * get the vstv file of the given paket id
   *
   * @return response()
   */
  downloadVSTVFile(paket_id: number) {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: this.authQuery.getValue().phone_id,
      token: this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: this.authQuery.getValue().dienstnr,
      didstvo: this.authQuery.getValue().dienstnr_stvo,
      didname: this.authQuery.getValue().dienstname,
    };

    // get the data for the item to save
    const data = {
      ...requestAuth,
      pakid: paket_id,
    };

    return this.httpClient
      .post(environment.serverUrl + '/vstv-package', JSON.stringify(data), {
        ...httpOptions,
        responseType: 'blob',
      })
      .pipe(
        tap((blob) => {
          console.log('Response received as blob');
          // Assuming you want to download or otherwise handle the blob here
          // For example, to download the blob as a file:
          //this.downloadBlob(blob, 'downloaded_file_name.extension');
          return blob;
        }),
        catchError((error) => {
          let errorMsg: string = '';
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }

          return throwError(errorMsg);
        })
      );
  }

  /**
   * CREATE
   *
   * @return response()

  createVisitor(post: any): Observable<any> {
    return this.httpClient.post<any>(environment.serverUrl + '/mandat/', JSON.stringify(post), httpOptions).pipe(
      map((response) => {
        return {
          ...response.data,
          id: response.data.quuid,
          quizdata: '',
        };
      }),
      tap((response: any) => {
        console.log(response);
        //this.repo.addQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }
*/

  /**
   * CHECK
   *
   * @return response()
   */
  checkMandat(mandat: any): Observable<any> {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: '' + this.authQuery.getValue().phone_id,
      token: '' + this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: '' + this.authQuery.getValue().dienstnr,
      didstvo: '' + this.authQuery.getValue().dienstnr_stvo,
      didname: '' + this.authQuery.getValue().dienstname,
    };

    // get the data for the item to save
    var id = mandat.id;
    var mandat_json = JSON.stringify(mandat);
    const data = {
      ...requestAuth,
      id: id,
      aktenzeichen: mandat.aktenzeichen,
    };
    console.log(data);

    return this.httpClient.post<any>(environment.serverUrl + '/mandatcheck', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          positiv: response.result,
          ...response,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * UPDATE
   *
   * @return response()
   */
  updateMandat(mandat: any): Observable<any> {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: this.authQuery.getValue().phone_id,
      token: this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: this.authQuery.getValue().dienstnr,
      didstvo: this.authQuery.getValue().dienstnr_stvo,
      didname: this.authQuery.getValue().dienstname,
    };

    // get the data for the item to save
    var id = mandat.id;
    var mandat_json = JSON.stringify(mandat);
    const data = {
      ...requestAuth,
      id: id,
      aktenzeichen: mandat.aktenzeichen,
      mandat: mandat_json,
      debug: mandat,
    };

    return this.httpClient.post<any>(environment.serverUrl + '/mandat-update', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.mandat,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * UPDATE
   *
   * @return response()
   *
   *    !!! the given mandat is not from format MANDAT >> the form has different values and keys
   */
  changeStatus(mandat: any, newStatusID: string): Observable<any> {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: this.authQuery.getValue().phone_id,
      token: this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: this.authQuery.getValue().dienstnr,
      didstvo: this.authQuery.getValue().dienstnr_stvo,
      didname: this.authQuery.getValue().dienstname,
    };

    // get the data for the item
    var id = mandat.id;
    //var transfer_json = JSON.stringify(mandat);
    const data = {
      ...requestAuth,
      id: id,
      aktenzeichen: mandat.aktenzeichen,
      ostatusid: mandat.status_id,
      nstatusid: newStatusID,
    };

    console.log(data);

    return this.httpClient.post<any>(environment.serverUrl + '/mandat-cstatus', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.mandat,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * DELETE
   *
   * @return response()

  deleteVisitor(id: string) {
    return this.httpClient.delete(environment.serverUrl + '/visitor/' + id, httpOptions).pipe(
      tap((value) => {
        //this.repo.removeQuiz(id);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }
  */

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getServerErrorMessage(error: HttpErrorResponse) {
    console.log('custom service error handler');

    switch (error.status) {
      case 0: {
        // A client-side or network error occurred. Handle it accordingly.
        //console.error('An error occurred:', error.error);
        return `An error occurred: ${error.error}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.error}`;
      }

      // Return an observable with a user-facing error message.
      //return throwError(() => new Error('Something bad happened; please try again later.'));
    }
  }
}
