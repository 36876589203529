import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { MaterialModule } from '@app/material.module';
import { AuthRoutingModule } from './auth-routing.module';
import { I18nModule } from '@app/i18n';

import { LoginPageComponent } from './login-page/login-page.component';
import { ForgotPwdPageComponent } from './forgot-pwd-page/forgot-pwd-page.component';
import { RegisterNewPageComponent } from './register-new-page/register-new-page.component';
import { ResetPwdComponent } from './reset-pwd/reset-pwd.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    //SharedModule,
    MaterialModule,
    I18nModule,
    AuthRoutingModule,
  ],
  declarations: [LoginPageComponent, ForgotPwdPageComponent, RegisterNewPageComponent, ResetPwdComponent],
})
export class AuthModule {}
