<div>
  <div class="authGridContainer authGridContainer--login authGridContainer--fullScreen">
    <a class="authGrid__logo" href="/" aria-label="Link to main page" tabindex="0">
      <img src="./assets/parkraumplus-logo.png" alt="ParkraumPlus" />
    </a>
    <div class="authGridColumn authGridColumn--callToActionParent">
      <div class="createFreeAccount">
        <span class="createFreeAccount__text" style="font-size: 1.6em; line-height: 30px">Die effiziente Parkraumverwaltung.</span>
      </div>
    </div>
    <div class="authGridColumn authGridColumn--formParent">
      <div class="formContainer loginForm">
        <h2>Neuanlage</h2>

        <form (ngSubmit)="registerNew()" [formGroup]="registerForm" novalidate>
          <br />
          <div class="login-fields" fxLayout="column">
            <mat-form-field [hideRequiredMarker]="true">
              <input type="text" matInput formControlName="username" autocomplete="username" [placeholder]="'Benutzername'" required />
              <mat-error *ngIf="registerForm.controls.username.invalid && registerForm.controls.username.touched">
                <span>Benutzername muss angegeben werden</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field [hideRequiredMarker]="true">
              <input
                type="password"
                matInput
                formControlName="password"
                autocomplete="current-password"
                [placeholder]="'Kennwort'"
                required />
              <mat-error *ngIf="registerForm.controls.password.invalid && registerForm.controls.password.touched">
                <span>Kennwort muss angegeben werden</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field [hideRequiredMarker]="true">
              <input
                type="password"
                matInput
                formControlName="pwdconfirm"
                autocomplete="password-confirm"
                [placeholder]="'Kennwort best&auml;tigen'"
                required />
              <mat-error *ngIf="registerForm.controls.pwdconfirm.invalid && registerForm.controls.pwdconfirm.touched">
                <span>Kennworter m&uuml;ssen übereinstimmen</span>
              </mat-error>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true">
              <input type="text" matInput formControlName="firstname" autocomplete="firstname" [placeholder]="'Vorname'" required />
              <mat-error *ngIf="registerForm.controls.firstname.invalid && registerForm.controls.firstname.touched">
                <span>Vorname muss angegeben werden</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field [hideRequiredMarker]="true">
              <input type="text" matInput formControlName="lastname" autocomplete="lastname" [placeholder]="'Nachname'" required />
              <mat-error *ngIf="registerForm.controls.lastname.invalid && registerForm.controls.lastname.touched">
                <span>Nachname muss angegeben werden</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input type="text" matInput formControlName="knr" autocomplete="knr" [placeholder]="'Kunden Nr.'" />
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true">
              <input type="text" matInput formControlName="company" autocomplete="company" [placeholder]="'Firma'" required />
              <mat-error *ngIf="registerForm.controls.company.invalid && registerForm.controls.company.touched">
                <span>Firma muss angegeben werden</span>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input type="text" matInput formControlName="street" autocomplete="street" [placeholder]="'Straße'" />
            </mat-form-field>
            <mat-form-field>
              <input type="text" matInput formControlName="zip" autocomplete="zip" [placeholder]="'Plz'" />
            </mat-form-field>
            <mat-form-field>
              <input type="text" matInput formControlName="city" autocomplete="city" [placeholder]="'Stadt'" />
            </mat-form-field>
            <mat-form-field>
              <input type="text" matInput formControlName="country" autocomplete="country" [placeholder]="'Land'" />
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true">
              <input type="text" matInput formControlName="email" autocomplete="email" [placeholder]="'E-Mail'" required />
              <mat-error *ngIf="registerForm.controls.email.invalid && registerForm.controls['email'].touched">
                <span>E-Mail muss angegeben werden</span>
              </mat-error>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true">
              <input type="text" matInput formControlName="phone" autocomplete="phone" [placeholder]="'Telefon'" required />
              <mat-error *ngIf="registerForm.controls.phone.invalid && registerForm.controls['phone'].touched">
                <span>Telefon muss angegeben werden</span>
              </mat-error>
            </mat-form-field>

            <p>Branche:</p>
            <select formControlName="branche" name="branche">
              <option value="Bäckereien">Bäckereien</option>
              <option value="Elektrotechnik">Elektrotechnik</option>
              <option value="Fenster & Fassaden">Fenster & Fassaden</option>
              <option value="Fliesenleger">Fliesenleger</option>
              <option value="Garten- und Landschaftsbau">Garten- und Landschaftsbau</option>
              <option value="Holzbe- und -verarbeitung">Holzbe- und -verarbeitung</option>
              <option value="Holzhandel">Holzhandel</option>
              <option value="Holzhäuser">Holzhäuser</option>
              <option value="Kältetechnik">Kältetechnik</option>
              <option value="Kamin- & Kachelofenbau">Kamin- & Kachelofenbau</option>
              <option value="Malerbetriebe & Lackierer">Malerbetriebe & Lackierer</option>
              <option value="Metallbe - und -verarbeitung">Metallbe - und -verarbeitung</option>
              <option value="Sanitär">Sanitär</option>
              <option value="Schlossereien & Schweißereien">Schlossereien & Schweißereien</option>
              <option value="Schreinereien">Schreinereien</option>
              <option value="Zimmereien">Zimmereien</option>
              <option value="Sonstige">Sonstige</option>
            </select>

            <mat-form-field>
              <input type="text" matInput formControlName="uid" autocomplete="uid" [placeholder]="'UID-Nummer'" />
            </mat-form-field>

            <br />
            <button
              class="btnLogin"
              mat-focus-indicator
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="registerForm.invalid || isLoading">
              <span (click)="registerNew()">Neuanlage</span>
            </button>
          </div>
        </form>

        <br />
        <br />
        <button mat-flat-button style="padding: 0px" (click)="gotoLogin()">zur&uuml;ck</button>
      </div>
    </div>
  </div>
</div>
