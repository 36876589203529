import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpParamsOptions, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { map, tap } from 'rxjs/operators';

import { SortDirection } from '@angular/material/sort';

import { AuthQuery } from '@app/auth/auth.query';

/*
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};
*/

export enum StatusTransfer {
  DRAFT = '0',
  TRANSFER = '1',
  UNGULTIG = '3',
}

/*
export interface IBaseRequest {
  api_key: string;
  phone_id: string;
  token: string;
  gemeinde_key: string;

  dienstnr_id: string;
  dienstnr_stvo: string;
  person: string;
}
*/

export interface TransferMandat {
  id: number;
  aktenzeichen: string;
  plate: string;
  kzland: string;
  kzbezirk: string;
  kznummer: string;
  street: string;
  streetnbr: string;
  hersteller: string;
  farbe: string;
  createdate: string;
  createtime: string;
  dienstnr_id: number;
  person: string;
  tatbestnr: string;
  konkretisierung: string;
  printed: number;
  printdate: string;
  printcount: number;
  mandat_id: number;
  transferred: number;
  transferdate: string;
  notes: string;
  info1: string;
  info2: string;
  info3: string;
  longitude: string;
  lattitude: string;
  fahrzeugart: number;
  zone: number;
  ticketnr: number;
  ticket_zeit: string;
  zusatz1: string;
  zusatz2: string;
  strafe: number;
  dienstnr_stvo: number;
  transfer_db: number;
  status_id: string;
  created: string;

  tatzeit: string;
  tat_datum: string;
  tat_zeit: string;

  exif: string;

  // additional params for checking
  errorId?: number;
  errorMessage?: string; // Dynamic error message
}

export interface TransferMandatSingle {
  data: TransferMandat;
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

export interface TransferMandatTable {
  data: TransferMandat[];
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

export interface PlateRecon {
  id: number;
  aktenzeichen: string;
  land: string;
  bezirk: string;
  kennzeichen: string;
  plate: string;
  farbe: string;
  hersteller: string;
  notes: string;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
  }),
};

//
// --------------------------------------------------------------
//

@Injectable({
  providedIn: 'root',
})
export class TransferMandatService {
  errorMsg: string = '';

  constructor(private httpClient: HttpClient, private authQuery: AuthQuery) {}

  /**
   * GET ALL
   *
   * @return response()
   */

  public getTransferMandate(
    pageNumber: Number,
    pageSize: Number,
    sort: string,
    order: SortDirection,
    searchTerm: string,
    filtersArgs: string
  ): Observable<TransferMandatTable> {
    const apiUrl = `${environment.serverUrl}/transfer`;
    // '?page=${pageNumber}&per_page=${pageSize}&sort=${sort}&sorder=${order}`

    return this.httpClient.get<TransferMandatTable>(apiUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
      }),
      params: new HttpParams()
        .set('page', '' + pageNumber)
        .set('per_page', '' + pageSize)
        .set('sort', sort)
        .set('sorder', order)
        .set('sterm', searchTerm)
        .set('filters', filtersArgs)
        .set('webapp', 1),
    });

    /*
    .pipe(
      map((response: VisitorTable) => {
        //debugger;
        return response.data.map((item: Visitor) => ({
          ...item,
        }));
      }),
      tap((response: any) => {
        console.log('mrio');
        console.log(response);
        return <Visitor[]>response;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
    */
  }

  /**
   * GET / FIND BY ID
   *
   * @return response()
   */
  getTransferMandatById(id: number): Observable<TransferMandatSingle> {
    const apiUrl = `${environment.serverUrl}/transfer/` + id;

    return this.httpClient
      .get<TransferMandatSingle>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
        }),
        params: new HttpParams().set('id', '' + id),
      })
      .pipe(
        map((response: TransferMandatSingle) => {
          return {
            ...response,
          };
        })
      );

    /*
    return this.httpClient.get<Visitor[]>(environment.serverUrl + '/visitor/' + id, httpOptions).pipe(
      tap((resp) => {
        //this.repo.setQuizes( resp || []);
        return resp;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
    */
  }

  /**
   * GET / FIND BY FILTER
   *
   * @return response()
   */
  getTransferMandatByParams(data: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    //const data: any = { this: 'thisThing', that: 'thatThing', other: 'otherThing'};

    const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;

    const options = { params: new HttpParams(httpParams), headers: headers };

    return this.httpClient.get<TransferMandat[]>(environment.serverUrl + '/transfer/', options).pipe(
      tap((resp) => {
        //this.repo.setQuizes( resp || []);
        return resp;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * clone the TRANSFER into the MANDATE >>> damit wird es zu einem AKTIVEN Strafmandat (Status-erfasst)
   *          oder auch für die polizeit TEILERFASST !!!!!!! BUG BUG BUG
   *
   * @return response()
   */
  doTransfer2Mandate(postData: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    //const data: any = { this: 'thisThing', that: 'thatThing', other: 'otherThing'};

    return this.httpClient
      .post<any>(environment.serverUrl + '/transfer2mandate/', JSON.stringify(postData), httpOptions)
      .pipe(
        map((response) => {
          return {
            ...response.data,
          };
        }),
        tap((response: any) => {
          console.log(response);
          //this.repo.addQuiz(response);
        }),
        catchError((error) => {
          let errorMsg: string = '';
          if (error.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${error.error.message}`;
          } else {
            this.errorMsg = this.getServerErrorMessage(error);
          }

          return throwError(errorMsg);
        })
      );
  }

  /**
   * CREATE
   *
   * @return response()

  createVisitor(post: any): Observable<any> {
    return this.httpClient.post<any>(environment.serverUrl + '/transfer/', JSON.stringify(post), httpOptions).pipe(
      map((response) => {
        return {
          ...response.data,
          id: response.data.quuid,
          quizdata: '',
        };

      }),
      tap((response: any) => {
        console.log(response);
        //this.repo.addQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }
  */

  /**
   * UPDATE
   *
   * @return response()
   */
  updateTransfer(transfer: TransferMandat): Observable<any> {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: this.authQuery.getValue().phone_id,
      token: this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: this.authQuery.getValue().dienstnr,
      didstvo: this.authQuery.getValue().dienstnr_stvo,
      didname: this.authQuery.getValue().dienstname,
    };

    // get the data for the item to save
    var id = transfer.id;
    var transfer_json = JSON.stringify(transfer);
    const data = {
      ...requestAuth,
      id: id,
      aktenzeichen: transfer.aktenzeichen,
      mandat: transfer_json,
    };

    return this.httpClient.post<any>(environment.serverUrl + '/tupdate', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.mandat,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * FINALIZE
   *
   * @return response()
   */
  finalizeTransfer(transfer: TransferMandat): Observable<any> {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: this.authQuery.getValue().phone_id,
      token: this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: this.authQuery.getValue().dienstnr,
      didstvo: this.authQuery.getValue().dienstnr_stvo,
      didname: this.authQuery.getValue().dienstname,
    };

    // get the data for the item to save
    var id = transfer.id;
    var transfer_json = JSON.stringify(transfer);
    const data = {
      ...requestAuth,
      id: id,
      aktenzeichen: transfer.aktenzeichen,
    };

    console.log('sylvia war da');
    console.log(data);

    return this.httpClient.post<any>(environment.serverUrl + '/mandatefin', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.mandat,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * CHECK
   *
   * @return response()
   */
  checkTransfer(transfer: TransferMandat): Observable<any> {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: '' + this.authQuery.getValue().phone_id,
      token: '' + this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: '' + this.authQuery.getValue().dienstnr,
      didstvo: '' + this.authQuery.getValue().dienstnr_stvo,
      didname: '' + this.authQuery.getValue().dienstname,
    };

    // get the data for the item to save
    var id = transfer.id;
    var transfer_json = JSON.stringify(transfer);
    const data = {
      ...requestAuth,
      id: id,
      aktenzeichen: transfer.aktenzeichen,
    };

    console.log('sylvia war da');
    console.log(data);

    return this.httpClient.post<any>(environment.serverUrl + '/transfercheck', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          positiv: response.result,
          ...response,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * UPDATE
   *
   * @return response()
   */
  changeStatus(transfer: TransferMandat, newStatusID: string): Observable<any> {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: this.authQuery.getValue().phone_id,
      token: this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,

      did: this.authQuery.getValue().dienstnr,
      didstvo: this.authQuery.getValue().dienstnr_stvo,
      didname: this.authQuery.getValue().dienstname,
    };

    // get the data for the item
    var id = transfer.id;
    //var transfer_json = JSON.stringify(transfer);
    const data = {
      ...requestAuth,
      id: id,
      aktenzeichen: transfer.aktenzeichen,
      ostatusid: transfer.status_id,
      nstatusid: newStatusID,
    };

    console.log(data);

    return this.httpClient.post<any>(environment.serverUrl + '/cstatus', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.mandat,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * DELETE --- > in unserem fall ist es kein delete -> sondern ein status-change !! auf ungültig
   *
   * @return response()

  deleteVisitor(id: string) {
    return this.httpClient.delete(environment.serverUrl + '/visitor/' + id, httpOptions).pipe(
      tap((value) => {
        //this.repo.removeQuiz(id);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }
  */

  /**
   * SAVE PLATE RECOGNITION
   *
   * @return response()
   */
  savePlateRecognition(plateRecon: PlateRecon): Observable<any> {
    // get the base request data
    var requestAuth = {
      apikey: '' + environment.api_key,
      phone_id: this.authQuery.getValue().phone_id,
      token: this.authQuery.getValue().access_token,
      akey: '' + environment.api_key,
      sec_number: '' + environment.api_key,

      did: this.authQuery.getValue().dienstnr,
      didstvo: this.authQuery.getValue().dienstnr_stvo,
      didname: this.authQuery.getValue().dienstname,
    };

    // get the data for the item to save
    const data = {
      ...requestAuth,
      ...plateRecon,
    };

    console.log('sylvia war da');
    console.log(data);

    return this.httpClient.post<any>(environment.serverUrl + '/mandatanpr', JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.mandat,
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        console.log(response);
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getServerErrorMessage(error: HttpErrorResponse) {
    console.log('custom service error handler');

    switch (error.status) {
      case 0: {
        // A client-side or network error occurred. Handle it accordingly.
        //console.error('An error occurred:', error.error);
        return `An error occurred: ${error.error}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.error}`;
      }

      // Return an observable with a user-facing error message.
      //return throwError(() => new Error('Something bad happened; please try again later.'));
    }
  }
}
