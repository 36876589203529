import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

//import { I18nModule } from '@app/i18n';
import { MaterialModule } from '@app/material.module';

import { NgxFaceAvatarModule } from 'ngx-faceavatar';

import { AuthModule } from '@app/auth';
import { ShellComponent } from './shell.component';

import { ShellRoutingModule } from './shell-routing.module';
import { PhutureIavatarModule } from '../@components/phuture-iavatar/phuture-iavatar.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,

    NgxFaceAvatarModule,

    AuthModule,

    PhutureIavatarModule,

    //I18nModule,
    RouterModule,
    ShellRoutingModule,
  ],
  declarations: [ShellComponent],
  exports: [],
})
export class ShellModule {}
