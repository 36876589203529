import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoaderComponent } from './loader/loader.component';

import { TagPipe } from './pipe/pipe-tag';
import { TimePipe } from './pipe/pipe-time';
import { SortPipe } from './pipe/sort.pipe';
import { TranslateColorPipe } from './pipe/translate-color.pipe';
import { TranslateLandPipe } from './pipe/translate-land.pipe';
import { TranslateHerstellerPipe } from './pipe/translate-hersteller.pipe';
import { PadStringPipe } from './pipe/pad-string.pipe';

@NgModule({
  imports: [MatProgressSpinnerModule, CommonModule],
  declarations: [
    LoaderComponent,
    TagPipe,
    TimePipe,
    SortPipe,
    TranslateColorPipe,
    TranslateLandPipe,
    TranslateHerstellerPipe,
    PadStringPipe,
  ],
  exports: [
    LoaderComponent,
    TagPipe,
    TimePipe,
    SortPipe,
    TranslateColorPipe,
    TranslateLandPipe,
    TranslateHerstellerPipe,
    PadStringPipe,
  ],
})
export class SharedModule {}
