import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import * as storage from './storage';

export type AuthState = {
  appid: number;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  role: number;
  token_type: string;
  name: string;
  email: string;
  firststart_done: number;
  version: number;
  userid: number;
  status: number;
  appdata: string;
  company: string;
  fid: number;
  firststart: string;
  isapproved: number;
  isactivated: number;
  profile_image: string;
  country: string;

  phone_id: string;
  gemeinde_key: string;
  accesspoint: string;
  request_ip: string;

  dienstnr: string;
  dienstname: string;
  dienstnr_stvo: string;
  sachbereich: string;
};

export type ReturnAuthState = {
  appid: number;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  role: number;
  token_type: string;
  name: string;
  email: string;
  firststart_done: number;
  version: number;
  userid: number;
  appdata: string;
  company: string;
  fid: number;
  firststart: string;
  isapproved: number;
  isactivated: number;
  profile_image: string;
  country: string;

  phone_id: string;
  gemeinde_key: string;
  accesspoint: string;
  request_ip: string;

  dienstnr: string;
  dienstname: string;
  dienstnr_stvo: string;
  sachbereich: string;

  status: number;
  msg: string;
  error: string;
};

export function createInitialAuthState(): AuthState {
  return {
    name: null,
    email: null,
    firststart: null,
    ...storage.getSession(),
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialAuthState());
  }

  save(auth: AuthState) {
    this.update(auth);
    storage.saveSession(auth);
  }

  remove() {
    storage.clearSession();
    this.reset();
    //this.update(createInitialAuthState());
  }
}
