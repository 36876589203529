<div class="container">
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 d-flex align-items-center mt-4 mt-md-0">
          <div style="text-align: left">
            <h1>Hilfe</h1>
            <p class="margin-top-s">
              Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich
              mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche
              Seelen geschaffen ist wie die meine.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
