import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '@core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { JournalService, MailService, Mail } from '@core';

const log = new Logger('Support');

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  supportForm!: FormGroup;
  error: string;

  constructor(
    private formBuilder: FormBuilder,
    private mailService: MailService,
    private journalService: JournalService,
    private _snackBar: MatSnackBar
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  sendSupport() {
    const support$ = this.mailService.send(this.supportForm.value);
    support$
      .pipe(
        finalize(() => {
          this.supportForm.markAsPristine();
        })
        //untilDestroyed(this)
      )
      .subscribe(
        (data) => {
          if (data.code == 200) {
            this._snackBar.open('Anfrage erfolgreich gesendet.', 'Schließen', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
            });
          } else {
            this._snackBar.open('Fehler aufgetreten.', 'Schließen', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
            });
          }

          log.debug(`support msg sent`);
          this.journalService.log('Support Anfrage gesendet.', '', '');
        },
        (error) => {
          this._snackBar.open('Fehler aufgetreten.', 'Schließen', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
          });
          log.debug(`Support sending error: ${error}`);
          this.error = error;
        }
      );
  }

  private createForm() {
    this.supportForm = this.formBuilder.group({
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }
}
