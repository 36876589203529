<div>
  <h1 class="page-title">VSTV</h1>
</div>

<div
  class="offcanvas offcanvas-end"
  data-bs-backdrop="default"
  tabindex="-1"
  id="staticBackdrop"
  aria-labelledby="staticBackdropLabel">
  <div class="offcanvas-header">
    <h1 class="offcanvas-title" id="staticBackdropLabel">Mandat: #{{ current_mandat?.Md_BelegNr }}</h1>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
          clip-rule="evenodd" />
      </svg>
    </button>
  </div>
  <div class="offcanvas-body">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="container-fluid" style="margin-top: 30px">
  <!-- / .row -->
  <div class="row">
    <div class="col-12">
      <mat-tab-group (selectedTabChange)="onTabChange($event)">
        <!--
          ------------------------------------------------------------
          AKTUELLES PAKET
        -->
        <mat-tab label="Aktuelles Paket">
          <div class="mx-4">
            <div
              *ngIf="isLoading"
              style="display: flex; justify-content: center; align-items: center; background: white">
              <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
            </div>

            <mat-form-field appearance="standard">
              <mat-label>Suche Aktenzeichen</mat-label>
              <input matInput (keyup)="applyFilter($event)" [(ngModel)]="searchValue" placeholder="" #input />
              <button
                mat-button
                *ngIf="searchValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="searchValue = ''; applyFilter($event)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="example-container">
            <table
              mat-table
              [dataSource]="dataSource"
              class="mat-elevation-z0"
              matSort
              matSortActive="Md_PrimKey"
              matSortDisableClear
              matSortDirection="desc"
              matTableExporter
              #exporter="matTableExporter">
              <!----------------------------------------------------------------- -->

              <!-- ID Column -->
              <ng-container matColumnDef="Md_PrimKey">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let row">{{ row.Md_PrimKey }}</td>
              </ng-container>

              <!-- Md_Status Column -->
              <ng-container matColumnDef="Md_Status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Status</th>
                <td mat-cell *matCellDef="let row">
                  <div
                    *ngIf="row.Md_Status == 'A'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-approved word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ANGEZEIGT
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'T'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-gray [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    TEIL-ERFASST
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'E'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-darkgray [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ERFASST
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'U'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-overdue [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ÜBERFÄLLIG
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'B'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-active-fsdone [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    BEZAHLT
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'AB'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-warn [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ANG/BEZ
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'S'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-lightgray [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    STORNO
                  </div>

                  <div
                    *ngIf="row.Md_Status == '8'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-warn [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    AUSGENOMMEN
                  </div>

                  <div
                    *ngIf="row.Md_Status == '9'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-error [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ERROR
                  </div>
                </td>
              </ng-container>

              <!-- Profilbild Column
              <ng-container matColumnDef="profile_image">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-50">&nbsp;</th>
                <td mat-cell *matCellDef="let row">
                  <phuture-iavatar src_image="{{ imageStorage }}{{ row.profile_image }}"></phuture-iavatar>
                </td>
              </ng-container>
              -->

              <!-- Md_BelegNr Column -->
              <ng-container matColumnDef="Md_BelegNr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Aktenzeichen</th>
                <td mat-cell *matCellDef="let row" class="font-semibold">&nbsp;{{ row.Md_BelegNr }}&nbsp;</td>
              </ng-container>

              <!----------------------------------------------------------------- -->

              <!-- Md_StatusDate Column -->
              <ng-container matColumnDef="Md_StatusDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
                <td mat-cell *matCellDef="let row">&nbsp;{{ row.Md_StatusDate | date }}&nbsp;</td>
              </ng-container>

              <!-- Md_StatusTime Column -->
              <ng-container matColumnDef="Md_StatusTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Zeit</th>
                <td mat-cell *matCellDef="let row">&nbsp;{{ row.Md_StatusTime | date : 'shortTime' }}&nbsp;</td>
              </ng-container>

              <!-- Md_KzLand Column -->
              <ng-container matColumnDef="Md_KzLand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
                <td mat-cell *matCellDef="let row">&nbsp;{{ row.Md_KzLand }}&nbsp;</td>
              </ng-container>

              <!-- Md_KzBezirk Column -->
              <ng-container matColumnDef="Md_KzBezirk">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Bezirk</th>
                <td mat-cell *matCellDef="let row">{{ row.Md_KzBezirk }}</td>
              </ng-container>

              <!-- Md_KzNummer Column -->
              <ng-container matColumnDef="Md_KzNummer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Kennzeichen</th>
                <td mat-cell *matCellDef="let row">{{ row.Md_KzNummer }}</td>
              </ng-container>

              <!-- Md_Hersteller Column -->
              <ng-container matColumnDef="Md_Hersteller">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Hersteller</th>
                <td mat-cell *matCellDef="let row">{{ row.Md_Hersteller }}</td>
              </ng-container>

              <!-- Md_Betrag Column -->
              <ng-container matColumnDef="Md_Betrag">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Betrag</th>
                <td mat-cell *matCellDef="let row">{{ row.Md_Betrag }}</td>
              </ng-container>

              <!-- Person Column -->
              <ng-container matColumnDef="person">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Person</th>
                <td mat-cell *matCellDef="let row">{{ row.person }}</td>
              </ng-container>

              <!----------------------------------------------------------------- -->

              <!-- actions button bar Column
            -->

              <ng-container matColumnDef="actions" stickyEnd>
                <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                <td mat-cell *matCellDef="let row">
                  <!--
                  <button mat-icon-button [matMenuTriggerFor]="appMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #appMenu="matMenu">
                    <button mat-menu-item (click)="ausPaketAusnehmen(row)">Aus Paket ausnehmen</button>
                  </mat-menu>
                --></td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let emprow; columns: displayedColumns"></tr>
            </table>

            <mat-paginator
              #paginator
              [length]="totalItems"
              [pageSizeOptions]="pageSizes"
              showFirstLastButtons></mat-paginator>
          </div>

          <p>&nbsp;</p>

          <div>
            <form [formGroup]="frmExport">
              <div class="flex items-center space-x-2">
                <!--
                <mat-form-field>
                  <mat-label>Anzahl Mandate</mat-label>
                  <mat-select>
                    <mat-option *ngFor="let size of patchsize" [value]="size.viewValue">
                      {{ size.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
-->
                <label for="bearbeiter" class="mr-2">Bearbeiter:</label>
                <input
                  type="text"
                  class="form-input bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/3 p-2.5"
                  id="bearbeiter"
                  formControlName="bearbeiter"
                  placeholder="Bearbeiter" />
              </div>

              <button
                type="button"
                matRipple
                class="mt-4 bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
                (click)="generateVSTV(0)">
                VSTV exportieren
              </button>
            </form>
          </div>

          <p>&nbsp;</p>

          <div *ngIf="vstvlog != '' && !erneutDownload" class="logVSTVExport checkJournal">
            <p [innerHTML]="vstvlog"></p>

            <button
              type="button"
              matRipple
              [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
              class="bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
              [style]="{ 'margin-left': '.5em' }"
              (click)="downloadVSTV(paketID)">
              VSTV Datei herunterladen
            </button>
          </div>

          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </mat-tab>

        <!--
          ------------------------------------------------------------
          ARCHIV
        -->
        <mat-tab label="Archiv"
          ><p>&nbsp;</p>
          <p>Liste der bereits &uuml;bermittelten VSTV Pakete.</p>

          <div class="flex items-center space-x-2 mr-4">
            <select
              class="form-control"
              id="allVSTVPakete"
              [formControl]="popVSTVArchiv"
              class="mb-2 py-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option *ngFor="let paket of vstv_archiv_pakete | sort : 'created' : 'desc'" [value]="paket?.id">
                #{{ paket?.id }} | {{ paket?.bezeichnung }} - erstellt:
                {{ paket?.created | date : 'dd.MM.yyyy HH:mm:ss' }}
              </option>
            </select>
          </div>

          <div class="example-container">
            <table
              mat-table
              [dataSource]="dsVSTVArchivMandate"
              class="mat-elevation-z0"
              matSort
              matSortActive="Md_PrimKey"
              matSortDisableClear
              matSortDirection="desc"
              matTableExporter
              #exporter="matTableExporter">
              <!----------------------------------------------------------------- -->

              <!-- ID Column -->
              <ng-container matColumnDef="Md_PrimKey">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let row">{{ row.Md_PrimKey }}</td>
              </ng-container>

              <!-- Md_Status Column -->
              <ng-container matColumnDef="Md_Status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Status</th>
                <td mat-cell *matCellDef="let row">
                  <div
                    *ngIf="row.Md_Status == 'A'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-approved word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ANGEZEIGT
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'T'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-gray [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    TEIL-ERFASST
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'E'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-darkgray [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ERFASST
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'U'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-overdue [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ÜBERFÄLLIG
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'B'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-active-fsdone [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    BEZAHLT
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'AB'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-warn [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ANG/BEZ
                  </div>

                  <div
                    *ngIf="row.Md_Status == 'S'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-lightgray [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    STORNO
                  </div>

                  <div
                    *ngIf="row.Md_Status == '8'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-warn [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    AUSGENOMMEN
                  </div>

                  <div
                    *ngIf="row.Md_Status == '9'"
                    data-te-chip-init
                    data-te-ripple-init
                    class="chip-error [word-wrap: break-word] my-[5px] mr-4 flex h-[32px] cursor-pointer items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200"
                    data-te-close="true">
                    ERROR
                  </div>
                </td>
              </ng-container>

              <!-- Profilbild Column
                <ng-container matColumnDef="profile_image">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-50">&nbsp;</th>
                  <td mat-cell *matCellDef="let row">
                    <phuture-iavatar src_image="{{ imageStorage }}{{ row.profile_image }}"></phuture-iavatar>
                  </td>
                </ng-container>
                -->

              <!-- Md_BelegNr Column -->
              <ng-container matColumnDef="Md_BelegNr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Aktenzeichen</th>
                <td mat-cell *matCellDef="let row" class="font-semibold">&nbsp;{{ row.Md_BelegNr }}&nbsp;</td>
              </ng-container>

              <!----------------------------------------------------------------- -->

              <!-- Md_StatusDate Column -->
              <ng-container matColumnDef="Md_StatusDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
                <td mat-cell *matCellDef="let row">&nbsp;{{ row.Md_StatusDate | date }}&nbsp;</td>
              </ng-container>

              <!-- Md_StatusTime Column -->
              <ng-container matColumnDef="Md_StatusTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Zeit</th>
                <td mat-cell *matCellDef="let row">&nbsp;{{ row.Md_StatusTime | date : 'shortTime' }}&nbsp;</td>
              </ng-container>

              <!-- Md_KzLand Column -->
              <ng-container matColumnDef="Md_KzLand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
                <td mat-cell *matCellDef="let row">&nbsp;{{ row.Md_KzLand }}&nbsp;</td>
              </ng-container>

              <!-- Md_KzBezirk Column -->
              <ng-container matColumnDef="Md_KzBezirk">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Bezirk</th>
                <td mat-cell *matCellDef="let row">{{ row.Md_KzBezirk }}</td>
              </ng-container>

              <!-- Md_KzNummer Column -->
              <ng-container matColumnDef="Md_KzNummer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Kennzeichen</th>
                <td mat-cell *matCellDef="let row">{{ row.Md_KzNummer }}</td>
              </ng-container>

              <!-- Md_Hersteller Column -->
              <ng-container matColumnDef="Md_Hersteller">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Hersteller</th>
                <td mat-cell *matCellDef="let row">{{ row.Md_Hersteller }}</td>
              </ng-container>

              <!-- Md_Betrag Column -->
              <ng-container matColumnDef="Md_Betrag">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Betrag</th>
                <td mat-cell *matCellDef="let row">{{ row.Md_Betrag }}</td>
              </ng-container>

              <!-- Person Column -->
              <ng-container matColumnDef="person">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-column-100">Person</th>
                <td mat-cell *matCellDef="let row">{{ row.person }}</td>
              </ng-container>

              <!----------------------------------------------------------------- -->

              <!-- actions button bar Column
              -->

              <ng-container matColumnDef="actions" stickyEnd>
                <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                <td mat-cell *matCellDef="let row">
                  <button mat-icon-button [matMenuTriggerFor]="appMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #appMenu="matMenu">
                    <button mat-menu-item (click)="ausPaketAusnehmen(row)">Aus Paket ausnehmen</button>
                    <!--
                    <button mat-menu-item (click)="editItem(row)">Bearbeiten</button>
                    -->
                  </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let emprow; columns: displayedColumns"></tr>
            </table>

            <mat-paginator
              #paginator
              [length]="totalItems"
              [pageSizeOptions]="pageSizes"
              showFirstLastButtons></mat-paginator>
          </div>

          <p>&nbsp;</p>

          <form [formGroup]="frmExportArchiv">
            <div *ngIf="selectedVSTVPaketID > 0">
              <div class="flex items-center space-x-2">
                <label for="bearbeiter" class="mr-2">Bearbeiter:</label>
                <input
                  type="text"
                  class="form-input bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/3 p-2.5"
                  id="bearbeiterErneut"
                  formControlName="bearbeiterErneut"
                  placeholder="Bearbeiter" />
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  matRipple
                  [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
                  class="bg-gray-400 hover:bg-gray-700 text-white py-2 px-4"
                  [style]="{ 'margin-left': '.5em' }"
                  (click)="downloadVSTV(selectedVSTVPaketID, true)">
                  VSTV erneut downloaden
                </button>

                <button
                  type="button"
                  matRipple
                  [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
                  class="bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
                  [style]="{ 'margin-left': '.5em' }"
                  (click)="generateVSTV(selectedVSTVPaketID)">
                  VSTV neu erstellen
                </button>
              </div>

              <div *ngIf="vstvlog != '' && erneutDownload" class="logVSTVExport checkJournal">
                <p [innerHTML]="vstvlog"></p>

                <button
                  type="button"
                  matRipple
                  [matRippleColor]="'rgb(103, 121, 189, 0.5)'"
                  class="bg-blue-400 hover:bg-blue-700 text-white py-2 px-4"
                  [style]="{ 'margin-left': '.5em' }"
                  (click)="downloadVSTV(paketID)">
                  VSTV Datei herunterladen
                </button>
              </div>
            </div>
          </form>
          <p>&nbsp;</p>
        </mat-tab>
      </mat-tab-group>

      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </div>
</div>
