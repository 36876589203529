import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpParamsOptions, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { map, tap } from 'rxjs/operators';

import { SortDirection } from '@angular/material/sort';

/*
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};
*/

/**
 * DATA STRUCTURES
 */
export interface Farbe {
  id: number;
  bezeichnung: string;
  usage: number;
}
export interface Hersteller {
  id: number;
  bezeichnung: string;
  usage: number;
}
export interface Street {
  id: number;
  bezeichnung: string;
  tatortart: string;
  zone: number;
  usage: number;
}
export interface Bezirk {
  id: number;
  land: string;
  bezeichnung: string;
  usage: number;
}
export interface Land {
  id: number;
  bezeichnung: string;
  usage: number;
}
export interface Luecke {
  luecke: number;
  titel: string;
  info: string;
  default: string;
  value?: string; // Optional to handle merging with original_codes
}
export interface Tatbestand {
  id: number;
  vstv_code: string;
  paragraph: string;
  reihung: number;
  strafe: number;
  short: string;
  drucktext: string;
  usage: number;
  luecken: Luecke[];
  freitext: string;
}

export interface Stammdaten {
  farben: Farbe[];
  hersteller: Hersteller[];
  streets: Street[];
  bezirke: Bezirk[];
  lander: Land[];
  tatbestande: Tatbestand[];
  usage: number;
}

interface ApiResponse {
  data: Stammdaten;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
  }),
};

//
// --------------------------------------------------------------
//

@Injectable({
  providedIn: 'root',
})
export class StammdatenService {
  errorMsg: string = '';

  constructor(private httpClient: HttpClient) {}

  /**
   * GET / FIND BY ID
   *
   * @return response()
   */
  getStammdaten(): Observable<Stammdaten> {
    const apiUrl = `${environment.serverUrl}/stammdaten`;

    return this.httpClient
      .get<ApiResponse>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
        }),
        //params: new HttpParams().set('id', '' + id),
      })
      .pipe(
        map((response) => {
          // repair the data > as the old version had no luecken info
          response.data.tatbestande.forEach((tatbestand) => {
            if (!Array.isArray(tatbestand.luecken) || tatbestand.luecken === null) {
              tatbestand.luecken = []; // Set luecken to an empty array if it is not an array or is null
            }
          });

          return response.data;
        })
      );
  }

  /**
   * SYNC STAMMDATEN
   *
   * @return response()
   */

  public syncStammdaten(postData: any): Observable<Stammdaten> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.httpClient.post<any>(environment.serverUrl + '/stammdaten', JSON.stringify(postData), httpOptions).pipe(
      map((response) => {
        return {
          ...response.data,
        };
      }),
      tap((response: any) => {
        console.log(response);
        //this.repo.addQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );

    /*
    .pipe(
      map((response: VisitorTable) => {
        //debugger;
        return response.data.map((item: Visitor) => ({
          ...item,
        }));
      }),
      tap((response: any) => {
        console.log('mrio');
        console.log(response);
        return <Visitor[]>response;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
    */
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getServerErrorMessage(error: HttpErrorResponse) {
    console.log('custom service error handler');

    switch (error.status) {
      case 0: {
        // A client-side or network error occurred. Handle it accordingly.
        //console.error('An error occurred:', error.error);
        return `An error occurred: ${error.error}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.error}`;
      }

      // Return an observable with a user-facing error message.
      //return throwError(() => new Error('Something bad happened; please try again later.'));
    }
  }
}
