<div>
  <div class="authGridContainer authGridContainer--login authGridContainer--fullScreen">
    <a class="authGrid__logo" href="/" aria-label="Link to main page" tabindex="0">
      <img src="./assets/parkraumplus-logo.png" alt="ParkraumPlus" />
    </a>
    <div class="authGridColumn authGridColumn--callToActionParent">
      <div class="createFreeAccount">
        <span class="createFreeAccount__text" style="font-size: 1.6em; line-height: 30px"
          >Die effiziente Parkraumverwaltung</span
        >
      </div>
    </div>
    <div class="authGridColumn authGridColumn--formParent">
      <div class="formContainer loginForm">
        <div class="example-small-box">
          <!-- Heading -->
          <h1 class="mb-0" style="font-size: 25px">ParkraumPlus V6</h1>
          <h3 class="mb-3">Anmelden</h3>

          <!-- Form -->
          <form (ngSubmit)="login()" [formGroup]="loginForm" class="mb-3">
            <!-- Email -->
            <div class="form-group">
              <label class="form-label" for="email"> E-Mail </label>
              <input
                type="email"
                formControlName="email"
                id="email"
                class="form-control"
                required
                placeholder="name@address.com" />
            </div>

            <!-- Password -->
            <div class="form-group mb-3">
              <label class="form-label" for="password"> Password </label>
              <input
                [type]="!eye ? 'password' : 'text'"
                formControlName="password"
                id="password"
                class="form-control"
                required />
              <mat-icon class="field-icon toggle-password" style="padding-top: 10px" (click)="toggleEye()">{{
                eyeIcon
              }}</mat-icon>
            </div>

            <!-- Submit -->
            <div class="flex items-center justify-between">
              <button mat-button mat-ripple class="btn-primary" type="submit">Sign In</button>

              <button mat-button type="button" (click)="gotoForgotPwd()">Forgot Password</button>
            </div>
          </form>
        </div>

        <!--
        <h2>Anmelden</h2>

        <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
          <div class="login-error" [hidden]="!error || isLoading">Benutzername oder Kennwort sind falsch.</div>
          <br />
          <div class="login-fields" fxLayout="column">
            <mat-form-field [hideRequiredMarker]="true">
              <input type="text" matInput formControlName="email" autocomplete="email" [placeholder]="'name@email.at'" required />
              <mat-error *ngIf="loginForm.controls.email.invalid && loginForm.controls.email.touched">
                <span>Benutzername muss angegeben werden</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field [hideRequiredMarker]="true">
              <input
                type="password"
                matInput
                formControlName="password"
                autocomplete="current-password"
                [placeholder]="'Kennwort'"
                required />
              <mat-error *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
                <span>Kennwort muss angegeben werden</span>
              </mat-error>
            </mat-form-field>
            <mat-slide-toggle color="primary" formControlName="remember">Angemeldet bleiben</mat-slide-toggle>
            <br />
            <button
              class="btnLogin"
              mat-focus-indicator
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="loginForm.invalid || isLoading">
              <span>Anmelden</span>
            </button>

            <br />
            <br />
            <button mat-flat-button style="padding: 0px" (click)="gotoForgotPwd()">Kennwort vergesssen?</button>
          </div>
        </form>

-->
      </div>
    </div>
  </div>
</div>
