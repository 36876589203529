import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '@core';
import { AuthQuery } from '@app/auth/auth.query';
import { AuthDataService } from '@app/auth/auth-data.service';

const log = new Logger('ForgotPwd');

@Component({
  selector: 'app-forgot-pwd-page',
  templateUrl: './forgot-pwd-page.component.html',
  styleUrls: ['./forgot-pwd-page.component.scss'],
})
export class ForgotPwdPageComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authQuery: AuthQuery,
    private authService: AuthDataService,
    private _snackBar: MatSnackBar
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  gotoRegisterNew() {
    this.router.navigateByUrl('/register-new');
  }

  gotoLogin() {
    this.router.navigateByUrl('/login');
  }

  forgotPwd() {
    var username = this.authQuery.name;
    var userid = this.authQuery.userid;

    var email = this.loginForm.controls['email'].value;

    let data = {
      email: email,
      uid: userid,
    };

    const changePwd$ = this.authService.forgotpwd(data);
    changePwd$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
        })
      )
      .subscribe(
        (data: any) => {
          console.log(data);

          // show done notification alert
          if (data.code == 200) {
            this._snackBar.open(
              'Kennwortanfrage erfolgreich geschickt',
              'Schließen',
              {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 3000,
              }
            );
          } else {
            this._snackBar.open('Fehler aufgetreten.', 'Schließen', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        },
        (error: any) => {
          // show error notification alert
          this._snackBar.open('Fehler aufgetreten', 'Schließen', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      );
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }
}
