import {
  Component,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit,
  OnChanges,
  HostListener,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { tap, map, startWith } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
//import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DeviceDetectorService } from 'ngx-device-detector';

import { DatePipe } from '@angular/common';

import * as tus from 'tus-js-client';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';

import { environment } from '../../environments/environment';
import { Logger } from '@core';

import { AuthQuery } from '@app/auth/auth.query';

import { JournalService, Journal, JournalSingle, JournalTable, MailService, Mail } from '@core';
import { CookieService } from 'ngx-cookie-service';

import { AuthDataService } from '@app/auth/auth-data.service';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS_FACTORY } from '@angular/material/progress-spinner';

import { requireAtLeastOneCheckboxValidator } from '../@shared/validation/require-checkboxes-to-be-checked.validator';

import { ConfirmDialogComponent } from '../@shared/dialog/confirm-dialog/confirm-dialog.component';
import { TranslateLandPipe } from '@app/@shared/pipe/translate-land.pipe';

import {
  TransferMandatService,
  TransferMandat,
  TransferMandatSingle,
  StatusTransfer,
  PlateRecon,
} from '../services/transfer.service';
import { ImageService, MandatImage, MandatImageTable } from '@app/services/image.service';

import { DataChangeService } from '../transfer/changes-transfer.service';
import {
  StammdatenService,
  Stammdaten,
  Tatbestand,
  Street,
  Land,
  Bezirk,
  Hersteller,
  Farbe,
} from '@app/services/stammdaten.service';
import { fromJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';

declare var bootstrap: any; // Declare bootstrap to avoid TypeScript errors

const log = new Logger('Login');

export interface LueckenValue {
  luecken: string[];
}

@Component({
  templateUrl: './transfer_edit.component.html',
  styleUrls: ['./transfer_edit.component.scss'],
})
export class TransferEditComponent implements OnInit {
  transfer: TransferMandat;
  @Input() transfer_id;

  @Input() _uid; // person_id
  @Input() _fid; // transfer_id
  @Input() _role;
  //@Input() _country;
  @Input() _isAdmin;

  aktenzeichen: string = '';

  journalItems: Journal[] = [];

  _accounting_link: string;

  stammdaten: Stammdaten = {
    farben: [],
    hersteller: [],
    streets: [],
    bezirke: [],
    lander: [],
    tatbestande: [],
    usage: 0,
  };
  filteredBezirke$: Observable<any[]>;

  @ViewChild('myPond') myPond: any;
  pondOptions = {
    class: 'my-filepond',
    credits: false,
    multiple: true,
    maxFiles: 5,
    allowFileTypeValidation: true,
    acceptedFileTypes: ['image/png', 'image/jpeg'],
    labelFileTypeNotAllowed: 'File of invalid type: only JPG, PNG',
    fileValidateTypeLabelExpectedTypesMap: { 'image/jpeg': '.jpg', 'image/png': '.png' },
    allowImageValidateSize: true,
    imageValidateSizeMinWidth: 150,
    imageValidateSizeMinHeight: 150,
    allowImageResize: true,
    imageResizeTargetWidth: 2400,
    imageResizeTargetHeight: 1600,
    imageResizeMode: 'contain',
    imageResizeUpscale: true,
    imageCropAspectRatio: 1,
    labelIdle: 'Drop files here or <a style="text-decoration:underline;">Browse</a> ...',
    // acceptedFileTypes: ['image/jpeg, image/png'],
    server: {
      process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        // fieldName is the name of the input field
        // file is the actual file object to send
        //debugger;
        //console.table(fieldName);
        const formData = new FormData();
        formData.append(fieldName, file, file.name);
        formData.append('filepond', file.name);

        formData.append('uid', '' + this._uid);
        formData.append('fid', '' + this._fid);
        formData.append('role', '' + this._role);
        formData.append('imgtype', '' + fieldName);

        formData.append('aktenzeichen', '' + this.aktenzeichen);
        formData.append('did', '' + this.authQuery.getValue().dienstnr);
        formData.append('didstvo', '' + this.authQuery.getValue().dienstnr_stvo);
        formData.append('didname', '' + this.authQuery.getValue().dienstname);

        formData.append('apikey', '' + environment.api_key);
        formData.append('phone_id', '' + this.authQuery.getValue().phone_id);
        formData.append('token', '' + this.authQuery.getValue().access_token);
        formData.append('akey', '' + environment.api_key);

        const request = new XMLHttpRequest();
        request.open('POST', environment.serverUrl + '/filepond/process');

        // set `Content-Type` header
        //request.setRequestHeader('Content-Type', 'application/json');
        request.setRequestHeader('Access-Control-Allow-Origin', '*');
        //xhr.setRequestHeader('Accept', "application/json");
        request.setRequestHeader('Access-Control-Allow-Headers', '*');

        // token
        request.setRequestHeader('Authorization', `Bearer ${this.authQuery.access_token}`);

        // Should call the progress method to update the progress to 100% before calling load
        // Setting computable to false switches the loading indicator to infinite mode
        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        // Should call the load method when done and pass the returned server file id
        // this server file id is then used later on when reverting or restoring a file
        // so your server knows which file to return without exposing that info to the client
        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object
            load(request.responseText);
          } else {
            // Can call the error method if something is wrong, should exit after
            error('oh no');
          }
        };

        request.send(formData);

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            request.abort();

            // Let FilePond know the request has been cancelled
            abort();
          },
        };
      },
      revert: 'filepond/revert',
      restore: 'filepond/restore?id=',
      fetch: 'filepond/fetch?data=',
      load: 'filepond/load',
    },
  };

  pondFiles = [
    // 'index.html'
  ];

  // request vars
  version: string | null = environment.version;
  error: string | undefined;
  isLoading = false;

  frmTransfer: FormGroup;
  submitted = false;

  imageStorage = environment.serverUrl + '/';
  _profile_image = '';

  // tatbestand
  transfer_tatbestande: Tatbestand[];

  // demo image gallery
  items1: GalleryItem[];
  mandatImages: MandatImage[];

  // plate recognition result
  plateRecon: any = '';
  private translateLandPipe = new TranslateLandPipe();
  //modell: any = '';
  //farbe: any = '';
  //richtung: any = '';

  //_country: string = '';

  constructor(
    private element: ElementRef,
    private _fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private authService: AuthDataService,
    private authQuery: AuthQuery,
    private router: Router,
    private _activeRoute: ActivatedRoute,
    private journalService: JournalService,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private transferService: TransferMandatService,
    private imageService: ImageService,
    public gallery: Gallery,
    public dialog: MatDialog,
    public dataChangeService: DataChangeService,
    public stammdatenService: StammdatenService,
    private datePipe: DatePipe
  ) {
    console.log('firststart-constructor');

    //this._country = this.authQuery.country;

    // init the form
    this.initForm();
  }

  ngOnInit(): void {
    console.log('firststart-oninit');

    // build lightbox gallery
    //this.gallery.ref().load(this.items1);

    this._activeRoute.paramMap.subscribe((paramMap) => {
      this.transfer_id = paramMap.get('id');

      this._uid = paramMap.get('_uid');
      //this._fid = paramMap.get('_fid');
      this._fid = this.transfer_id;
      this._role = paramMap.get('_role');
      //this._country = paramMap.get('_country');
      this._isAdmin = paramMap.get('ia');

      // get the data for the form and the tabs
      this.loadInitData();

      // reset the form
      this.initForm;

      // tatbestand
      this.transfer_tatbestande = [];

      // reset the filepond -> new display
      this.resetFilePond();

      // reset prüfprotokoll
      this.pruefung_positiv = 1;
      this.pruefprotokoll = '';
      this.errorprotokoll = '';
    });

    //
    // and also setup a subscriber if any value changes (for example the status)
    //
    this.frmTransfer.valueChanges.subscribe((selectedValue) => {
      //console.log('status_id changed to:', selectedValue);
    });
  }

  get role(): number | null {
    return this.authQuery.role;
  }

  /**
   * be informed on changeds on the datasource
   * @param data
   */
  broadcastChanges(data: any) {
    console.log('broadcast changes ');
    console.log(data);
    this.dataChangeService.updateData(data);
  }

  /**
   * initialize the form -> reset it
   */
  initForm() {
    // load and init the form
    this.frmTransfer = this._fb.group({
      //uid: this.authQuery.userid,
      //fid: this.authQuery.fid,
      //role: this.authQuery.role,
      uid: 0,
      fid: 0,
      role: 3,
      firststart_done: this.authQuery.firststart_done,

      id: '',
      mandat_id: '',

      aktenzeichen: ['', Validators.required],
      land: '',
      bezirk: '',
      nummer: '',
      hersteller: '',
      farbe: '',
      fahrzeugart: '',
      status_id: '0',

      datum: '',

      tatzeit: '',
      tat_zeit: '',
      tat_datum: '',

      strasse: '',
      hausnummer: '',
      tatbestandNr: '',
      dienstNr: '',
      dienstNrSTVO: '',
      person: '',

      strafe: '',

      internal_notes: '', // info3
      anmerkungen: '', // notes
      probekennzeichen: 0, // info2

      konkretisierung: '', // is a json
      //zusatz1: '',    // nicht mehr benotigt
      //zusatz2: '',    // nicht mehr benotigt

      tatbestande: this._fb.array([]), // json from tatbestnr

      selection: [],
      colorControl: [],
      makeControl: [],

      allTatbestande: [],
    });
  }

  /**
   * load all the data for the form
   * @returns
   */
  loadInitData() {
    // get both data

    this.stammdatenService
      .getStammdaten()
      .pipe(
        switchMap((stammdaten) => {
          this.stammdaten = stammdaten;

          return this.transferService.getTransferMandatById(this.transfer_id);
        })
      )
      .subscribe(
        (transferData) => {
          this.transfer = transferData.data;

          // merge the data for the luecken table
          this.transfer_tatbestande = this.mergeDataTatbestande(
            this.stammdaten.tatbestande,
            transferData.data.tatbestnr,
            transferData.data.konkretisierung
          );

          // ------------------------------ transfer
          //
          // set the profile image
          this._profile_image = ''; //visitor.data.profile_image;

          // set the accounting link
          //this._accounting_link = environment.serverUrl + '/pdf-accinfo/' + this._fid + '?&akey=12987361235283447283261';

          this.aktenzeichen = this.transfer.aktenzeichen;

          this.frmTransfer.patchValue({
            uid: this._uid,
            fid: this._fid,
            role: this._role,
            //firststart_done: this.authQuery.firststart_done,

            id: this.transfer.id,
            mandat_id: this.transfer.mandat_id,

            aktenzeichen: this.transfer.aktenzeichen,
            land: this.transfer.kzland,
            bezirk: this.transfer.kzbezirk,
            nummer: this.transfer.kznummer,
            hersteller: this.transfer.hersteller,
            farbe: this.transfer.farbe.toLowerCase(),
            probekennzeichen: false, // BUG BUG BUG not imploemented yet

            status_id: this.transfer.status_id ?? '0',

            fahrzeugart: this.transfer.fahrzeugart,
            internal_notes: this.transfer.info3, // internal_notes

            //datum: this.transfer.createdate,

            tatzeit: this.transfer.tatzeit,
            tat_zeit: this.transfer.tat_zeit,
            tat_datum: this.transfer.tat_datum,

            strasse: this.transfer.street,
            hausnummer: this.transfer.streetnbr,
            tatbestandNr: this.transfer.tatbestnr,
            dienstNr: this.transfer.dienstnr_id,
            dienstNrSTVO: this.transfer.dienstnr_stvo,
            person: this.transfer.person,
            konkretisierung: this.transfer.konkretisierung,

            zusatz1: this.transfer.zusatz1,
            zusatz2: this.transfer.zusatz2,

            strafe: this.transfer.strafe,

            anmerkungen: this.transfer.notes, // anmerkungen

            tatbestande: [],

            selection: [],
            colorControl: [],
            makeControl: [],
            allTatbestande: [],
          });
          // and now add the dynamic form controls for luecken

          // reset the formarray
          const formArray = this.frmTransfer.get('tatbestande') as FormArray;
          formArray.clear();

          // and now fill it with the correct luecken
          this.transfer_tatbestande.forEach((tatbestand) => {
            const tatbestandGroup = this._fb.group({
              luecken: this._fb.array(
                tatbestand.luecken.map((luecke) => this._fb.control(luecke.value || luecke.default))
              ),
            });
            this.tatbestandeFormArray.push(tatbestandGroup);
          });

          // show me the result
          console.log('------------------ merge patch values form ---_');
          console.log(this.frmTransfer.value);

          // build the tatbestand object for the table-visualization
          // this.get_Tatbestande_with_Luecken(this.transfer.tatbestnr);

          // get all journal-items with that $aktenzeichen
          this.getAllJournalItems(this.transfer_id, this.transfer.aktenzeichen);

          // load the images from the giveen transfer-mandat
          //this.items1 = imageData1.map((item) => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
          let searchterm = '';
          let aktenzeichen = this.transfer.aktenzeichen;
          this.imageService
            .getImages(
              //pageNumber = 1,
              //pageSize = 100,
              //sort = '',
              //order = null,
              searchterm,
              //filtersArgs: string,
              aktenzeichen
            )
            .subscribe((res: MandatImageTable) => {
              this.mandatImages = res.data;
              this.cdr.detectChanges();

              // load the exifdata for each image
              this.loadExifImageData();
            });
        },
        (error) => {
          console.error('Error during sequential API calls:', error);
        }
      );

    // ------------------------------ stammdaten
    // filter the bezirke
    // based on the land value
    this.filteredBezirke$ = this.frmTransfer.controls['land'].valueChanges.pipe(
      startWith(''),
      map((land) => this.stammdaten.bezirke.filter((b) => b.land === land))
    );
  }

  //
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  //

  //
  // --------------------- TATBESTANDE
  //

  get tatbestandeFormArray(): FormArray {
    return this.frmTransfer.get('tatbestande') as FormArray;
  }

  /**
   * merge the given data into the full tatbestande infos -> stammdaten + values luecken
   * @param stammdaten
   * @param specificData
   * @returns
   */
  mergeDataTatbestande(stammdatenTatbestande, tatbestande, luecken_values_json): Tatbestand[] {
    // Clone the original stammdaten to avoid mutations
    let cloneStammdaten = [];
    try {
      cloneStammdaten = JSON.parse(JSON.stringify(stammdatenTatbestande));
    } catch (error) {
      console.error('Failed to parse cloneStammdaten:', error);
      cloneStammdaten = [];
    }

    let transferTatbestande = [];
    try {
      transferTatbestande = JSON.parse(tatbestande);
    } catch (error) {
      console.error('Failed to parse transferTatbestande:', error);
      transferTatbestande = [];
    }

    let luecken_values: LueckenValue[] = [];
    try {
      luecken_values = luecken_values_json ? JSON.parse(luecken_values_json) : [];
    } catch (error) {
      console.error('Failed to parse luecken_values_json:', error);
      luecken_values = [];
    }

    if (!Array.isArray(transferTatbestande)) {
      transferTatbestande = [];
    }

    // Determine the version of originalCodes
    const codesMap = new Map<string, { luecke: number; value?: string }[]>();
    if (transferTatbestande.length > 0 && typeof transferTatbestande[0] === 'string') {
      // old versaion
      console.log("It's the old version");
      //return []; // Return early or handle as needed
      // Mapping original codes for quick lookup

      (transferTatbestande as Tatbestand[]).forEach((code) => {
        codesMap.set('' + code, []);
      });
    } else {
      // new version
      // Mapping original codes for quick lookup
      (transferTatbestande as Tatbestand[]).forEach((code) => {
        codesMap.set('' + code.id, code.luecken);
      });
    }

    // Enrich stammdaten with values from originalCodes
    const enrichedTatbestande: Tatbestand[] = cloneStammdaten
      .filter(
        (tatbestand: Tatbestand) => codesMap.has(tatbestand.vstv_code) // Only include tatbestande that have corresponding codes
      )
      .map((tatbestand: Tatbestand) => {
        const matchedCodes = codesMap.get(tatbestand.vstv_code);

        if (!Array.isArray(tatbestand.luecken)) {
          tatbestand.luecken = [];
        }

        if (matchedCodes) {
          tatbestand.luecken = tatbestand.luecken.map((luecke) => {
            const matchedLuecke = matchedCodes.find((c) => c.luecke === luecke.luecke);
            if (matchedLuecke) {
              return { ...luecke, value: matchedLuecke.value };
            }
            return luecke;
          });
        }
        return tatbestand;
      });

    // and now fill it with the given values
    enrichedTatbestande.forEach((tatbestand, index) => {
      const konkretisierungItem: LueckenValue = luecken_values[index];
      if (tatbestand.luecken && konkretisierungItem?.luecken) {
        tatbestand.luecken.forEach((luecke, lueckeIndex) => {
          if (konkretisierungItem.luecken.length > lueckeIndex) {
            // Check if the value exists
            luecke.value = konkretisierungItem.luecken[lueckeIndex] || luecke.default; // Use default if konkretisierung value is empty
          }
        });
      }
    });

    return enrichedTatbestande;
  }

  // how the luecken should be tracked in the for loop
  trackByFn(index, tatbestand: Tatbestand) {
    return tatbestand.id;
  }

  newTatbestand: string;
  addTatbestand() {
    const vstv_code = this.frmTransfer.controls['allTatbestande'].value;
    console.log('add tatbestand ' + vstv_code);

    // Find the matching tatbestand
    const matchingTatbestand = this.stammdaten.tatbestande.find((tatbestand) => tatbestand.vstv_code === vstv_code);

    if (!matchingTatbestand) {
      //console.error('No matching tatbestand found');

      this._snackBar.open('Bitte wählen Sie einen Tatbestand in der Liste aus.', 'Schließen', {
        duration: 1000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } else {
      // add the selected tatbestand to the list
      this.transfer_tatbestande.push(matchingTatbestand);

      // and push it also into the frmTransfer form
      // and now fill it with the correct luecken
      // wir müssen nur die luecken hinzufuegen -> rest kommt aus tranfer_tatbestand

      const tatbestandGroup = this._fb.group({
        luecken: this._fb.array(
          matchingTatbestand.luecken.map((luecke) => this._fb.control(luecke.value || luecke.default))
        ),
      });
      this.tatbestandeFormArray.push(tatbestandGroup);

      let arTaten: string[] = [];
      this.transfer_tatbestande.forEach((tatbestand) => {
        arTaten.push(tatbestand.vstv_code);
      });
      // also update the form-field tatbestandnr (json of all selected vstv_codes)
      this.frmTransfer.patchValue({
        tatbestandNr: JSON.stringify(arTaten),
      });
    }
  }

  removeTatbestand(index: number, vstv_code: number) {
    console.log('remove  tatbestand');

    // get the confirmation
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Bestätigung',
        message: 'Der ausgewählte Tatbestand wird gelöscht.',
        cancelButton: 'Abbrechen',
        confirmButton: 'Ok',
      },
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        // ok was clicked
        console.log('tatbestand löschen');

        // remove it from the array
        this.transfer_tatbestande.splice(index, 1);

        // remove it from the luecken formarray
        this.tatbestandeFormArray.removeAt(index);

        // remove it from the tatbestandnr array
        let tatbestandNr = this.frmTransfer.get('tatbestandNr')?.value;
        let arTatbestandNr = JSON.parse(tatbestandNr);

        //arTatbestandNr.splice(index, 1); --> not working as index changes
        arTatbestandNr = arTatbestandNr.filter((item) => item !== vstv_code);

        this.frmTransfer.patchValue({
          tatbestandNr: JSON.stringify(arTatbestandNr),
        });
      } else {
        // cancel
      }
    });
  }

  //
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  //

  //
  // --------------------- JOURNAL
  //

  /**
   * get all journal entries for the given aktenzahl
   */
  getAllJournalItems(transfer_id: string, aktenzeichen: string) {
    // the parameters
    const dataIn = {
      tid: transfer_id,
      aktenzeichen: aktenzeichen,
    };

    this.journalService.getItemsByParams(dataIn).subscribe(
      (result: any) => {
        console.log(result);
        this.journalItems = result.data;
      },
      (error) => {
        console.error('Failed to fetch items:', error);
      }
    );
  }

  //
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  //

  //
  // --------------------- FILEUPLOAD
  //

  pondHandleInit() {
    console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any) {
    console.log('A file was added', event);

    // get the name of the upload object
    let elementId = event.pond.id.get();

    console.log(event.pond.files.get());

    // data-maxnumber should store the max numbers of file that are allowed to upload
    //return false;
  }

  // clear the upload pond after all files are processed
  onProcessFiles(event: any) {
    if (event.error) {
      console.error('Error during processing:', event.error);
    } else {
      console.log('All files processed successfully');
      event.pond.removeFiles();

      // get all the images again
      const searchterm = '';
      this.imageService.getImages(searchterm, this.aktenzeichen).subscribe((res: MandatImageTable) => {
        this.mandatImages = res.data;
      });
    }
  }

  pondHandleActivateFile(event: any) {
    console.log('A file was activated', event);
  }

  // reset the filepond
  resetFilePond() {
    if (this.myPond) {
      // Ensure FilePond is fully initialized before attempting to interact with it
      setTimeout(() => this.myPond.pond.removeAllFiles(), 0);
    }
  }

  //
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  //

  //
  // ---------------------
  //

  /**
   *
   * save the transfer with status DRAFT
   */
  saveStatusDraft() {
    console.log('change status draft');

    var newStatus = StatusTransfer.DRAFT;

    this.updateTatbestandNr(); // update also the tatbestand info

    // update to the server
    this.transferService.changeStatus(<TransferMandat>this.frmTransfer.value, newStatus).subscribe(
      (response: any) => {
        //next() callback
        console.log('response received');
        console.log(response);

        // update the form
        this.frmTransfer.patchValue({
          status_id: '0',
        });
        this.cdr.detectChanges();

        // broadcast the changes back to the table-view
        this.broadcastChanges(response);

        this._snackBar.open('Saved successfully', 'Close', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        //
        // show some feedback --> snackbar ?
        //
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  // update the tatbestandnummer with the luecken value informations
  updateTatbestandNr() {
    /*

      wir machen das jetzt nicht mehr so
      sondern anders -> wir speichern das ganze auf der php server seite
      hier wird eine extra info übergeben => tatbestande -> array mit lücken


    // save the tatbestande and the luecken information
    const transformedData = this.transfer_tatbestande.map((item) => ({
      vstv_code: item.vstv_code,
      luecken_values: item.luecken.map((luecke) => luecke.value),
    }));

    this.frmTransfer.patchValue({
      tatbestandNr: JSON.stringify(transformedData),
    });
    */

    // in der neuen version
    // werden die luecken einfach in der konkretisierung gespeichert
    let tatbestandeInfo = this.frmTransfer.get('tatbestande')?.value;

    this.frmTransfer.patchValue({
      konkretisierung: tatbestandeInfo,
    });
  }

  /**
   *
   * manuel check if the TRANSFER is valid for migration to MANDAT
   */
  pruefung_positiv: number = 1;
  pruefprotokoll: string = '';
  errorprotokoll: string = '';
  checkTransfer() {
    // tempory save -> firststart daten
    // bei step change
    console.log('check everything first');

    this.updateTatbestandNr();

    // update to the server
    this.transferService.checkTransfer(<TransferMandat>this.frmTransfer.value).subscribe(
      (response: any) => {
        //next() callback
        console.log('response received');
        console.log(response);

        // get log
        this.pruefung_positiv = response.positiv;
        this.pruefprotokoll = response.log;
        this.errorprotokoll = response.errorLog;

        // was the check positiv
        if (response.positiv == 1) {
          // yes the check was positiv
          // transfer can be migrated to mandat
          // update the form
          this.frmTransfer.patchValue({
            status_id: '1',
          });
          this.cdr.detectChanges();

          // broadcast the changes back to the table-view
          this.broadcastChanges(response.mandat);

          this._snackBar.open('Prüfung positiv', 'Schließen', {
            duration: 1000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        } else {
          // no there was an error
          // update the form
          this.frmTransfer.patchValue({
            status_id: '9',
          });
          this.cdr.detectChanges();

          // broadcast the changes back to the table-view
          this.broadcastChanges(response.mandat);

          this._snackBar.open('Prüfung negativ - Fehler', 'Schließen', {
            duration: 1000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  /**
   *
   * save / move the TRANSFER from Status draft to Status TRANSFER (bereit zur übertragung)
   */
  finalizeTransfer() {
    // tempory save -> firststart daten
    // bei step change
    console.log('check everything first');

    this.updateTatbestandNr();

    // update to the server
    this.transferService.finalizeTransfer(<TransferMandat>this.frmTransfer.value).subscribe(
      (response: any) => {
        //next() callback
        console.log('response received');
        console.log(response);

        // update the form
        this.frmTransfer.patchValue({
          status_id: '1',
        });
        this.cdr.detectChanges();

        // broadcast the changes back to the table-view
        this.broadcastChanges(response);

        this._snackBar.open('Saved successfully', 'Close', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        //
        // show some feedback --> snackbar ?
        //
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  /**
   *
   *
   */
  plateReconResults = [];
  recognizePlate(filename: string) {
    console.log('file' + filename);
    this.imageService.recognizeImage(filename).subscribe((res: any) => {
      console.log(res);

      //check if plate recon was successful
      if (res.results.length == 0) {
        this._snackBar.open('Kennzeichen konnte nicht ermittelt werden.', 'Schließen', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 3000,
          panelClass: 'snackbar-ontop',
        });
      } else {
        this.plateReconResults = res.results;

        //alert('dieser code ist noch alt');
        // er nimmt die : getSelectedResult

        this.plateRecon = res.results[0] ?? [];

        //this.modell = this.plateRecon.model_make[0] ?? [];
        //this.farbe = this.plateRecon.color[0] ?? [];
        //this.richtung = this.plateRecon.orientation[0] ?? [];

        this._snackBar.open('Kennzeichen wurde ermittelt. Ergebnis kann ausgewählt werden.', 'Schließen', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 3000,
          panelClass: 'snackbar-ontop',
        });
      }

      // get the values
    });
  }

  getSelectedResult(): any | string | undefined {
    return this.plateReconResults[this.frmTransfer.get('selection')?.value] || null;
  }

  // when the treffer pulldown was changed
  onTrefferChange(event) {
    console.log('Selected value: ', event.value);

    /*      selection: [],
      colorControl: [],
      makeControl: [],*/
    /*
    this.frmTransfer.get('colorControl')?.setValue("");

    if (this.frmTransfer.get('selection').length > 0) {
      this.selectControl.setValue(this.items[0].value);
    }
    */
  }

  /**
   * input default text fuer abmahnung
   */
  abmahnung() {
    // set the default text
    this.frmTransfer.patchValue({
      internal_notes: 'Abmahnung per VSTG §50(5a)',
    });
  }

  /**
   * speichern der plate erkennung
   *
   *
   */
  savePlateRecon() {
    console.log('save palte recon');

    // get the data from the selected anpr
    //this.modell = this.plateRecon.model_make[0] ?? [];
    //this.farbe = this.plateRecon.color[0] ?? [];
    //this.richtung = this.plateRecon.orientation[0] ?? [];

    let plateRecon = {
      id: this.transfer.id,
      aktenzeichen: this.aktenzeichen,

      land: '' + this.translateLandPipe.transform(this.getSelectedResult().region.code).toUpperCase(),
      bezirk: '' + (this.frmTransfer.get('bezirk')?.value ?? ''),

      kennzeichen: '' + this.getSelectedResult()?.plate?.toUpperCase(),
      plate: '' + this.getSelectedResult()?.plate?.toUpperCase(),

      farbe: this.frmTransfer.get('colorControl')?.value ?? '',
      hersteller: this.frmTransfer.get('makeControl')?.value ?? '',
      notes: '' + (this.frmTransfer.get('internal_notes')?.value ?? ''),
    };

    this.transferService.savePlateRecognition(plateRecon).subscribe({
      next: (response) => {
        // update form with the correct data
        //this.frmTransfer.patchValue(response);

        this.frmTransfer.patchValue({
          land: '' + this.translateLandPipe.transform(this.getSelectedResult().region.code).toUpperCase(),
          nummer: this.getSelectedResult()?.plate?.toUpperCase(),
          hersteller: this.frmTransfer.get('makeControl')?.value ?? '',
          farbe: this.frmTransfer.get('colorControl')?.value ?? '',
        });
        this.cdr.detectChanges(); // update gui
        this.broadcastChanges(response); // update table

        // console.log('Server response:', response);
        this._snackBar.open('Kennzeichen-Erkennung erfolgreich übertragen', 'Schließen', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
      error: (error) => {
        console.error('Error:', error);
        this._snackBar.open('Bei der Speicherung trat ein Fehler auf. #43a', 'Schließen', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
    });
  }

  /**
   * DELETE the TRANSFER
   *
   *
   */
  removeTransfer() {
    // get the confirmation
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Bestätigung',
        message: 'Der ausgewählte Eintrag wird gelöscht.',
        cancelButton: 'Abbrechen',
        confirmButton: 'Ok',
      },
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        // ok was clicked
        console.log(result);

        //
        // in our case -> we do no DELETE
        // we change the status auf UNGULTIG
        //

        var new_status = StatusTransfer.UNGULTIG;

        // update to the server
        this.transferService.changeStatus(<TransferMandat>this.frmTransfer.value, new_status).subscribe(
          (response: any) => {
            //next() callback
            console.log('response received');
            console.log(response);

            // update the form
            this.frmTransfer.patchValue({
              status_id: '3',
            });
            this.cdr.detectChanges();
            console.log(this.frmTransfer.value);

            // broadcast the changes back to the table-view
            this.broadcastChanges(response);

            this._snackBar.open('Datensatz erfolgreich gelöscht.', 'Close', {
              duration: 1000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });

            //
            // show some feedback --> snackbar ?
            //
          },
          (error) => {
            //error() callback
            console.error('Request failed with error');
            //this.errorMessage = error;
          },
          () => {
            //complete() callback
            console.log('Request completed'); //This is actually not needed
          }
        );

        //
        this.closeOffcanvas();
      } else {
        // cancel
      }
    });
  }

  /**
   *
   * open an image in a new window on click
   */
  openImageInNewWindow(imageSrc: string): void {
    const newWindow = window.open('', '_blank');
    // {{ imageStorage }}img?f={{
    console.log(imageSrc);
    if (newWindow) {
      newWindow.document.write(
        `<html><head><title>Image</title></head><body><img src="${this.imageStorage}img?f=${imageSrc}" ></body></html>`
      );
      newWindow.document.close(); // Close document writing stream
    }
  }

  /**
   * close the offcanvas
   */
  closeOffcanvas() {
    const offcanvasElement = document.getElementById('staticBackdrop');
    const offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
    if (offcanvasInstance) {
      offcanvasInstance.hide();
    }
  }

  //
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  //

  exifDataMap = new Map<string, any>();
  loadExifImageData() {
    // load data via service
    this.mandatImages.forEach((img) => {
      this.imageService.getExifImageData(img.filename).subscribe((res: any) => {
        console.log(res);

        this.exifDataMap.set(img.filename, res.exif);
      });
    });
  }

  // get theexifimagedata
  getExifData(filename: string): string {
    try {
      return this.exifDataMap.get(filename);
    } catch (error) {
      return '';
    }
  }

  // set the tatzeit form the image
  fetchTatzeitFromImage() {
    // fetch from the 1st image

    let imageZeit = this.getExifData(this.mandatImages[0].filename);
    // now set the image tatzeit information
    console.log(imageZeit);

    const [datePart, timePart] = imageZeit.split(' ');
    const [year, month, day] = datePart.split(':').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    const dateObject = new Date(year, month - 1, day, hours, minutes, seconds);

    const dateOnly = this.datePipe.transform(dateObject, 'dd.MM.yyyy');
    const timeOnly = this.datePipe.transform(dateObject, 'HH:mm');
    const fullDateTime = `${dateOnly} ${timeOnly}`;

    // update the tatzeit
    this.frmTransfer.patchValue({
      tatzeit: fullDateTime,
      tat_zeit: timeOnly,
      tat_datum: dateOnly,
    });
  }

  //
  // --------------------- SAVE
  //

  /**
   *
   *
   * @return {*}
   * @memberof TransferEditComponent
   */
  saveChanges() {
    console.log('submit');

    this.submitted = true;

    console.log('------- PRINT INVALID CONTROLS ---');
    for (let el in this.frmTransfer.controls) {
      if (this.frmTransfer.controls[el].errors) {
        console.log(el);
      }
    }

    // display form values on success
    this.submitted = true;
    if (this.frmTransfer.valid) {
      // console.table(this.firstVisitorForm.value);
    }

    this.frmTransfer.markAsPristine();
    this.isLoading = false;

    this.updateTatbestandNr(); // update also the tatbestand info

    console.log(this.frmTransfer.value);

    // update to the server
    this.transferService.updateTransfer(<TransferMandat>this.frmTransfer.value).subscribe(
      (response: any) => {
        //next() callback
        console.log('response received');
        console.log(response);

        // broadcast the changes back to the table-view
        this.broadcastChanges(response);

        this._snackBar.open('Saved successfully', 'Close', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        //
        // show some feedback --> snackbar ?
        //
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  /*
  saveChanges() {
    console.log('submit');
    this.submitted = true;

    console.log('------- PRINT INVALID CONTROLS ---');
    for (let el in this.frmTransfer.controls) {
      if (this.frmTransfer.controls[el].errors) {
        console.log(el);
      }
    }

    // stop here if form is invalid
    // no validation for admin
    if (this._isAdmin == '1') {
      // no check
    } else {
      if (this.frmTransfer.invalid) {
        return;
        // we do not care if it is invalid
        // because we do a cache save
        //
      } else {
        // first-start can set to 1
        // and then save it agin
        //  (click)="submitFirststart()"
        this.frmTransfer.controls['firststart_done'].setValue(1);
      }
    }

    // display form values on success
    this.submitted = true;
    if (this.frmTransfer.valid) {
      // console.table(this.frmMandat.value);
    }

    const access_token = this.authQuery.access_token;
    const userid = this._uid; //this.authQuery.userid;
    const fid = this._fid; //this.authQuery.fid;
    const role = this._role; //this.authQuery.role;

    //this.frmMandat.controls['access_token'].setValue(access_token);
    this.frmTransfer.controls['uid'].setValue(userid);
    this.frmTransfer.controls['fid'].setValue(fid);
    this.frmTransfer.controls['role'].setValue(role);

    const firststart$ = this.authService.firststart(this.frmTransfer.value);
    firststart$
      .pipe(
        finalize(() => {
          this.frmTransfer.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data) => {
          if (data.status === 401) {
            console.log('login error');
            this._snackBar.open('Error: Something went wrong.', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
              // panelClass: ['mat-toolbar', 'mat-primary'],
            });
          } else {
            log.debug(`${data.name} successfully firststart done`);

            this.journalService.log('FirstStart erfolgreich abgeschlossen.   --------------------------------------');

            // get firststart done
            let firststart_done = +data.firststart_done;

            // now route the user into the eplus system (sso )
            if (this._isAdmin == '1') {
              // admin just show that it was saved

              this._snackBar.open('Successfully saved.', 'Close', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 3000,
                // panelClass: ['mat-toolbar', 'mat-primary'],
              });
            } else {
              // redirect to old system -belogin
            }
          }
        },
        (error) => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }*/
}
