import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateColor',
})
export class TranslateColorPipe implements PipeTransform {
  private colorMap = {
    black: 'schwarz',
    blue: 'blau',
    brown: 'braun',
    green: 'grün',
    red: 'rot',
    silver: 'silber',
    white: 'weiss',
    yellow: 'gelb',
    unknown: 'unbekannt',
  };

  transform(value: string): string {
    // Check if the value exists in the map; if not, return 'unknown'
    return this.colorMap[value.toLowerCase()] || value;
  }
}
