import { Component, OnInit, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { Luecke, Tatbestand } from '@app/services/stammdaten.service';

@Component({
  selector: 'app-dialog-tatbestand',
  templateUrl: './dialog-tatbestand.component.html',
  styleUrls: ['./dialog-tatbestand.component.css'],
})
export class TatbestandDialogComponent implements OnInit {
  tatbestand: Tatbestand;
  frmTatbestand: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<TatbestandDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TatbestandDialogComponent,
    private _fb: FormBuilder
  ) {
    this.tatbestand = data.tatbestand;

    this.frmTatbestand = this._fb.group({
      id: [0],
      vstv_code: [''],
      paragraph: [''],
      reihung: [0],
      strafe: [0],
      short: [''],
      drucktext: [''],
      usage: [0],
      luecken: this._fb.array([]),
      freitext: [''],
    });
  }

  ngOnInit() {
    // fill the form with the data
    this.frmTatbestand.patchValue({
      id: this.tatbestand.id,
      vstv_code: this.tatbestand.vstv_code,
      paragraph: this.tatbestand.paragraph,
      reihung: this.tatbestand.reihung,
      strafe: this.tatbestand.strafe,
      short: this.tatbestand.short,
      drucktext: this.tatbestand.drucktext,
      usage: this.tatbestand.usage,
      freitext: this.tatbestand.freitext,
    });

    // add all the luecken to the form
    this.tatbestand.luecken.forEach((luecke) => {
      this.addFormLuecke(luecke);
    });
  }

  /**
   * add a section from the json to the form as form values
   * @param section
   */
  addFormLuecke(luecke: Luecke) {
    const frmLuecke = this._fb.group({
      luecke: [luecke.luecke], // fortlaufende nummer
      titel: [luecke.titel],
      info: [luecke.info],
      default: [luecke.default],
      value: [luecke.value],
    });

    this.luecken.push(frmLuecke);
  }

  get luecken(): FormArray {
    return this.frmTatbestand.get('luecken') as FormArray;
  }

  //
  // -------------------------------------------
  //

  addNewLuecke() {
    //console.log('add section');
    let newLuecke: Luecke = {
      luecke: this.tatbestand.luecken.length + 1,
      titel: '',
      info: '',
      default: '',
      value: '',
    };
    this.tatbestand.luecken.push(newLuecke);

    this.addFormLuecke(newLuecke);
  }

  // get the form-array
  get lueckenFormArray(): FormArray {
    return this.frmTatbestand.get('luecken') as FormArray;
  }

  // remove the luecke
  // a) from the form
  // b) from the data-structure
  removeLuecke(index: number) {
    this.tatbestand = this.frmTatbestand.value;
    console.log(this.tatbestand);

    // remove it from the form

    // remove it from the luecken formarray
    this.lueckenFormArray.removeAt(index);

    // remove it from the tatbestandnr array
    this.tatbestand.luecken.splice(index, 1);

    console.log('rm done');
  }
  /*
  removeLuecke(luecke: Luecke) {
    //console.log('remove section');

    // get the confirmation
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Removal',
        message: 'By clicking on OK the item will be deleted. This action cannot be undone.',
        cancelButton: 'Cancel',
        confirmButton: 'Ok',
      },
      width: '450px',
      height: '200px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        // ok was clicked
        // so do whatever
        // remove from strategy object
        this.strategy.sections.splice(this.strategy.sections.indexOf(section), 1);

        // remove from form-object >> this gets saved into db
        const index = this.sections.value.findIndex((sec) => sec.secid === section.secid);
        if (index > -1) {
          console.log('found remove it');
          let sections = <FormArray>this.strategyForm.get('sections');
          this.sections.removeAt(index);
          this.cdr.detectChanges();
        }
      }
    });
  }
  */

  //
  // ---------------------- dialog functions
  //

  onConfirm(): void {
    // Close the dialog, return true
    this.tatbestand = this.frmTatbestand.value;
    this.dialogRef.close({ event: 'save', data: this.frmTatbestand.value });
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.

export class ConfirmDialogModel {
  constructor(public title: string, public message: string, public cancelButton: string, public confirmButton: string) {}
}
 */
