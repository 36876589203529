<div class="d-flex">
  <div class="row setting-card-container mx-auto">
    <mat-card class="mat-elevation-z4 w-100">
      <mat-card-title>Einstellungen</mat-card-title>
      <mat-card-content>
        <mat-tab-group>
          <mat-tab label="Tage">
            <div class="container-fluid p-1">
              <div class="row w-100 m-0">
                <div class="col-sm-12 col-md-4 p-0">
                  <h2>Tage</h2>
                  <small
                    >Legen Sie fest, nach wie vielen Tagen ein erfasstes Mandat als überfällig betrachtet werden
                    soll.</small
                  >
                </div>
                <div class="col-sm-12 col-md-7 offset-md-1 p-1">
                  <form class="mt-3" name="frmVSTVSettings" [formGroup]="frmVSettings">
                    <table class="table table-striped table-sm">
                      <tr>
                        <td class="bold">Anzahl Tage:</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="TAGE_ZUR_ANZEIGE"
                            formControlName="TAGE_ZUR_ANZEIGE"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="" />
                        </td>
                      </tr>
                    </table>
                    <button mat-raised-button (click)="saveSettings()" color="primary">Speichern</button>
                  </form>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="VSTV">
            <div class="container-fluid p-1">
              <div class="row w-100 m-0">
                <div class="col-sm-12 col-md-4 p-0">
                  <h2>VSTV Export</h2>
                  <small>Informationen zum VSTV-Export</small>
                </div>
                <div class="col-sm-12 col-md-7 offset-md-1 p-1">
                  <form class="mt-3" name="frmVSTVSettings" [formGroup]="frmVSettings">
                    <table class="table table-striped table-sm">
                      <tr>
                        <td class="bold">Empfanger:</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="EMPFANGER"
                            formControlName="EMPFANGER"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="" />
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">Sender:</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="SENDER"
                            formControlName="SENDER"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="" />
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">Gemeinde:</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="GEMEINDE"
                            formControlName="GEMEINDE"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="" />
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">Tatortart:</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="TATORTART"
                            formControlName="TATORTART"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="" />
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">Landeskennzeichen:</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="LANDESKENNZEICHEN"
                            formControlName="LANDESKENNZEICHEN"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="" />
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">Wahrnehmung:</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="WAHRNEHMUNG"
                            formControlName="WAHRNEHMUNG"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="" />
                        </td>
                      </tr>

                      <tr>
                        <td class="bold">Unterzeichner:</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="UNTERZEICHNER"
                            formControlName="UNTERZEICHNER"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="" />
                        </td>
                      </tr>

                      <tr>
                        <td class="bold">Bearbeiter:</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            id="BEARBEITER"
                            formControlName="BEARBEITER"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="" />
                        </td>
                      </tr>
                    </table>
                    <button mat-raised-button (click)="saveSettings()" color="primary">Speichern</button>
                  </form>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="VSTV Freitexte">
            <div class="container-fluid p-1">
              <div class="row w-100 m-0">
                <div class="col-sm-12 col-md-4 p-0">
                  <h2>VSTV Freitexte'</h2>
                  <small
                    >Sie können Texte definieren, welche als Textbaustein vor und nach einem Tatbestand-Freitext
                    zusätzlich übermittelt werden.</small
                  >
                </div>
                <div class="col-sm-12 col-md-7 offset-md-1 p-1">
                  <form class="mt-3" name="frmVSTVZusatztexte" [formGroup]="frmVSettings">
                    <table class="table table-striped table-sm">
                      <tr>
                        <td class="bold">Vor Textbaustein</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td collspan="2">
                          <textarea
                            type="text"
                            class="form-control"
                            style="min-height: 120px"
                            id="VOR_TEXTBAUSTEIN"
                            formControlName="VOR_TEXTBAUSTEIN"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""></textarea>
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">Nach Textbaustein</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td collspan="2">
                          <textarea
                            type="text"
                            class="form-control"
                            style="min-height: 120px"
                            id="NACH_TEXTBAUSTEIN"
                            formControlName="NACH_TEXTBAUSTEIN"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""></textarea>
                        </td>
                      </tr>
                    </table>
                    <button mat-raised-button (click)="saveSettings()" color="primary">Speichern</button>
                  </form>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
</div>
