import { env } from './.env';

export const environment = {
  production: true,
  version: env['npm_package_version'],
  serverUrl: 'https://api-toc.parkraumplus.at/api/v5',
  //webstorage: 'https://api-toc.parkraumplus.at/api/storage/',
  defaultLanguage: 'de-DE',
  supportedLanguages: ['de-DE'],
  api_key: 'parkraumplus.at-12394w23x.dfsa332wqadfdsaf',

  // -------------
  feature_ZAHLUNGSABGLEICH: false,
};
