import {
  Component,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit,
  OnChanges,
  HostListener,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';

import { ComponentFactoryResolver, Injector, OnDestroy, SimpleChange, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, of, toArray } from 'rxjs';
import { merge, Observable, of as observableOf, pipe } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { parse, isBefore } from 'date-fns'; // Import date-fns functions

import { PhutureIavatarComponent } from '../@components/phuture-iavatar/phuture-iavatar.component';

import { AuthQuery } from '@app/auth/auth.query';

import { JournalService, Journal, MailService, Mail } from '@core';
import { Patch } from 'immer';

import { environment } from '../../environments/environment';
import { Logger } from '@core';

import { ConfirmDialogComponent } from '../@shared/dialog/confirm-dialog/confirm-dialog.component';

import { MandatService, Mandat, MandatTable } from '../services/mandat.service';
import { DataChangeMandateService } from './changes-mandat.service';

declare var $: any;

export interface EmpFilter {
  name: string;
  options: string[];
  defaultValue: string;
}

export interface filterOption {
  name: string;
  value: string;
  isdefault: boolean;
}

@Component({
  templateUrl: './mandat.component.html',
  styleUrls: [
    './mandat.component.scss',
    //'../../assets/dk/css/libs.bundle.css',
    //'../../assets/dk/css/theme.bundle.css'
  ],
})
export class MandatComponent implements OnInit {
  displayedColumns: string[] = [
    'Md_PrimKey',
    'Md_Status',
    'Md_BelegNr',

    //'createdate',
    //'createtime',

    'Md_Datum',
    'Md_ZeitVon',

    'Md_KzLand',
    //'Md_KzBezirk',
    'Md_KzNummer',

    'Md_Hersteller',

    'Md_Betrag',
    'Md_DienstNr',
    'actions',

    /*
    Aktenzeichen
    Status
    Land
    Bezirk
    Nummer
    Hersteller
    Farbe
    Datum
    Tatzeit
    Strasse
    Hausnummer
    Tatbestand
    DienstNr
    Konkretisierung
    Betrag
    create_date
    create_time
    person
    printed
    printdate
    printcount
    transferred
    transferdate
    longitude
    latitude
    info1
    info2
    info3
    fahrzeugart
    zone
    ticketnr
    ticket_zeit
    notees
    internal_notes
    vstv_export
    vstv_packet
    vstv_ignore
    zusatz1
    zusatz2
    strafe
    dienstnr_stvo
     */
  ];

  //imageStorage = environment.webstorage + '/';
  imageStorage = environment.serverUrl + '/'; // images gehen über api

  visitorTable: MandatTable;

  totalItems: number;
  //visitors: Visitor[];

  //stats: VisitorStats;
  /*
  stat_nbr_visitors: Observable<number>;
  stat_nbr_exhibitors: Observable<number>;
  stat_nbr_appointments: Observable<number>;
  stat_nbr_registered: Observable<number>;
  stat_nbr_approved: Observable<number>;
  stat_nbr_fs_done: Observable<number>;
  */
  dataSubject = new BehaviorSubject<Mandat[]>([]);
  visitors$: Observable<Mandat[]>;
  dataSource = new MatTableDataSource<Mandat>();

  isLoading = true;

  searchTerm$ = new BehaviorSubject<string>('');

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSizes = [50, 100, 200, 500];

  searchValue: string;
  //searchKeywordFilter = new FormControl();

  stati: string[] = [
    'Alle',
    'Erfasst',
    'Teil-Erfasst',
    'Überfällig',
    'Bezahlt',
    'Angezeigt',
    'Anzeige / Bezahlt',
    'Storno',
    'Error',
    'Ausgenommen',
  ];
  //streets: string[] = ['Alle', 'Flughafen Alee', 'Zufahrt ABC'];

  empFilters: EmpFilter[] = [];
  defaultValue = 'Alle';
  filterDictionary = new Map<string, string>();
  //dataSourceFilters = new MatTableDataSource();
  filters$ = new BehaviorSubject<string>('');

  //patch sizes
  //patchsize: PatchSize[] = [{ viewValue: '20' }, { viewValue: '50' }, { viewValue: '100' }, { viewValue: '200' }, { viewValue: '500' }];

  // dilaog und sidenav
  openSidebar = false;
  @ViewChild('sidenav') sidenav;
  selectedVisitor: Mandat;
  @ViewChild('visitorContainer', { read: ViewContainerRef }) quizContainer: ViewContainerRef;
  current_mandat: Mandat;
  private subscription: Subscription; // changes in the eg sidebar

  constructor(
    private mandatService: MandatService,
    private cfr: ComponentFactoryResolver,
    private _snackBar: MatSnackBar,
    private _fb: FormBuilder,
    private journalService: JournalService,
    public dialog: MatDialog,
    public dataChangeMandateService: DataChangeMandateService,

    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private authQuery: AuthQuery
  ) {}

  ngOnInit() {
    //this.empFilters.push({ name: 'Land', options: this.countries, defaultValue: this.defaultValue });
    this.empFilters.push({ name: 'Status', options: this.stati, defaultValue: this.defaultValue });
    //this.empFilters.push({ name: 'Straße', options: this.streets, defaultValue: this.defaultValue });

    // subscribe to the sidebar changes
    this.subscription = this.dataChangeMandateService.dataChanges$.subscribe((data) => {
      if (data) {
        // Handle the data, e.g., update the list or form
        console.log('change recieved-----------');
        //console.log(data);

        // map the data ---> we got something
        const mappedData = {
          //...data,
          Md_PrimKey: data.id,
          Md_Status: data.status_id,
          Md_BelegNr: data.aktenzeichen,
        };

        // update the specific transfer
        this.updateMandat(mappedData);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe(); // unsubscibe to the subscriptions
  }

  /**
   * update the datasource with the given data from the subscription
   */
  updateMandat(updatedMandat: any) {
    const data = this.dataSubject.getValue();
    const index = data.findIndex((mandat) => +mandat.Md_PrimKey === +updatedMandat.Md_PrimKey);
    if (index > -1) {
      data[index] = updatedMandat;
      this.dataSubject.next(data); // Update the BehaviorSubject
      this.dataSource.data = data; // Update the MatTableDataSource
    }
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;

    merge(
      this.filters$,
      this.searchTerm$.pipe(debounceTime(400), distinctUntilChanged()),
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(
        startWith({}),
        switchMap((term) => {
          this.isLoading = true;
          //var sTerm = term && typeof term == 'string' ? term.toString() : '';
          return this.getTableData$(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            //sTerm.trim(),
            this.searchTerm$.getValue(),
            this.filters$.getValue()
          ).pipe(catchError(() => observableOf(null)));
        }),
        map((visTable) => {
          if (visTable == null) return [];
          this.totalItems = visTable.total;
          this.isLoading = false;

          //this.stats = visTable.stats;

          return visTable.data.map((data) => {
            return <Mandat>{
              ...data,
              /*
              decharge_resp_civile: data.decharge_resp_civile == -1 ? 1 : 0,
              estimate: data.estimate == '-1' ? '1' : 0,
              garage_day1: data.garage_day1 == -1 ? 1 : 0,
              garage_day2: data.garage_day2 == -1 ? 1 : 0,
              lunch_day1: data.lunch_day1 == -1 ? 1 : 0,
              lunch_day2: data.lunch_day2 == -1 ? 1 : 0,
              attendance_event: data.attendance_event == -1 ? 1 : 0,
              hotel_stay_night: data.hotel_stay_night == -1 ? 1 : 0,
              transfer_arrival: data.transfer_arrival == -1 ? 1 : 0,
              transfer_return: data.transfer_return == -1 ? 1 : 0,
              flight_booked: data.flight_booked == -1 ? 1 : 0,
              */
            };
          });
          //return visTable.data;
        })
      )
      .subscribe((mandate: Mandat[]) => {
        this.visitors$ = of(mandate);
        this.dataSource = new MatTableDataSource(mandate);

        this.dataSubject.next(mandate);

        //reset paginator
        //this.paginator.pageIndex = 1;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTerm$.next(filterValue);
  }

  applyEmpFilter(ob: MatSelectChange, empfilter: EmpFilter) {
    this.filterDictionary.set(empfilter.name, ob.value);
    var jsonString = JSON.stringify(Array.from(this.filterDictionary.entries()));
    this.filters$.next(jsonString);
  }

  getTableData$(pageNumber: Number, pageSize: Number, sort: string, order, searchTerm: string, filtersArgs: string) {
    // get data from server
    return this.mandatService.getMandate(pageNumber, pageSize, sort, order, searchTerm, filtersArgs);
  }

  /**
   * check for overdue mandate -> set status Überzogen
   */
  checkOverdue() {
    console.log('check overdue');

    // BUG BUG BUG: this should come from the settings
    const numberOfDaysToCheck = 7;

    // get current - todays date
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - numberOfDaysToCheck);

    // iterate all rows in the table
    // BUG BUG BUG: this solution does not work --> because of pagination !!!
    this.dataSource.data.forEach((row) => {
      const rowDate = parse(row.createdate, 'dd.MM.yyyy', new Date());

      console.log('check date' + row.Md_Datum);

      // if the date is before the given date -> do something
      if (isBefore(rowDate, currentDate)) {
        console.log(`Row with id ${row.Md_PrimKey} has a date older than ${numberOfDaysToCheck} days.`);

        // set the status to U -> just for show
        row.Md_Status = 'U';
      }
    });

    //
    // change the status also in the database
    //
    this.doMandate2VSTV();
  }

  /**
   * doMandate2VSTV -> als überfällig markieren (status-change)
   *    > alle überfälligen sind bereit zur VSTV
   */

  doMandate2VSTV() {
    console.log('do transfer');

    // update to the server
    this.mandatService.doMandate2VSTV().subscribe(
      (response: any) => {
        //next() callback
        console.log('response received');
        //console.log(response);

        // broadcast the changes back to the table-view
        //this.broadcastChanges(response);

        this._snackBar.open('Mandate erfolgreich geprüft.', 'Close', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        //
        // show some feedback --> snackbar ?
        //
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  showPageDetail(mandat_id: number) {
    console.log('load page: ' + mandat_id);

    // get the quiz page with given uuid
    let mandat: Mandat;
    mandat = <Mandat>this.dataSource.filteredData.find((element: Mandat) => element.Md_PrimKey == mandat_id);
    this.current_mandat = mandat;

    // load the component into the modal slide window
    //this.router.navigate(['/home/edit', visitor_id]);
    this.router.navigate([
      '/mandate/edit',
      { id: mandat_id, _uid: mandat.Md_PrimKey, _fid: mandat_id, _role: 3, _country: mandat.Md_Kennzeichen, ia: 1 },
    ]);
    //, {my_object: JSON.stringify(this.Obj)}]));

    //this.showVisitor(visitor);
    //this.sidenav.open();
    //const mySidebar = <any>document.querySelectorAll('.offcanvas');
    //mySidebar.show();

    $('#staticBackdrop').offcanvas('show');
  }

  showVisitor(visitor: Mandat) {
    console.log('show the page');

    this.selectedVisitor = visitor;

    // load visitor - firststart
    //this.llStartPageEdit(visitor);
  }

  sidebarOnClose() {
    console.log('close');
    this.openSidebar = false;
  }

  /**
   *
   *
   * @type {string[]}
   * @memberof DashboardComponent
   */
  additionalColumns: string[] = [
    'generalisttour',
    'specialisttour',
    'tourownretailing',
    'tourretailingtravelagencies',
    'touroperatormember',
    'travel',
    'incentive',
    'inernationalincoming',
    'coach',
    'carrental',
    'hotelbookingcallcenter',
    'aircarrier',
    'seacarrier',
    'roadcarrier',
    'railcarrier',
    'numberemployees',
    'volumegroups',
    'volumeindividuals',
    'numberbrochures',
    'onlinebooking',
  ];
  showAdditionalColumns() {
    this.displayedColumns = this.displayedColumns.concat(this.additionalColumns);
    console.log(this.displayedColumns);
  }

  //
  // -------------------------------
}
