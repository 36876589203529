import { Injectable, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ID } from '@datorama/akita';

import { environment } from '@env/environment';

import { IAppDataModel, YearData } from './appdata.model';
import { AppDataStore } from './appdata.store';
import { AuthQuery } from '@app/auth/auth.query';
//import { AuthDataService } from '@app/auth/auth.service';

import { tap, map } from 'rxjs/operators';

import { Observable, EMPTY, throwError, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { produce } from 'immer';
import { JournalService } from '@app/@core';

export interface AppDataVersion {
  id: number;
  modified: string;
  version: number;
  status: string;
  appdata: any;
}

const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  API_SLUG = '/appdata';

  constructor(
    private http: HttpClient,
    private appdataStore: AppDataStore,
    private authQuery: AuthQuery,
    //private authService: AuthService,
    private journalService: JournalService
  ) {}

  /*
    get() {
      of(data).subscribe(entities => {
        this.appdataStore.set(entities);
      });
    }
  */

  setActive(id: ID) {
    this.appdataStore.setActive(id);
  }

  updateActive(data: IAppDataModel) {
    this.appdataStore.updateActive(data);
  }

  updateSomething() {
    this.appdataStore.update(this.authQuery.appid, (entity) => {
      return produce(entity, (entityDraft) => {
        entityDraft.version = 3;
      });
    });
  }

  updateFirststart(firstdata: boolean) {
    // update rest db
    let send = {
      uid: this.authQuery.userid,
      fstart: firstdata,
    };

    // save the data to the server
    this.http.post<any>(`${environment.serverUrl}/api/v0/firststart`, JSON.stringify(send), HttpUploadOptions);
    //.pipe(catchError(this.handleError));

    const xhr = new XMLHttpRequest();

    // listen for `load` event
    xhr.onload = () => {
      // print JSON response
      if (xhr.status >= 200 && xhr.status < 300) {
        // parse JSON
        const response = JSON.parse(xhr.responseText);
        //console.log(response);
      }
    };

    // open request
    xhr.open('POST', `${environment.serverUrl}/api/v0/firststart`);
    // set `Content-Type` header
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    //xhr.setRequestHeader('Accept', "application/json");
    xhr.setRequestHeader('Access-Control-Allow-Headers', '*');

    // token
    xhr.setRequestHeader('Authorization', `Bearer ${this.authQuery.access_token}`);

    // send rquest with JSON payload
    xhr.send(JSON.stringify(send));
    //console.log('done');
  }

  updateAppData(data: IAppDataModel) {
    // update akita
    this.appdataStore.update(this.authQuery.appid, (entity) => data);

    // update rest db
    let send = {
      id: data.id,
      mydata: JSON.stringify(data),
      token: '12987361235283447283261',
    };

    console.log('-------------------------------------------------appdata.service -updateAppData');

    // save the data to the server
    //this.http.post<any>(`${environment.serverUrl}/api/v0/appdata`, JSON.stringify(send), HttpUploadOptions);
    //.pipe(catchError(this.handleError));

    //
    // second version - as the 1st did not work properly
    //

    const xhr = new XMLHttpRequest();

    // listen for `load` event
    xhr.onload = () => {
      // print JSON response
      if (xhr.status >= 200 && xhr.status < 300) {
        // parse JSON
        const response = JSON.parse(xhr.responseText);
        //console.log(response);
      }
    };

    // open request
    xhr.open('POST', `${environment.serverUrl}/api/v0/appdata`);

    // set `Content-Type` header
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    //xhr.setRequestHeader('Accept', "application/json");
    xhr.setRequestHeader('Access-Control-Allow-Headers', '*');

    // token
    xhr.setRequestHeader('Authorization', `Bearer ${this.authQuery.access_token}`);

    // send rquest with JSON payload
    xhr.send(JSON.stringify(send));
  }

  /**
 *
 * @param data getData(): Observable<any> {
        return this.http.get('https://samples.openweathermap.org/data/2.5/history/city?q=Warren,OH&appid=b6907d289e10d714a6e88b30761fae22')
        .pipe(map(result => result));
    }
 * @returns
 */

  /*
  checkAppDataVersion() {
    this.getAppDataVersion().pipe(
      map((data) => {
        //console.log('current version: ' + this.authQuery.version + '/' + data.version);
        if (this.authQuery.version < data.version) {
          //console.log('new version');
          // update local appdata
          if (data.appdata != '') {
            // set new

            console.log('set new version ' + data.version);
            this.appdataStore.upsert(data.appdata.id, <IAppDataModel>data.appdata);
            //this.authService.updateVersion(data.version); ==>> circula dependency

            this.journalService.log('Datenbank wurde synchronisiert. Version: ' + data.version);
          }
        }

        return true;
      }),
      catchError((error) => of(true))
    );
  }
*/
  getAppDataVersion(): Observable<AppDataVersion> {
    // update rest db
    let send = {
      id: this.authQuery.appid,
      uid: this.authQuery.userid,
      version: this.authQuery.version,
      //mydata: JSON.stringify(data),
      token: '12987361235283447283261',
    };

    /*
    let response = {
      id: 0,
      version: 0,
      modified: '',
    };
    */

    return this.http.post<AppDataVersion>(
      `${environment.serverUrl}/api/v0/appdata-version`,
      JSON.stringify(send),
      HttpUploadOptions
    );
    //.pipe(retry(1), catchError(this.handleError));

    /*
      .pipe(
        tap(
          (fred) => {
            debugger;
            console.log('Mario');
            console.log(fred);
          },
          (error) => {
            console.log('was ist lost');
            this.handleError(error);
          }
        )
      );
      */
  }

  /*
  abcgetAppDataVersion(data: IAppDataModel): Observable<AppDataVersion> {
    // update rest db
    let send = {
      id: data.id,
      uid: this.authQuery.userid,
      //mydata: JSON.stringify(data),
      token: '12987361235283447283261',
    };

    let response = {
      id: 0,
      version: 0,
      modified: '',
    };

    return this.http.post<any>(
      `${environment.serverUrl}/api/v0/appdata-version`,
      JSON.stringify(send),
      HttpUploadOptions
    );
    //.pipe(map((result) => result));
    /*
      .pipe(
        map((result) => {
          console.log(result);
          return result;
        })
      );
  }

  geabtfrederikeAppDataVersion(data: IAppDataModel) {
    // update akita
    //this.appdataStore.update(this.authQuery.appid, (entity) => data);

    // update rest db
    let send = {
      id: data.id,
      uid: this.authQuery.userid,
      //mydata: JSON.stringify(data),
      token: '12987361235283447283261',
    };

    let response = {
      id: 0,
      version: 0,
      modified: '',
    };

    // save the data to the server
    this.http
      .post<any>(`${environment.serverUrl}/api/v0/appdata-version`, JSON.stringify(send), HttpUploadOptions)
      .subscribe(
        (res) => {
          return <AppDataVersion>res;
        },
        (err: HttpErrorResponse) => {
          console.log(err.message);
          return <AppDataVersion>response;
        }
      );
  }
*/
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }


  updateData(id: any, path: string, newValue: string) {
    /*
            let copyOfItems = state.items.map( obj => ({
              ...obj,
              description: "newDescription"
            }));

            return {
              ...state,
              items: copyOfItems
            }
            */
    /*
    this.appdataStore.update(id,
      state => {
        path: newValue
      }
    );

    this.appdataStore.update(id,
      state => {
        ansprechpartner: 'mario und sylvia'
      }
    );
    */
    //this.appdataStore.update(id, { 'ansprechpartner': 'mario und sylvia' });
    /*
    this.appdataStore.update(state => ({
      path: newValue
    }));
    */
  }

  /*
    getAppDatas(): void {
      this.http
        .get<IAppDataModel[]>(`${environment.serverUrl}`)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            alert(error.message);
            return throwError(error.message);
          })
        )
        .subscribe(data => this.appdataStore.set(data));
    }

    getAppData(id: string): void {
      this.http
        .get<IAppDataModel>(`${environment.serverUrl}${id}`)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            alert(error.message);
            return throwError(error.message);
          })
        )
        .subscribe(data => this.appdataStore.add(data));
    }

    addAppData(appdata: IAppDataModel): void {
      this.http
        .post<IAppDataModel>(`${environment.serverUrl}`, appdata)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            alert(error.message);
            return throwError(error.message);
          })
        )
        .subscribe(data => this.appdataStore.add(data));
    }

    updateAppData(appdata: IAppDataModel): void {
      this.http
        .put<IAppDataModel>(`${environment.serverUrl}${appdata.id}`, appdata)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            alert(error.message);
            return throwError(error.message);
          })
        )
        .subscribe(data => this.appdataStore.update(<number>appdata.id, { ...data }));
    }

    removeAppData(id: ID): void {
      this.http
        .delete<any>(`${environment.serverUrl}${id}`)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            alert(error.message);
            return throwError(error.message);
          })
        )
        .subscribe(() => this.appdataStore.remove());
    }
    */
}
