import { AfterViewInit, Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { ComponentFactoryResolver, Injector, SimpleChange, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, of, toArray } from 'rxjs';
import { merge, Observable, of as observableOf, pipe } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { TransferMandatService, TransferMandat, TransferMandatTable } from '../services/transfer.service';

import { PhutureIavatarComponent } from '../@components/phuture-iavatar/phuture-iavatar.component';

import { environment } from './../../environments/environment';
import { Logger } from '@core';

import { AuthQuery } from '@app/auth/auth.query';

import { JournalService, MailService, Mail } from '@core';
import { DataChangeService } from './changes-transfer.service';

declare var $: any;

export interface EmpFilter {
  name: string;
  options: string[];
  defaultValue: string;
}

export interface filterOption {
  name: string;
  value: string;
  isdefault: boolean;
}

@Component({
  templateUrl: './transfer.component.html',
  styleUrls: [
    './transfer.component.scss',
    //'../../assets/dk/css/libs.bundle.css',
    //'../../assets/dk/css/theme.bundle.css'
  ],
})
export class TransferComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'transferred',
    'aktenzeichen',
    'kzland',
    //'kzbezirk',
    'kznummer',
    //'plate',
    'street',
    //'createdate',
    //'createtime',
    //'printcount',
    //'tatzeit',
    'tat_datum',
    'tat_zeit',

    //'tatbestnr',
    'person',

    'checked',

    'actions',

    /*
    id
    aktenzeichen
    plate
    kzland
    kzbezirk
    kznummer
    street
    streetnbr
    hersteller
    farbe
    createdate
    createtime
    dienstnr_id
    person
    tatbestnr
    konkretisierung
    printed
    printdate
    printcount
    mandat_id
    transferred
    transferdate
    notes
    info1
    info2
    info3
    longitude
    lattitude
    fahrzeugart
    zone
    ticketnr
    ticket_zeit
    zusatz1
    zusatz2
    strafe
    dienstnr_stvo
    transfer_db

    tatzeit
    tat_datum
    tat_zeit

     */
  ];

  current_aktenzeichen: string = ''; /// das aktuelle aktenzeichen (im sidenav)

  imageStorage = environment.serverUrl + '/'; // images gehen über api

  visitorTable: TransferMandatTable;

  totalItems: number;
  //visitors: Visitor[];

  //stats: VisitorStats;
  /*
  stat_nbr_visitors: Observable<number>;
  stat_nbr_exhibitors: Observable<number>;
  stat_nbr_appointments: Observable<number>;
  stat_nbr_registered: Observable<number>;
  stat_nbr_approved: Observable<number>;
  stat_nbr_fs_done: Observable<number>;
  */
  dataSubject = new BehaviorSubject<TransferMandat[]>([]);
  visitors$: Observable<TransferMandat[]>;
  dataSource = new MatTableDataSource<TransferMandat>();

  isLoading = true;

  searchTerm$ = new BehaviorSubject<string>('');

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSizes = [50, 100, 200, 500];

  searchValue: string;
  //searchKeywordFilter = new FormControl();

  stati: string[] = ['Alle', 'Entwurf', 'Transfer', 'Ungültig', 'Übertragen', 'Error'];

  empFilters: EmpFilter[] = [];
  defaultValue = 'Alle';
  filterDictionary = new Map<string, string>();
  //dataSourceFilters = new MatTableDataSource();
  filters$ = new BehaviorSubject<string>('');

  // dilaog und sidenav
  openSidebar = false;
  @ViewChild('sidenav') sidenav;
  selectedVisitor: TransferMandat;
  @ViewChild('visitorContainer', { read: ViewContainerRef }) quizContainer: ViewContainerRef;
  private subscription: Subscription; // changes in the eg sidebar

  constructor(
    private transferService: TransferMandatService,
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    private _snackBar: MatSnackBar,
    private _fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authQuery: AuthQuery,
    private changeDetectorRef: ChangeDetectorRef,
    private dataChangeService: DataChangeService
  ) {}

  ngOnInit() {
    //this.empFilters.push({ name: 'Land', options: this.countries, defaultValue: this.defaultValue });
    this.empFilters.push({ name: 'Status', options: this.stati, defaultValue: this.defaultValue });
    //this.empFilters.push({ name: 'Tatort', options: this.streets, defaultValue: this.defaultValue });

    // subscribe to the sidebar changes
    this.subscription = this.dataChangeService.dataChanges$.subscribe((data) => {
      if (data) {
        // Handle the data, e.g., update the list or form
        console.log('change recieved-----------');
        console.log(data);

        // update the specific transfer
        this.updateTransfer(data);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe(); // unsubscibe to the subscriptions
  }

  /**
   * update the datasource with the given data from the subscription
   */
  updateTransfer(updatedTransfer: TransferMandat) {
    const data = this.dataSubject.getValue();
    const index = data.findIndex((transfer) => +transfer.id === +updatedTransfer.id);
    if (index > -1) {
      data[index] = updatedTransfer;
      this.dataSubject.next(data); // Update the BehaviorSubject
      this.dataSource.data = data; // Update the MatTableDataSource
    }
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;

    merge(
      this.filters$,
      this.searchTerm$.pipe(debounceTime(400), distinctUntilChanged()),
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(
        startWith({}),
        switchMap((term) => {
          this.isLoading = true;
          //var sTerm = term && typeof term == 'string' ? term.toString() : '';
          return this.getTableData$(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            //sTerm.trim(),
            this.searchTerm$.getValue(),
            this.filters$.getValue()
          ).pipe(catchError(() => observableOf(null)));
        }),
        map((visTable) => {
          if (visTable == null) return [];
          this.totalItems = visTable.total;
          this.isLoading = false;

          //this.stats = visTable.stats;

          return visTable.data.map((data) => {
            return <TransferMandat>{
              ...data,
              /*
              decharge_resp_civile: data.decharge_resp_civile == -1 ? 1 : 0,
              estimate: data.estimate == '-1' ? '1' : 0,
              garage_day1: data.garage_day1 == -1 ? 1 : 0,
              garage_day2: data.garage_day2 == -1 ? 1 : 0,
              lunch_day1: data.lunch_day1 == -1 ? 1 : 0,
              lunch_day2: data.lunch_day2 == -1 ? 1 : 0,
              attendance_event: data.attendance_event == -1 ? 1 : 0,
              hotel_stay_night: data.hotel_stay_night == -1 ? 1 : 0,
              transfer_arrival: data.transfer_arrival == -1 ? 1 : 0,
              transfer_return: data.transfer_return == -1 ? 1 : 0,
              flight_booked: data.flight_booked == -1 ? 1 : 0,
              */
            };
          });
          //return visTable.data;
        })
      )
      .subscribe((transfers: TransferMandat[]) => {
        this.visitors$ = of(transfers);
        this.dataSource = new MatTableDataSource(transfers);

        this.dataSubject.next(transfers);

        //reset paginator
        //this.paginator.pageIndex = 1;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTerm$.next(filterValue);
  }

  applyEmpFilter(ob: MatSelectChange, empfilter: EmpFilter) {
    this.filterDictionary.set(empfilter.name, ob.value);
    var jsonString = JSON.stringify(Array.from(this.filterDictionary.entries()));
    this.filters$.next(jsonString);
  }

  getTableData$(pageNumber: Number, pageSize: Number, sort: string, order, searchTerm: string, filtersArgs: string) {
    // get data from server
    return this.transferService.getTransferMandate(pageNumber, pageSize, sort, order, searchTerm, filtersArgs);
  }

  /**
   * checkTransfer - check 3 things
   * // a) dublette auf aktenzeichen
     // b) falsches kennzeichen
     // c) bereits in mandate db vorhanden
   */
  checking = false;
  checkJournal: string = '';
  isChecked = false;
  errorsFound = false;
  numberCorrect: number = 0;

  // do the check
  checkTransfer() {
    console.log('check transfers');
    this.checking = true;

    // check for dubletten in the TRANSFER items
    const seenIds: Set<string> = new Set();

    this.checkJournal += 'Prüfe TRANSFER <br>';

    // iterate all the rows
    this.dataSource.data.forEach((row) => {
      // init the errors
      row.errorId = 0;
      row.errorMessage = '';

      this.checkJournal += '.';

      // check for TRANSFER status
      // transferred = bereits transferd
      // status=3 >  status ungültig - gelöscht
      if (row.transferred == 0 || row.status_id == '3') {
        // its still in DRAFT mode
        row.errorMessage += 'In Arbeit';
        row.errorId += 100;
      } else {
        // ---------------------------------------------------------------------
        //
        // check ob das aktenzeichen bereits vorkommt (im transfer !)
        if (row.aktenzeichen !== undefined && row.aktenzeichen !== null) {
          if (seenIds.has(row.aktenzeichen)) {
            // This id has been seen more than once
            // console.log(`Duplicate id found: ${row.aktenzeichen}`);
            row.errorMessage += 'Aktenzeichen ist doppelt vorhanden';
            row.errorId += 2;

            this.checkJournal += '<br>' + row.aktenzeichen + ' Aktenzeichen ist doppelt vorhanden';

            this.errorsFound = true;
          } else {
            // First occurrence of the id
            seenIds.add(row.aktenzeichen);
          }
        }

        // ---------------------------------------------------------------------
        //
        // check ob das kennzeichen korrekt ist
        if (!this.checkKennzeichen(row.plate)) {
          row.errorMessage += 'Kennzeichen ist nicht korrekt per VSTV';
          row.errorId += 4;
          this.errorsFound = true;

          this.checkJournal += '<br>' + row.aktenzeichen + ' Kennzeichen ist nicht korrekt per VSTV';
        }

        // ---------------------------------------------------------------------
        //
        // check ob das mandat ? bereits in den mandaten vorkommt (uebertragen wurde)
        if (!this.checkAktenzeichenInMandate(row.aktenzeichen)) {
          row.errorMessage += 'Aktenzeichen wurde bereits zur Anzeige übermittelt';
          row.errorId += 8;
          this.errorsFound = true;

          this.checkJournal += '<br>' + row.aktenzeichen + ' Aktenzeichen wurde bereits zur Anzeige übermittelt';
        }

        // ---------------------------------------------------------------------
        //
        // in diesem fall ist alles OK -> show the
        if (row.errorId == 0) {
          row.errorMessage = 'Alles OK';
          row.errorId = 999;
          //this.errorsFound = true; --> do not change the values
          this.numberCorrect++;

          this.checkJournal += 'OK ';
        }
      }
    });

    this.checkJournal += '<br> Überprüfen auf Doppelte in TRANSFER Liste...Erledigt';
    this.checkJournal += '<br> Überprüfen auf inkorrekte Kennzeichen per VSTV...Erledigt';
    this.checkJournal += '<br> Überprüfen auf bereits übermittelt...Erledigt';
    this.checkJournal += '<br> Kontrolle abgeschlossen - Fertig.';
    this.checkJournal += '<br>';
    this.checkJournal += '<br> ' + this.numberCorrect + ' Transfer können überführt werden zu Mandate.';

    this.isChecked = true;
  }

  /**
   * checkKennzeichen
   *    > check if kennzeichen is korrekt for VSTV Export
   */
  checkKennzeichen(plate: string): boolean {
    console.log('check Kennzeichen');
    return true;
  }

  /**
   * checkAktenzeichenInMandate
   *    > check if Aktenzeichen is in Mandate already
   */
  checkAktenzeichenInMandate(plate: string): boolean {
    console.log('check Aktenzeichen in Mandate');
    return true;
  }

  /**
   * doTransfer2Mandate -> if everything is fine
   *    > create the mandate out of the transfer
   */
  transferSuccess = false;
  doTransfer2Mandate() {
    console.log('do transfer');

    /* BUG BUG BUG -- we need some kind of security
    let request = {
      api_key: this._config.getConfig().api_key,
      api_baseurl: this._config.getConfig().api_baseurl,
      company_id: this._config.getConfig().company_id,
      person_id: this._config.getConfig().person_id,
    };
    */

    let requestData = {
      api_key: new Date().getTime(),
      //api_baseurl: this._config.getConfig().api_baseurl,
      //company_id: this._config.getConfig().company_id,
      //person_id: this._config.getConfig().person_id,
      do: 'transfer2mandate',
    };

    // update to the server
    this.transferService.doTransfer2Mandate(requestData).subscribe(
      (response: TransferMandat[]) => {
        //next() callback
        console.log('response received');
        console.log(response);

        // BUG BUG BUG ---> refreh the list
        //

        //
        // show some feedback --> snackbar ?
        this._snackBar.open('Datensätze erfolgreich zu Mandate übertragen', 'Schließen', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        this.transferSuccess = true;
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  /**
   * show the sidebar page
   * @param transfer_id
   *
   */

  showPageDetail(transfer_id: number) {
    console.log('load page: ' + transfer_id);

    // get the quiz page with given uuid
    let transfer: TransferMandat;
    transfer = <TransferMandat>(
      this.dataSource.filteredData.find((element: TransferMandat) => element.id == transfer_id)
    );

    // load the component into the modal slide window
    //this.router.navigate(['/home/edit', visitor_id]);
    this.router.navigate([
      '/transfer/edit',
      { id: transfer_id, _uid: transfer.id, _fid: transfer_id, _role: 3, _country: transfer.plate, ia: 1 },
    ]);
    //, {my_object: JSON.stringify(this.Obj)}]));

    this.current_aktenzeichen = transfer.aktenzeichen;
    //this.showVisitor(visitor);
    //this.sidenav.open();
    //const mySidebar = <any>document.querySelectorAll('.offcanvas');
    //mySidebar.show();

    $('#staticBackdrop').offcanvas('show');
  }

  showVisitor(visitor: TransferMandat) {
    console.log('show the page');

    this.selectedVisitor = visitor;

    // load visitor - firststart
    //this.llStartPageEdit(visitor);
  }

  sidebarOnClose() {
    console.log('close');
    this.openSidebar = false;
  }

  additionalColumns: string[] = [
    'generalisttour',
    'specialisttour',
    'tourownretailing',
    'tourretailingtravelagencies',
    'touroperatormember',
    'travel',
    'incentive',
    'inernationalincoming',
    'coach',
    'carrental',
    'hotelbookingcallcenter',
    'aircarrier',
    'seacarrier',
    'roadcarrier',
    'railcarrier',
    'numberemployees',
    'volumegroups',
    'volumeindividuals',
    'numberbrochures',
    'onlinebooking',
  ];
  showAdditionalColumns() {
    this.displayedColumns = this.displayedColumns.concat(this.additionalColumns);
    console.log(this.displayedColumns);
  }

  //
  // -------------------------------
}
