import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padString',
})
export class PadStringPipe implements PipeTransform {
  transform(value: string, desiredLength: number): string {
    // Check if value is a string and desiredLength is a positive number
    if (typeof value === 'string' && desiredLength && desiredLength > 0) {
      return value.padEnd(desiredLength, ' ');
    }
    return value;
  }
}
