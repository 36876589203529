import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateLand',
})
export class TranslateLandPipe implements PipeTransform {
  private countrycodeMap = {
    at: 'A',
    de: 'D',
    hu: 'H',
    unknown: 'A',
  };

  transform(value: string): string {
    // Check if the value exists in the map; if not, return 'unknown'
    return this.countrycodeMap[value.toLowerCase()] || value;
  }
}
