import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'phuture-iavatar',
  templateUrl: './phuture-iavatar.component.html',
  styleUrls: ['./phuture-iavatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhutureIavatarComponent implements OnInit {
  @Input() src_image: string = '';

  constructor() {}

  ngOnInit(): void {
    console.log('oninit iavatar');
  }
}
