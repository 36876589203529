import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';

import { LoginPageComponent } from './login-page/login-page.component';
import { ForgotPwdPageComponent } from './forgot-pwd-page/forgot-pwd-page.component';
import { RegisterNewPageComponent } from './register-new-page/register-new-page.component';
import { ResetPwdComponent } from './reset-pwd/reset-pwd.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent, data: { title: extract('Login') } },
  { path: 'logout', redirectTo: 'login' },
  { path: 'forgot-pwd', component: ForgotPwdPageComponent, data: { title: extract('ForgotPwd') } },
  { path: 'register-new', component: RegisterNewPageComponent, data: { title: extract('RegisterNew') } },
  { path: 'reset-pwd', component: ResetPwdComponent, data: { title: extract('ResetPwd') } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
