import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';
import { CookieService } from 'ngx-cookie-service';

import { TranslateModule } from '@ngx-translate/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
//import localeDeAt from '@angular/common/locales/de-AT';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);
//registerLocaleData(localeDeAt, 'de-AT'); // de-AT
//registerLocaleData(localeDeAt, 'de-CH'); // de-CH

import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { AuthModule } from '@app/auth';

import { ShellModule } from './shell/shell.module';
//import { HomeModule } from './home/home.module';
import { MandatModule } from './mandat/mandat.module';
import { TransferModule } from './transfer/transfer.module';

import { AboutModule } from './about/about.module';
import { SettingsModule } from './settings/settings.module';

import { ImpressumModule } from './impressum/impressum.module';
import { DatenschutzModule } from './datenschutz/datenschutz.module';
import { HelpModule } from './help/help.module';
import { SupportModule } from './support/support.module';
import { StammdatenModule } from './stammdaten/stammdaten.module';

//import { PhutureLoginModule } from './phuture-login/phuture-login.module';
import { MandatEditModule } from './mandat_edit/mandat_edit.module';
import { TransferEditModule } from './transfer_edit/transfer_edit.module';
import { VSTVModule } from './vstv/vstv.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    MaterialModule,
    TranslateModule.forRoot(),

    GalleryModule,
    LightboxModule,

    CoreModule,
    SharedModule,
    AuthModule,

    ShellModule,
    //HomeModule, ---> wurde zu dashboard
    MandatModule,
    TransferModule,
    VSTVModule,

    AboutModule,
    SettingsModule,
    ImpressumModule,
    DatenschutzModule,
    HelpModule,
    SupportModule,
    StammdatenModule,

    //PhutureLoginModule,
    MandatEditModule,
    TransferEditModule,

    environment.production ? [] : AkitaNgDevtools.forRoot({ shallow: false, sortAlphabetically: true }),

    AppRoutingModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
