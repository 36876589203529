import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthenticationGuard } from '@app/auth/authentication.guard';

import { Shell } from '@app/shell/shell.service';

//import { PhutureLoginComponent } from './phuture-login/phuture-login.component';
//import { DashboardComponent } from './dashboard/dashboard.component';
//import { FirststartComponent } from './firststart/firststart.component';

const routes: Routes = [
  Shell.childRoutes([
    //{ path: 'login', loadChildren: () => import('./phuture-login/phuture-login.module').then((m) => m.PhutureLoginModule) },
    //{path: 'first', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
    //{} path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
    //{ path: 'settings', loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule) },
    //{ path: 'support', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) }
  ]),

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

/*
const routes: Routes = [
  //{ path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    canActivate: [],
    component: PhutureLoginComponent,
    data: { title: 'Atout' },
  },

  {
    path: 'home',
    canActivate: [AuthenticationGuard],
    component: DashboardComponent,
    data: { title: 'Home' },
    // List and form component is the child component of sidebar3 component.
    children: [
      {
        // When you click go to list, it navigate to list component page.
        path: 'edit',
        component: FirststartComponent,
      },
    ],
  },

  // this firststart routine is only vor the visitors
  {
    path: 'firststart',
    canActivate: [AuthenticationGuard], // AppDataSyncGuard
    component: FirststartComponent,
    data: { title: 'Home' },
  },


];
*/

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
