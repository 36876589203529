import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '@core';
import { AuthDataService } from '@app/auth/auth-data.service';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.scss'],
})
export class ResetPwdComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;

  userid: number = 0;
  token: string = '';
  tstamp: number = 0;

  loginlink = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthDataService,
    private _snackBar: MatSnackBar
  ) {
    this.createForm();
  }

  ngOnInit() {
    // Capture the token  if available
    this.route.queryParams.subscribe((params) => {
      //console.log(params);
      this.userid = params['uid'];
      //this.token = params['token'];
      //this.tstamp = params['tstamp'];

      this.token = params['token'];
      //console.log('token' + this.token);
    });
  }

  ngOnDestroy() {}

  resetpwd() {
    var pwd = this.loginForm.controls['password'].value;

    let data = {
      email: '',
      token: this.token,
      uid: this.userid,
      password: pwd,
      password_confirm: this.loginForm.controls['pwdconfirm'].value,
    };

    if (pwd.length < 6) {
      this._snackBar.open('Bitte geben Sie ein längeres Kennwort ein', 'Schließen', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 3000,
      });
    } else {
      const changePwd$ = this.authService.changepwd(data);
      changePwd$
        .pipe(
          finalize(() => {
            this.loginForm.markAsPristine();
          })
        )
        .subscribe(
          (data) => {
            //console.log(data);

            // show done notification alert
            if (data.code == 200) {
              this._snackBar.open('Kennwortänderung erfolgreich durchgeführt', 'Schließen', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 3000,
              });

              //show link zur anmeldung
              this.loginlink = 1;
            } else {
              this._snackBar.open('Fehler aufgetreten.', 'Schließen', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 3000,
              });
            }
          },
          (error) => {
            // show error notification alert
            this._snackBar.open('Fehler aufgetreten', 'Schließen', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        );
    }
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      pwdconfirm: ['', Validators.required],
    });
  }
}
