import { Component, HostListener, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { IAppDataModel, IYearData, YearData } from '@app/@appstate/appdata.model';

import { AppDataQuery } from '@app/@appstate/appdata.query';
import { AppDataService } from '@app/@appstate/appdata.service';
import { AuthQuery } from '@app/auth/auth.query';
import { AuthDataService } from '@app/auth/auth-data.service';

import { Observable } from 'rxjs';

import { ConfirmationService } from 'primeng/api';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';

import { JournalService } from '@app/@core';
import {
  StammdatenService,
  Stammdaten,
  Land,
  Bezirk,
  Street,
  Farbe,
  Hersteller,
  Tatbestand,
} from '@app/services/stammdaten.service';

import { DialogInputComponent } from '../@shared/dialog/input/dialog-input.component';
import { ConfirmDialogComponent } from '../@shared/dialog/confirm-dialog/confirm-dialog.component';

import { TatbestandDialogComponent } from './dialog-tatbestand/dialog-tatbestand.component';

// data structures
interface LandExt extends Land {
  isEditable?: boolean;
}
interface BezirkExt extends Bezirk {
  isEditable?: boolean;
}
interface StreetExt extends Street {
  isEditable?: boolean;
}
interface FarbeExt extends Farbe {
  isEditable?: boolean;
}
interface HerstellerExt extends Hersteller {
  isEditable?: boolean;
}
interface TatbestandExt extends Tatbestand {
  isEditable?: boolean;
}

/*
enum CropType {
  MAIZE = 'Maize',
  SUGARBEET = 'Sugarbeet',
  NONE = 'None',
}

interface Field {
  position: string;
  name: string;
  area: number;
  type: CropType;
  isEditable?: boolean;
}
*/

/**
 * component
 *
 */
@Component({
  selector: 'app-stammdaten',
  templateUrl: './stammdaten.component.html',
  styleUrls: ['./stammdaten.component.css'],
})
export class StammdatenComponent implements OnInit {
  // the state
  appData: IAppDataModel;

  // copy of the current state
  cAppData: IAppDataModel;

  arMonate = [];

  changePwdForm!: FormGroup;

  //displayedColumns: string[] = ['id', 'title', 'title2', 'action'];
  displayLander: string[] = ['bezeichnung', 'action'];
  displayBezirke: string[] = ['land', 'bezeichnung', 'action'];
  displayStrassen: string[] = ['bezeichnung', 'tatortart', 'action']; // , 'zone'
  displayFarben: string[] = ['bezeichnung', 'action'];
  displayHersteller: string[] = ['bezeichnung', 'action'];
  displayTatbestande: string[] = ['vstv_code', 'paragraph', 'short', 'drucktext', 'strafe', 'reihung', 'action'];

  stammdaten: Stammdaten;

  dataSourceLander: MatTableDataSource<LandExt> = new MatTableDataSource<LandExt>();
  dataSourceBezirke: MatTableDataSource<BezirkExt> = new MatTableDataSource<BezirkExt>();
  dataSourceStrassen: MatTableDataSource<StreetExt> = new MatTableDataSource<StreetExt>();
  dataSourceFarben: MatTableDataSource<FarbeExt> = new MatTableDataSource<FarbeExt>();
  dataSourceHersteller: MatTableDataSource<HerstellerExt> = new MatTableDataSource<HerstellerExt>();
  dataSourceTatbestande: MatTableDataSource<TatbestandExt> = new MatTableDataSource<TatbestandExt>();

  constructor(
    private _formBuilder: FormBuilder,
    private _adQuery: AppDataQuery,
    private _adService: AppDataService,
    private authQuery: AuthQuery,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private authService: AuthDataService,
    private _snackBar: MatSnackBar,
    private journalService: JournalService,
    private stammdatenService: StammdatenService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // query and init the data
    //this.data$ = this._adQuery.selectFirst();
    this._adQuery.selectEntity(this.authQuery.appid).subscribe((state) => {
      //console.log(state);
      this.appData = <IAppDataModel>state;

      //this.cAppData = { ...state };
      // this.cAppData = Object.assign({}, state);
      // deep clone
      try {
        this.cAppData = JSON.parse(JSON.stringify(state));
      } catch (err) {
        // console.log("error");
      }

      //console.log("change state");
    });

    // get the stammdaten
    this.getStammdaten();
  }

  /**
   * get the stammdaten
   */
  getStammdaten() {
    console.log('do transfer');

    /* BUG BUG BUG -- we need some kind of security
    let request = {
      api_key: this._config.getConfig().api_key,
      api_baseurl: this._config.getConfig().api_baseurl,
      company_id: this._config.getConfig().company_id,
      person_id: this._config.getConfig().person_id,
    };
    */

    let requestData = {
      api_key: new Date().getTime(),
      //api_baseurl: this._config.getConfig().api_baseurl,
      //company_id: this._config.getConfig().company_id,
      //person_id: this._config.getConfig().person_id,
      do: 'getStammdaten',
    };

    this.stammdatenService.getStammdaten().subscribe(
      (response: Stammdaten) => {
        //next() callback
        console.log('response received');
        console.log(response);

        // also set the whole stammdaten
        this.stammdaten = response;

        // set the date
        this.dataSourceLander.data = response.lander;
        this.dataSourceBezirke.data = response.bezirke;
        this.dataSourceStrassen.data = response.streets;
        this.dataSourceFarben.data = response.farben;
        this.dataSourceHersteller.data = response.hersteller;
        this.dataSourceTatbestande.data = response.tatbestande;
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  // -------------------------------------------------------------
  // ---- EDIT
  //
  // land
  @ViewChild('tableLand') tableLand!: MatTable<any>;
  editableLand: LandExt | null = null; // Currently editable row
  originalDataLand: { [key: string]: any } = {}; // copy of the original data

  // bezirk
  @ViewChild('tableBezirk') tableLBezirk!: MatTable<any>;
  editableBezirk: BezirkExt | null = null; // Currently editable row
  originalDataBezirk: { [key: string]: any } = {}; // copy of the original data

  // strasse
  @ViewChild('tableStreet') tableStreet!: MatTable<any>;
  editableStreet: StreetExt | null = null; // Currently editable row
  originalDataStreet: { [key: string]: any } = {}; // copy of the original data

  // farbe
  @ViewChild('tableFarbe') tableFarbe!: MatTable<any>;
  editableFarbe: FarbeExt | null = null; // Currently editable row
  originalDataFarbe: { [key: string]: any } = {}; // copy of the original data

  // hersteller
  @ViewChild('tableHersteller') tableHersteller!: MatTable<any>;
  editableHersteller: HerstellerExt | null = null; // Currently editable row
  originalDataHersteller: { [key: string]: any } = {}; // copy of the original data

  // tatbestand
  @ViewChild('tableTatbestand') tableTatbestand!: MatTable<any>;
  editableTatbestand: TatbestandExt | null = null; // Currently editable row
  originalDataTatbestand: { [key: string]: any } = {}; // copy of the original data

  /**
   *  ADD a land to the list
   *
   */

  // add new land to the stammdaten json
  addNewLand() {
    const emptyRowLand: LandExt = {
      id: this.findMaxId(this.dataSourceLander.data) + 1,
      bezeichnung: '',
      usage: 0,
      isEditable: true,
    };
    this.editableLand = emptyRowLand; // Track the new row as editable
    this.dataSourceLander.data = [emptyRowLand, ...this.dataSourceLander.data];
    this.tableLand.renderRows();
  }

  /**
   *  ADD a land to the list
   *
   */

  // add new land to the stammdaten json
  addNewBezirk() {
    const emptyRowBezirk: BezirkExt = {
      id: this.findMaxId(this.dataSourceBezirke.data) + 1,
      bezeichnung: '',
      land: '',
      usage: 0,
      isEditable: true,
    };
    this.editableBezirk = emptyRowBezirk; // Track the new row as editable
    this.dataSourceBezirke.data = [emptyRowBezirk, ...this.dataSourceBezirke.data];
    this.tableLBezirk.renderRows();
  }

  /**
   *  ADD a street to the list
   *
   */

  // add new street to the stammdaten json
  addNewStreet() {
    const emptyRowStreet: StreetExt = {
      id: this.findMaxId(this.dataSourceStrassen.data) + 1,
      bezeichnung: '',
      tatortart: 'PO',
      zone: 1,
      usage: 0,
      isEditable: true,
    };
    this.editableStreet = emptyRowStreet; // Track the new row as editable
    this.dataSourceStrassen.data = [emptyRowStreet, ...this.dataSourceStrassen.data];
    this.tableStreet.renderRows();
  }

  /**
   *  ADD a farbe to the list
   *
   */

  // add new farbe to the stammdaten json
  addNewFarbe() {
    const emptyRowFarbe: FarbeExt = {
      id: this.findMaxId(this.dataSourceFarben.data) + 1,
      bezeichnung: '',
      usage: 0,
      isEditable: true,
    };
    this.editableFarbe = emptyRowFarbe; // Track the new row as editable
    this.dataSourceFarben.data = [emptyRowFarbe, ...this.dataSourceFarben.data];
    this.tableFarbe.renderRows();
  }

  /**
   *  ADD a hersteller to the list
   *
   */

  // add new hersteller to the stammdaten json
  addNewHersteller() {
    const emptyRowHersteller: HerstellerExt = {
      id: this.findMaxId(this.dataSourceHersteller.data) + 1,
      bezeichnung: '',
      usage: 0,
      isEditable: true,
    };
    this.editableHersteller = emptyRowHersteller; // Track the new row as editable
    this.dataSourceHersteller.data = [emptyRowHersteller, ...this.dataSourceHersteller.data];
    this.tableHersteller.renderRows();
  }

  /**
   *  ADD a tatbestand to the list
   *
   */

  // add new tatbestand to the stammdaten json
  neuanlage: boolean = false;
  addNewTatbestand() {
    this.neuanlage = true;
    const emptyRowTatbestand: TatbestandExt = {
      id: this.findMaxId(this.dataSourceTatbestande.data) + 1,
      vstv_code: '',
      paragraph: '',
      reihung: 0,
      strafe: 0,
      short: '',
      drucktext: '',
      usage: 0,
      isEditable: true,
      luecken: [],
      freitext: '',
    };
    this.editableTatbestand = emptyRowTatbestand; // Track the new row as editable
    this.dataSourceTatbestande.data = [emptyRowTatbestand, ...this.dataSourceTatbestande.data];
    this.tableTatbestand.renderRows();
  }

  /**
   *  SAVE an ITEM
   *
   */

  saveItem(type: string, item: any) {
    console.log('save item');

    item.isEditable = false;

    switch (type) {
      case 'Land':
        this.editableLand = null;
        this.tableLand.renderRows();
        // update stammdaten values
        this.stammdaten.lander = this.dataSourceLander.data;
        break;

      case 'Bezirk':
        this.editableLand = null;
        this.tableLBezirk.renderRows();
        // update stammdaten values
        this.stammdaten.bezirke = this.dataSourceBezirke.data;
        break;

      case 'Street':
        this.editableStreet = null;
        this.tableStreet.renderRows();
        // update stammdaten values
        this.stammdaten.streets = this.dataSourceStrassen.data;
        break;

      case 'Farbe':
        this.editableFarbe = null;
        this.tableFarbe.renderRows();
        // update stammdaten values
        this.stammdaten.farben = this.dataSourceFarben.data;
        break;

      case 'Hersteller':
        this.editableHersteller = null;
        this.tableHersteller.renderRows();
        // update stammdaten values
        this.stammdaten.hersteller = this.dataSourceHersteller.data;
        break;

      case 'Tatbestand':
        this.editableTatbestand = null;
        this.tableTatbestand.renderRows();
        // update stammdaten values
        this.stammdaten.tatbestande = this.dataSourceTatbestande.data;
        break;

      default:
        break;
    }

    // update stammdaten and sync with backend
    this.updateStammDaten();
    this.neuanlage = false;
  }

  /**
   *  REMOVE an ITEM
   *
   */
  removeItem(type: string, item: any) {
    // get the confirmation
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Bestätigung',
        message: 'Der ausgewählte Eintrag wird gelöscht.',
        cancelButton: 'Abbrechen',
        confirmButton: 'Ok',
      },
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        // ok was clicked
        console.log(result);

        var index = -1;

        switch (type) {
          case 'Land':
            index = this.dataSourceLander.data.indexOf(item);
            if (index > -1) {
              this.dataSourceLander.data.splice(index, 1); // Remove the row from data source
              this.dataSourceLander._updateChangeSubscription(); // Refresh the MatTable
            }
            break;

          case 'Bezirk':
            index = this.dataSourceBezirke.data.indexOf(item);
            if (index > -1) {
              this.dataSourceBezirke.data.splice(index, 1); // Remove the row from data source
              this.dataSourceBezirke._updateChangeSubscription(); // Refresh the MatTable
            }
            break;

          case 'Street':
            index = this.dataSourceStrassen.data.indexOf(item);
            if (index > -1) {
              this.dataSourceStrassen.data.splice(index, 1); // Remove the row from data source
              this.dataSourceStrassen._updateChangeSubscription(); // Refresh the MatTable
            }
            break;

          case 'Farbe':
            index = this.dataSourceFarben.data.indexOf(item);
            if (index > -1) {
              this.dataSourceFarben.data.splice(index, 1); // Remove the row from data source
              this.dataSourceFarben._updateChangeSubscription(); // Refresh the MatTable
            }
            break;

          case 'Hersteller':
            index = this.dataSourceHersteller.data.indexOf(item);
            if (index > -1) {
              this.dataSourceHersteller.data.splice(index, 1); // Remove the row from data source
              this.dataSourceHersteller._updateChangeSubscription(); // Refresh the MatTable
            }
            break;

          case 'Tatbestand':
            index = this.dataSourceTatbestande.data.indexOf(item);
            if (index > -1) {
              this.dataSourceTatbestande.data.splice(index, 1); // Remove the row from data source
              this.dataSourceTatbestande._updateChangeSubscription(); // Refresh the MatTable
            }
            break;

          default:
            break;
        }

        // update stammdaten and sync with backend
        this.updateStammDaten();
      } else {
        // cancel
        this.cancelEdit(type, item);
      }
    });
  }

  /**
   *  cancel the edit
   *
   */
  cancelEdit(type: string, row: any) {
    switch (type) {
      case 'Land':
        if (this.originalDataLand[row.id]) {
          Object.assign(row, this.originalDataLand[row.id]); // Restore original data
          delete this.originalDataLand[row.id]; // Clean up
        }
        this.editableLand = null; // Reset the editable row
        row.isEditable = false;
        this.tableLand.renderRows();
        break;

      case 'Bezirk':
        if (this.originalDataBezirk[row.id]) {
          Object.assign(row, this.originalDataBezirk[row.id]); // Restore original data
          delete this.originalDataBezirk[row.id]; // Clean up
        }
        this.editableBezirk = null; // Reset the editable row
        row.isEditable = false;
        this.tableLBezirk.renderRows();
        break;

      case 'Street':
        if (this.originalDataStreet[row.id]) {
          Object.assign(row, this.originalDataStreet[row.id]); // Restore original data
          delete this.originalDataStreet[row.id]; // Clean up
        }
        this.editableStreet = null; // Reset the editable row
        row.isEditable = false;
        this.tableStreet.renderRows();
        break;

      case 'Farbe':
        if (this.originalDataFarbe[row.id]) {
          Object.assign(row, this.originalDataFarbe[row.id]); // Restore original data
          delete this.originalDataFarbe[row.id]; // Clean up
        }
        this.editableFarbe = null; // Reset the editable row
        row.isEditable = false;
        this.tableFarbe.renderRows();
        break;

      case 'Hersteller':
        if (this.originalDataHersteller[row.id]) {
          Object.assign(row, this.originalDataHersteller[row.id]); // Restore original data
          delete this.originalDataHersteller[row.id]; // Clean up
        }
        this.editableHersteller = null; // Reset the editable row
        row.isEditable = false;
        this.tableHersteller.renderRows();
        break;

      case 'Tatbestand':
        if (this.originalDataTatbestand[row.id]) {
          Object.assign(row, this.originalDataTatbestand[row.id]); // Restore original data
          delete this.originalDataTatbestand[row.id]; // Clean up
        }
        this.editableTatbestand = null; // Reset the editable row
        row.isEditable = false;
        this.tableTatbestand.renderRows();
        break;

      default:
        break;
    }
    this.neuanlage = false;
  }

  /**
   *  EDIT an ITEM
   *
   */

  editItem(type: string, element: any) {
    console.log('edit item');
    console.log(element);

    switch (type) {
      case 'Land':
        this.dataSourceLander.data.forEach((r) => (r.isEditable = false)); // Ensure all other rows are not editable
        element.isEditable = true;
        this.editableLand = { ...element }; // Create a copy for tracking
        this.originalDataLand[element.id] = { ...element }; // Store original data
        this.tableLand.renderRows();
        break;

      case 'Bezirk':
        this.dataSourceBezirke.data.forEach((r) => (r.isEditable = false)); // Ensure all other rows are not editable
        element.isEditable = true;
        this.editableBezirk = { ...element }; // Create a copy for tracking
        this.originalDataBezirk[element.id] = { ...element }; // Store original data
        this.tableLBezirk.renderRows();
        break;

      case 'Street':
        this.dataSourceStrassen.data.forEach((r) => (r.isEditable = false)); // Ensure all other rows are not editable
        element.isEditable = true;
        this.editableStreet = { ...element }; // Create a copy for tracking
        this.originalDataStreet[element.id] = { ...element }; // Store original data
        this.tableStreet.renderRows();
        break;

      case 'Farbe':
        this.dataSourceFarben.data.forEach((r) => (r.isEditable = false)); // Ensure all other rows are not editable
        element.isEditable = true;
        this.editableFarbe = { ...element }; // Create a copy for tracking
        this.originalDataFarbe[element.id] = { ...element }; // Store original data
        this.tableFarbe.renderRows();
        break;

      case 'Hersteller':
        this.dataSourceHersteller.data.forEach((r) => (r.isEditable = false)); // Ensure all other rows are not editable
        element.isEditable = true;
        this.editableHersteller = { ...element }; // Create a copy for tracking
        this.originalDataHersteller[element.id] = { ...element }; // Store original data
        this.tableHersteller.renderRows();
        break;

      case 'Tatbestand':
        /*  old: inline edit
        this.dataSourceTatbestande.data.forEach((r) => (r.isEditable = false)); // Ensure all other rows are not editable
        element.isEditable = true;
        this.editableTatbestand = { ...element }; // Create a copy for tracking
        this.originalDataTatbestand[element.id] = { ...element }; // Store original data
        this.tableTatbestand.renderRows();
        */
        console.log(element);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = 450;
        dialogConfig.data = { tatbestand: element };
        dialogConfig.panelClass = 'custom-dialog-container';

        const dialogRef = this.dialog.open(TatbestandDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
          console.log('The dialog was closed');
          if (result && result.event === 'save') {
            console.log('The dialog was closed with a save click');
            console.log(result.data);

            const index = this.dataSourceTatbestande.data.findIndex((t) => t.id === result.data.id);
            if (index !== -1) {
              this.dataSourceTatbestande.data[index] = result.data;
              this.dataSourceTatbestande.data = [...this.dataSourceTatbestande.data]; // Refresh the data array to trigger change detection
            }

            // update stammdaten and sync with backend
            this.updateStammDaten();
          } else {
            console.log('The dialog was closed without saving');
          }
        });

        break;

      default:
        break;
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    /*
    if (event.key === 'Enter' && this.editableRow && this.editableRow.isEditable) {
      this.saveRow(this.editableRow);
      this.editableRow = null; // Reset the editable row
    } else if (event.key === 'Escape' && this.editableRow && this.editableRow.isEditable) {
      this.cancelEdit(this.editableRow);
      this.editableRow = null; // Reset the editable row
    }
    */
  }

  /**
   * update stammdaten and sync with backend
   * @param type
   * @param item
   */
  updateStammDaten() {
    console.log('sync server');
    console.log(this.stammdaten);

    var username = this.authQuery.name;
    var userid = this.authQuery.userid;
    var token = this.authQuery.access_token;

    let data = {
      username: username,
      uid: userid,
      token: token,
      data: this.stammdaten,
    };

    this.stammdatenService
      .syncStammdaten(data)
      .pipe(
        finalize(() => {
          // do nothing here
        })
      )
      .subscribe(
        (data) => {
          //console.log(data);

          this._snackBar.open('Änderungen erfolgreich durchgeführt', 'Schließen', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
            panelClass: 'awesome-snackbar',
          });
        },
        (error) => {
          // show error notification alert
          this._snackBar.open('Fehler aufgetreten', 'Schließen', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      );
  }

  //
  // ------------------------------------------------------------------------
  //

  /**
   *
   * get the next ID (number) max of the array of items
   */
  findMaxId(items): number {
    // Filter out null or undefined items first
    const validItems = items.filter((item) => item != null);

    // Ensure the array is not empty
    if (validItems.length === 0) return 0;

    // Convert each id to number and find the max
    const maxId = Math.max(...validItems.map((item) => Number(item.id)));

    return maxId;
  }

  /*
  @ViewChild(MatTable) table!: MatTable<any>;

  // Currently editable row
  editableRow: Field | null = null;

  // Add a property to keep a copy of the row's original data
  originalRowData: { [key: string]: any } = {};

  displayedColumns: string[] = ['position', 'name', 'area', 'type', 'actions'];
  dataSource = new MatTableDataSource<Field>([
    { position: '1', name: 'Field 1', area: 100, type: CropType.MAIZE },
    { position: '2', name: 'Field 2', area: 200, type: CropType.SUGARBEET },
    { position: '3', name: 'Field 3', area: 150, type: CropType.NONE },
  ]);
  types = Object.values(CropType).filter((value) => typeof value === 'string') as string[];

  editRow(row: Field) {
    this.dataSource.data.forEach((r) => (r.isEditable = false)); // Ensure all other rows are not editable
    row.isEditable = true;
    this.editableRow = { ...row }; // Create a copy for tracking
    this.originalRowData[row.position] = { ...row }; // Store original data
    this.table.renderRows();
  }

  saveRow(row: Field) {
    row.isEditable = false;
    this.editableRow = null;
    // Here, you can call your service to update the row in your backend.
    console.log(row);

    this.table.renderRows();
  }

  // Function to add a new empty row in edit mode
  addNewItem() {
    const emptyRow: Field = {
      position: '',
      name: '',
      area: 1,
      type: CropType.NONE,
      isEditable: true,
    };
    this.editableRow = emptyRow; // Track the new row as editable
    this.dataSource.data = [emptyRow, ...this.dataSource.data];
    this.table.renderRows();
  }

  removeRow(row: Field) {
    const index = this.dataSource.data.indexOf(row);
    if (index > -1) {
      this.dataSource.data.splice(index, 1); // Remove the row from data source
      this.dataSource._updateChangeSubscription(); // Refresh the MatTable
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.editableRow && this.editableRow.isEditable) {
      this.saveRow(this.editableRow);
      this.editableRow = null; // Reset the editable row
    } else if (event.key === 'Escape' && this.editableRow && this.editableRow.isEditable) {
      this.cancelEdit(this.editableRow);
      this.editableRow = null; // Reset the editable row
    }
  }
  */
}
