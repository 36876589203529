import { FilePondModule } from 'ngx-filepond';
import { VSTVArchivTable, VSTVPaket, VSTVService } from './../services/vstv.service';
import { AfterViewInit, Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { ComponentFactoryResolver, Injector, SimpleChange, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, of, toArray } from 'rxjs';
import { merge, Observable, of as observableOf, pipe } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { FormControl } from '@angular/forms';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { MandatService, Mandat, MandatTable } from '../services/mandat.service';

import { PhutureIavatarComponent } from '../@components/phuture-iavatar/phuture-iavatar.component';

import { environment } from '../../environments/environment';
import { Logger } from '@core';

import { AuthQuery } from '@app/auth/auth.query';

import { JournalService, MailService, Mail } from '@core';
import { Patch } from 'immer';

import { DataChangeVSTVService } from './changes-vstv.service';

declare var $: any;

interface PatchSize {
  viewValue: string;
}

export interface EmpFilter {
  name: string;
  options: string[];
  defaultValue: string;
}

export interface filterOption {
  name: string;
  value: string;
  isdefault: boolean;
}

@Component({
  templateUrl: './vstv.component.html',
  styleUrls: [
    './vstv.component.scss',
    //'../../assets/dk/css/libs.bundle.css',
    //'../../assets/dk/css/theme.bundle.css'
  ],
})
export class VSTVComponent implements OnInit {
  displayedColumns: string[] = [
    'Md_PrimKey',
    'Md_Status',
    'Md_BelegNr',

    'Md_StatusDate',
    'Md_StatusTime',

    'Md_KzLand',
    'Md_KzBezirk',
    'Md_KzNummer',

    'Md_Hersteller',

    //'Md_Betrag',
    'person',

    'actions',

    /*
    Aktenzeichen
    Status
    Land
    Bezirk
    Nummer
    Hersteller
    Farbe
    Datum
    Tatzeit
    Strasse
    Hausnummer
    Tatbestand
    DienstNr
    Konkretisierung
    Betrag
    create_date
    create_time
    person
    printed
    printdate
    printcount
    transferred
    transferdate
    longitude
    latitude
    info1
    info2
    info3
    fahrzeugart
    zone
    ticketnr
    ticket_zeit
    notees
    internal_notes
    vstv_export
    vstv_packet
    vstv_ignore
    zusatz1
    zusatz2
    strafe
    dienstnr_stvo
     */
  ];

  imageStorage = environment.serverUrl + '/'; // images gehen über api

  visitorTable: MandatTable;

  totalItems: number;
  //visitors: Visitor[];

  //stats: VisitorStats;
  /*
  stat_nbr_visitors: Observable<number>;
  stat_nbr_exhibitors: Observable<number>;
  stat_nbr_appointments: Observable<number>;
  stat_nbr_registered: Observable<number>;
  stat_nbr_approved: Observable<number>;
  stat_nbr_fs_done: Observable<number>;
  */

  dataSubject = new BehaviorSubject<Mandat[]>([]);
  visitors$: Observable<Mandat[]>;
  dataSource = new MatTableDataSource<Mandat>();

  isLoading = true;

  searchTerm$ = new BehaviorSubject<string>('');

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSizes = [50, 100, 200, 500];

  searchValue: string;
  //searchKeywordFilter = new FormControl();

  stati: string[] = [
    'Alle',
    'Erfasst',
    'Teil-Erfasst',
    'Überfällig',
    'Bezahlt',
    'Anzeige',
    'Anzeige-Bezahlt',
    'Anzeige / Bezahlt',
    'Storno',
  ];
  streets: string[] = ['Alle', 'Flughafen Alee', 'Zufahrt ABC'];

  empFilters: EmpFilter[] = [];
  defaultValue = 'Alle';
  filterDictionary = new Map<string, string>();
  //dataSourceFilters = new MatTableDataSource();
  filters$ = new BehaviorSubject<string>('');

  //patch sizes
  patchsize: PatchSize[] = [
    { viewValue: '20' },
    { viewValue: '50' },
    { viewValue: '100' },
    { viewValue: '200' },
    { viewValue: '500' },
  ];

  // dilaog und sidenav
  openSidebar = false;
  @ViewChild('sidenav') sidenav;
  selectedVisitor: Mandat;
  @ViewChild('visitorContainer', { read: ViewContainerRef }) quizContainer: ViewContainerRef;
  current_mandat: Mandat;
  private subscription: Subscription; // changes in the eg sidebar

  //
  //
  frmExport: FormGroup;
  frmExportArchiv: FormGroup;

  //
  //frmVSTVArchiv: FormGroup;
  selectedVSTVPaketID: number = 0;
  vstv_archiv_pakete: VSTVPaket[];
  popVSTVArchiv = new FormControl();
  dsVSTVArchivMandate = new MatTableDataSource<any>(); // For the mat-table data
  //displayColVSTVArchivMandate: string[] = ['id', 'bezeichnung', 'status']; // Adjust according to your data
  // ----> displayedColumns von mdante

  constructor(
    private mandatService: MandatService,
    private VSTVService: VSTVService,
    private cfr: ComponentFactoryResolver,
    private _snackBar: MatSnackBar,
    private _fb: FormBuilder,
    private journalService: JournalService,
    public dialog: MatDialog,

    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private authQuery: AuthQuery,
    private dataChangeService: DataChangeVSTVService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    //this.empFilters.push({ name: 'Land', options: this.countries, defaultValue: this.defaultValue });
    //this.empFilters.push({ name: 'Status', options: this.stati, defaultValue: this.defaultValue });
    //this.empFilters.push({ name: 'Straße', options: this.streets, defaultValue: this.defaultValue });

    // reset the selected id
    this.selectedVSTVPaketID = 0;

    // subscribe to the sidebar changes
    this.subscription = this.dataChangeService.dataChanges$.subscribe((data) => {
      if (data) {
        // Handle the data, e.g., update the list or form
        console.log('change recieved-----------');
        console.log(data);

        // update the specific transfer
        this.updateMandat(data);
      }
    });

    // get selection change on the dropdown
    this.popVSTVArchiv.valueChanges.subscribe((value) => {
      // fetch the daata
      console.log('get mandate from paket ' + value);
      this.fetchMandateVSTVPaket(value);

      this.selectedVSTVPaketID = value;
    });

    // init the forms
    this.initForms();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe(); // unsubscibe to the subscriptions
  }

  initForms() {
    this.frmExport = this._fb.group({
      bearbeiter: [''],
    });

    this.frmExportArchiv = this._fb.group({
      bearbeiterErneut: [''],
    });

    // fill with user data
    var username = this.authQuery.name;
    const bearbeiterControl = this.frmExport.get('bearbeiter');
    if (bearbeiterControl && !bearbeiterControl.value) {
      this.frmExport.patchValue({ bearbeiter: '' + username });
    }
    const bearbeiterErneutControl = this.frmExportArchiv.get('bearbeiterErneut');
    if (bearbeiterErneutControl && !bearbeiterErneutControl.value) {
      this.frmExportArchiv.patchValue({ bearbeiterErneut: '' + username });
    }
  }

  /**
   * update the datasource with the given data from the subscription
   */
  updateMandat(updatetMandat: Mandat) {
    const data = this.dataSubject.getValue();
    const index = data.findIndex((visitor) => +visitor.Md_BelegNr === +updatetMandat.Md_BelegNr);
    if (index > -1) {
      data[index] = updatetMandat;
      this.dataSubject.next(data); // Update the BehaviorSubject
      this.dataSource.data = data; // Update the MatTableDataSource
    }
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;

    // ----------------------------------------- MANDATE
    // get all the mandate for the table ansicht
    merge(
      this.filters$,
      this.searchTerm$.pipe(debounceTime(400), distinctUntilChanged()),
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(
        startWith({}),
        switchMap((term) => {
          this.isLoading = true;
          //var sTerm = term && typeof term == 'string' ? term.toString() : '';
          return this.getTableData$(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            //sTerm.trim(),
            this.searchTerm$.getValue(),
            this.filters$.getValue()
          ).pipe(catchError(() => observableOf(null)));
        }),
        map((visTable) => {
          if (visTable == null) return [];
          this.totalItems = visTable.total;
          this.isLoading = false;

          //this.stats = visTable.stats;

          return visTable.data.map((data) => {
            return <Mandat>{
              ...data,
              /*
              decharge_resp_civile: data.decharge_resp_civile == -1 ? 1 : 0,
              estimate: data.estimate == '-1' ? '1' : 0,
              garage_day1: data.garage_day1 == -1 ? 1 : 0,
              garage_day2: data.garage_day2 == -1 ? 1 : 0,
              lunch_day1: data.lunch_day1 == -1 ? 1 : 0,
              lunch_day2: data.lunch_day2 == -1 ? 1 : 0,
              attendance_event: data.attendance_event == -1 ? 1 : 0,
              hotel_stay_night: data.hotel_stay_night == -1 ? 1 : 0,
              transfer_arrival: data.transfer_arrival == -1 ? 1 : 0,
              transfer_return: data.transfer_return == -1 ? 1 : 0,
              flight_booked: data.flight_booked == -1 ? 1 : 0,
              */
            };
          });
          //return visTable.data;
        })
      )
      .subscribe((mandate: Mandat[]) => {
        this.visitors$ = of(mandate);
        this.dataSource = new MatTableDataSource(mandate);

        this.dataSubject.next(mandate);

        //reset paginator
        //this.paginator.pageIndex = 1;
      });

    // ----------------------------------------- VSTV ARCHIV Pakete
    // get all the archiv pakete for the dropdown
    this.VSTVService.getVSTVArchivPakete().subscribe(
      (vstv_pakete: VSTVArchivTable) => {
        this.vstv_archiv_pakete = vstv_pakete.data;
      },
      (error) => {
        console.error('Error during sequential API calls:', error);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTerm$.next(filterValue);
  }

  applyEmpFilter(ob: MatSelectChange, empfilter: EmpFilter) {
    this.filterDictionary.set(empfilter.name, ob.value);
    var jsonString = JSON.stringify(Array.from(this.filterDictionary.entries()));
    this.filters$.next(jsonString);
  }

  getTableData$(pageNumber: Number, pageSize: Number, sort: string, order, searchTerm: string, filtersArgs: string) {
    // get data from server

    filtersArgs = '[["Status","Überfällig"]]';

    return this.mandatService.getMandate(pageNumber, pageSize, sort, order, searchTerm, filtersArgs);
  }

  showPageDetail(mandat_id: number) {
    console.log('load page: ' + mandat_id);

    // get the quiz page with given uuid
    let mandat: Mandat;
    mandat = <Mandat>this.dataSource.filteredData.find((element: Mandat) => element.Md_PrimKey == mandat_id);
    this.current_mandat = mandat;

    // load the component into the modal slide window
    //this.router.navigate(['/home/edit', visitor_id]);
    this.router.navigate([
      '/mandate/edit',
      { id: mandat_id, _uid: mandat.Md_PrimKey, _fid: mandat_id, _role: 3, _country: mandat.Md_Kennzeichen, ia: 1 },
    ]);
    //, {my_object: JSON.stringify(this.Obj)}]));

    //this.showVisitor(visitor);
    //this.sidenav.open();
    //const mySidebar = <any>document.querySelectorAll('.offcanvas');
    //mySidebar.show();

    $('#staticBackdrop').offcanvas('show');
  }

  showVisitor(visitor: Mandat) {
    console.log('show the page');

    this.selectedVisitor = visitor;

    // load visitor - firststart
    //this.llStartPageEdit(visitor);
  }

  sidebarOnClose() {
    console.log('close');
    this.openSidebar = false;
  }

  /**
   * wenn der tab geklickt wird --< sollte man das dropdoewn refreshen -< wieder laden
   * @param event
   */
  onTabChange(event: MatTabChangeEvent) {
    console.log('Selected Tab index: ', event.index);

    if (event.index === 1) {
      // das ist der zweite tab --> archiv
      this.VSTVService.getVSTVArchivPakete().subscribe(
        (vstv_pakete: VSTVArchivTable) => {
          this.vstv_archiv_pakete = vstv_pakete.data;

          this.cdr.detectChanges();
        },
        (error) => {
          console.error('Error during sequential API calls:', error);
        }
      );
    }
  }

  //
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  //
  // ------------------------ ARCHIV
  //

  fetchMandateVSTVPaket(filterKey: string) {
    // get the data

    var filtersArgs = '[["VSTVPaket",' + filterKey + ']]';

    this.mandatService.getMandate(1, 50, 'Md_BelegNr', 'asc', '', filtersArgs).subscribe((retMandate: MandatTable) => {
      this.dsVSTVArchivMandate.data = retMandate.data;
    });
  }

  //
  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  //
  // ------------------------ VSTV FILE DOWNLOAD
  //

  /**
   *
   * generate the vstv to file
   * paket-nummer ist 0 -> wenn es neu erstellt wird -> sonst wieder erstellen
   */
  vstvlog: string = '';
  erneutDownload: boolean = false;
  generateVSTV(paket_id: number) {
    console.log('export vstv');

    let bearbeiter = this.frmExport.get('bearbeiter')?.value;

    if (paket_id > 0) this.erneutDownload = true; // wenn es wieder generiert wird - anderer log anzeigen
    // update to the server

    this.mandatService.doExportVSTV(bearbeiter, paket_id).subscribe(
      (response) => {
        //next() callback
        console.log('response received');

        this.vstvlog = response.log;
        this.paketID = response.paketid;
        this.vstv_file = response.vstvfile;

        // broadcast the changes back to the table-view
        //this.broadcastChanges(response);

        this._snackBar.open('VSTV Datei erfolgreich generiert.', 'Close', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        //
        // show some feedback --> snackbar ?
        //
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  // find vstv-paket im archiv by id
  findVSTVPaketByID(array, id) {
    const item = array.find((element) => element.id === id);
    return item ? item.bezeichnung : 'not-found';
  }

  /**
   *
   * das übermittelte mandat soll aus dem paket ausgenommen werden
   * @param mandat
   */
  ausPaketAusnehmen(mandat: Mandat) {
    console.log(mandat);

    this.VSTVService.doPaketAusnehmen(mandat.Md_PrimKey).subscribe(
      (response: any) => {
        //next() callback
        console.log('response received');
        //console.log(response);

        // broadcast the changes back to the table-view
        //this.broadcastChanges(response);

        mandat.Md_Status = '8'; // auf ausgenommen setzten

        this._snackBar.open('Mandate erfolgreich ausgenommen.', 'Close', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        //
        // show some feedback --> snackbar ?
        //
        //alert('table refresh');
        this.ngAfterViewInit();
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  /**
   *
   * export vstv to file with given paket id
   */
  paketID: number;
  vstv_file: string;
  downloadVSTV(paketID: number, erneut: boolean = false) {
    console.log('download vstv paket# ' + paketID);

    // update to the server
    this.mandatService.downloadVSTVFile(paketID).subscribe(
      (blob) => {
        //next() callback
        console.log('response received');

        // bei einem erneutem export
        // muss die caption ermittelt werden
        if (erneut) {
          // es wird erneut exportiert
          const bezeichnung = this.findVSTVPaketByID(this.vstv_archiv_pakete, this.selectedVSTVPaketID);

          this.vstv_file = bezeichnung + '-' + Math.floor(1000 + Math.random() * 9000) + '.txt';
        }

        this.downloadBlob(blob, this.vstv_file);

        // broadcast the changes back to the table-view
        //this.broadcastChanges(response);

        this._snackBar.open('VSTV Datei erfolgreich generiert.', 'Close', {
          duration: 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        //
        // show some feedback --> snackbar ?
        //
      },
      (error) => {
        //error() callback
        console.error('Request failed with error');
        //this.errorMessage = error;
      },
      () => {
        //complete() callback
        console.log('Request completed'); //This is actually not needed
      }
    );
  }

  private downloadBlob(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a); // Append the element to the DOM to make it work in Firefox
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a); // Clean up
  }

  //
  // -------------------------------
}
