import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';
import { AppDataStore } from './appdata.store';

import { IAppDataModel, AppDataState } from '@app/@appstate/appdata.model';

@Injectable({
  providedIn: 'root',
})
export class AppDataQuery extends QueryEntity<AppDataState> {
  constructor(protected override store: AppDataStore) {
    super(store);
  }

  /*
    get getAppData(): AppDataState {
      const firstAppData$ = this.selectEntity(this.authQuery.appid);

      return firstAppData$;
    }
  */
}
